export function isPhoneNumberValid(phoneNumber) {
    // Use a regular expression to validate a 10-digit phone number
    if (phoneNumber.length === 0) {
        return true;
    }
    const phoneNumberRegex = /^\d{10}$/;
    return phoneNumberRegex.test(phoneNumber);
};

export function isSinValid(sin) {
    // Use a regular expression to validate a 9-digit number
    if (sin.length === 0) {
        return true;
    }
    const sinRegex = /^\d{9}$/;
    return sinRegex.test(sin);
}

export function isEmailValid(email) {
    // Use a regular expression to validate email format
    if (email.length === 0) {
        return true;
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
};

export function isPostalCodeValid(country, postalCode) {
    if (postalCode.length === 0) {
        return true;
    }
    if (country === 'Canada') {
        // Canadian postal code format: A1A 1A1 or A1A1A1
        const canadianPostalCodeRegex = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d|[A-Za-z]\d[A-Za-z]\d$/;
        return canadianPostalCodeRegex.test(postalCode);
    }
    if (country === 'U.S.A') {
        // US ZIP code format: 12345 or 12345-6789
        const usZipCodeRegex = /^\d{5}(-\d{4})?$/;
        return usZipCodeRegex.test(postalCode);
    }
    if (country === 'Mexico') {
        // Mexican postal code format: 12345
        const mexicanPostalCodeRegex = /^\d{5}$/;
        return mexicanPostalCodeRegex.test(postalCode);
    }
    if (postalCode === '') {
        return true;
    }
    return false; // Default to false if the country is not recognized
};

