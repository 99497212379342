// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;


const navConfig = [

  // {
  //   title: 'analytics',
  //   path: '/dashboard/analytics',
  //   icon: icon('ic_analytics'),
  // },
  {
    title: 'Driver Management',
    path: '/dashboard/drivers',
    icon: icon('ic_user'),
  },
  {
    title: 'Job Postings',
    path: '/dashboard/jobpostings',
    icon: icon('ic_blog'),
  },
  // {
  //   title: 'Calendar',
  //   path: '/dashboard/calendar',
  //   icon: icon('ic_cart'),
  // },
  {
    title: 'Company Insurance',
    path: '/dashboard/insurance',
    icon: icon('ic_insurance'),
  },
  {
    title: 'Incident Management',
    path: '/dashboard/incidents',
    icon: icon('ic_accident'),
  },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

const navAdminConfig = [

  // {
  //   title: 'analytics',
  //   path: '/dashboard/analytics',
  //   icon: icon('ic_analytics'),
  // },
  {
    title: 'Driver Management',
    path: '/dashboard/drivers',
    icon: icon('ic_user'),
  },
  {
    title: 'Job Postings',
    path: '/dashboard/jobpostings',
    icon: icon('ic_blog'),
  },
  // {
  //   title: 'Calendar',
  //   path: '/dashboard/calendar',
  //   icon: icon('ic_cart'),
  // },
  {
    title: 'Company Insurance',
    path: '/dashboard/insurance',
    icon: icon('ic_insurance'),
  },
  {
    title: 'Incidents Management',
    path: '/dashboard/incidents',
    icon: icon('ic_accident'),
  },
  {
    title: 'Manage Clients',
    path: '/dashboard/admin',
    icon: icon('ic_admin'),
  },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export { navConfig, navAdminConfig };
