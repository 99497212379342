import { useState } from 'react';
import { toast } from 'react-toastify';
import {
    Button, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
    List, ListItem, ListItemText
} from '@mui/material';
import { putRequest } from '../../../../../shared/apiCalls';

export default function ConfirmHire({ onClose, open, DriverDetails, setDriverDetails }) {
    const [disableConfirm, setDisableConfirm] = useState(false)

    const handleConfirm = async (e) => {
        setDisableConfirm(true)
        try {
            const response = await toast.promise(putRequest(`driver/${DriverDetails.id}`, {
                'status': 'hired'
            }),
                {
                    pending: 'Updating Status...',
                    success: 'Status Updated',
                    error: 'Error Updating Status'
                })
            onClose();

            setDriverDetails(driver => {
                return { ...driver, 'status': 'hired' }
            });
        } catch (error) {
            console.error(error)
            alert('An Error Occured')
        }
        setDisableConfirm(false)


    }
    return (

        <Dialog
            onClose={onClose} open={open}
            aria-labelledby="form-dialog"
            maxWidth='sm'
            fullWidth

        >
            <DialogTitle>Confirm Hire</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please confirm that you are about to hire this driver without uploading the following information:
                </DialogContentText>
                <List>
                    {!DriverDetails.license &&
                        <ListItem key={'license'}>
                            <ListItemText primary={'License'} />
                        </ListItem>
                    }
                    {!DriverDetails.cvor_retrieved &&
                        <ListItem key={'cvor_retrived'}>
                            <ListItemText primary={'CVOR'} />
                        </ListItem>
                    }
                    {!DriverDetails.abstract_retrieved &&
                        <ListItem key={'abstract_retrieved'}>
                            <ListItemText primary={'Abstract'} />
                        </ListItem>
                    }
                    {!DriverDetails.drug_test_date &&
                        <ListItem key={'drug_test'}>
                            <ListItemText primary={'Drug Test'} />
                        </ListItem>
                    }

                    {!DriverDetails.offer_letter_date &&
                        <ListItem key={'offer_letter'}>
                            <ListItemText primary={'Offer Letter'} />
                        </ListItem>
                    }

                </List>

            </DialogContent>
            <DialogActions >
                <Button color="error"
                    variant="contained" onClick={onClose}>Cancel</Button>
                <Button color="primary"
                    disabled={disableConfirm}
                    variant="contained" onClick={handleConfirm}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
};
