import Typography from '@mui/material/Typography';
import {
    Box,
} from '@mui/material';
import { capitalCase } from 'change-case';
import Alert from '@mui/material/Alert';
import AlertIcon from '@mui/icons-material/Warning';
import 'react-toastify/dist/ReactToastify.css';

function hasDatePassed(targetDate) {
    // Parse the target date string into a Date object
    const targetDateTime = new Date(targetDate);

    // Get the current date and time
    const currentDateTime = new Date();

    // Compare the target date with the current date
    return targetDateTime < currentDateTime;
}

export default function DisplayUpdates({ DriverDetails }) {
    const updates = []

    const sixMonthCheck = ['cvor_retrieved', 'abstract_retrieved', 'drug_test_date']
    const currentDateTime = new Date();

    sixMonthCheck.map((key) => {
        if (DriverDetails[key]) {
            const targetDateTime = new Date(DriverDetails[key]);

            // Calculate the difference in months
            const monthsDifference = (currentDateTime.getFullYear() - targetDateTime.getFullYear()) * 12 +
                (currentDateTime.getMonth() - targetDateTime.getMonth());

            // Check if the difference is greater than 6 months
            if (monthsDifference >= 6) {
                updates.push(key)
            }
        }
        // Add your logic for each key here
        // For example: console.log('Value:', yourObject[key]);
        return null; // The map function expects a return value; you can adjust as needed
    });

    // will need to use data from query id
    // const userColRef = query(collection(db, 'users'), where("uid", '===', searchParams.get('query'))
    return (

        <>
            {DriverDetails.license && hasDatePassed(DriverDetails.license.expiry) &&
                <Alert severity="warning" icon={<AlertIcon fontSize="inherit" />}>
                    <Typography variant="subtitle2" display="block" gutterBottom>
                        Update Required! Driver License on record has expired, please upload copy of new license.
                    </Typography>
                </Alert>
            }
            <Box minHeight={8} />
            {updates.length > 0 ?
                <Alert severity="warning" icon={<AlertIcon fontSize="inherit" />}>
                    <Typography variant="subtitle2" display="block" gutterBottom>
                        Update Required! It has been more than six months since following files have been updated:
                    </Typography>
                    {updates.map((item, index) => (

                        <Typography key={index} variant="subtitle2" display="block" gutterBottom>
                            {item === 'cvor' ?
                                '- CVOR'
                                :
                                `-${capitalCase(item)}`
                            }
                        </Typography>
                    ))}
                    <Typography variant="subtitle2" display="block" gutterBottom />
                </Alert>
                : <></>}
        </>
    )

}