import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

const clients = [...Array(100)].map((_, index) => ({
    id: index,
    brokerage_name: faker.name.firstName(),
    company_address: 'Canada On-Ontario',
    company_email: faker.internet.email(),
    company_name: faker.random.words(1),
    company_number: faker.phone.number('416-###-####'),
    hst_number: faker.datatype.number({ min: 10000, max: 100000 }),
    hiring_steps: sample([
        ['Drug Test', 'Insurance Approval', 'Interview Scheduled', 'Offer Letter'],
        ['Interview', 'Drug Test', 'Transplus', 'Schedule Interview', 'Drive Test', 'Offer Letter'],
        ['Drug Test', 'Schedule Interview', 'Drive Test']
    ]),
    insurance_agent_name: faker.name.firstName(),
    insurance_email: faker.internet.email(),
    insurance_phone_number: faker.phone.number('416-###-####'),
    monthly_rev: faker.datatype.number({ min: 500, max: 10000 }),
    num_drivers: faker.datatype.number({ min: 25, max: 500 }),
    policy_number: faker.datatype.number({ min: 10000, max: 100000 }),
    policy_period: faker.datatype.number({ min: 10000, max: 100000 }),
    services: {
        'claim_management': faker.datatype.boolean(),
        'driver_management': faker.datatype.boolean(),
    },
    start_date: faker.date.between('2022-01-01T00:00:00.000Z', '2023-05-01T00:00:00.000Z'),
    status: sample(['active', 'inactive']),
}))

export default clients;

