import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DriverApplication from './DriverApplication';
/* function to add new task to firestore */
function AddForm({ onClose, open }) {


    return (
        <Dialog
            onClose={onClose} open={open}
            aria-labelledby="form-dialog"
            maxWidth='lg'
            fullWidth



        >
            <DialogTitle id="form-dialog">Add New Driver

            </DialogTitle>
            <DialogContent>
                <DriverApplication onClose={onClose} job_post_id={-1} />
            </DialogContent>
        </Dialog >
    )


}

export default AddForm