import { useState } from 'react';
import TableCell from '@mui/material/TableCell';
// @mui
import {
    Grid,
    Button,
    Typography,
    TextField,
    Link
} from '@mui/material';
// sections
import { capitalCase } from "change-case";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getRequestDirectResponse } from '../../../shared/apiCalls';


function formatAsCurrency(number) {
    // Use toLocaleString to format the number with commas
    const formattedNumber = number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return formattedNumber;
}

function DisplayIncidentInfo({ incident }) {

    const comingSoon = () => {
        toast.warning(`Feature Coming Soon!`, {
            position: toast.POSITION.BOTTOM_CENTER
        });

    }


    const viewFile = async (fileType) => {
        try {
            // Make a request to the Flask backend to get the file
            const response = await getRequestDirectResponse(
                `incidentdocuments/${incident.id}/${fileType}`
            )
            const blob = await response.blob();

            // Create a download URL for the Blob
            const fileUrl = URL.createObjectURL(blob);
            // Open a new tab with the file URL
            window.open(fileUrl, "_blank");
        } catch (error) {
            console.error("Error fetching or opening file:", error);
        }
    };

    const colSpan = 12;
    const date_truck_maintenance = new Date(incident.last_truck_maintenance)
    return (
        <TableCell colSpan={colSpan}>
            <form >

                <Grid container spacing={3}>

                    <Grid item xs={12} sm={12} md={9} lg={9} xl={9}  >
                        <Typography sx={{ fontWeight: 'bold' }} color={"headline"} gutterBottom>
                            Incident #{incident.id}

                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}  >
                        <Button onClick={comingSoon}>Edit</Button>
                        <Button onClick={comingSoon}>Download</Button>
                        <Button onClick={comingSoon}>Delete</Button>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
                        <Typography component="div" variant='body1' sx={{ fontWeight: 'bold' }} color={"common.black"}>
                            {"Address: "} <Typography variant='subtitle1' style={{ display: 'inline-block' }}>
                                {incident.location.street}, {incident.location.city}, {incident.location.province_state} {incident.location.postal_zip}, {incident.location.country}
                            </Typography>
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}  >
                        <Typography component="div" variant='body1' sx={{ fontWeight: 'bold' }} color={"common.black"}>
                            {"Claim Filed: "} <Typography variant='subtitle1' style={{ display: 'inline-block' }}>
                                {incident.filing_claim ? "Yes" : "No"}
                            </Typography>
                        </Typography>

                    </Grid>
                    {incident.filing_claim ?
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}  >
                            <Typography component="div" variant='body1' sx={{ fontWeight: 'bold' }} color={"common.black"}>
                                {"Claim Number: "} <Typography variant='subtitle1' style={{ display: 'inline-block' }}>
                                    {incident.claim_number}
                                </Typography>
                            </Typography>
                        </Grid>
                        : <></>
                    }
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}  >
                        <Typography component="div" variant='body1' sx={{ fontWeight: 'bold' }} color={"common.black"}>
                            {"Driver Fault: "} <Typography variant='subtitle1' style={{ display: 'inline-block' }}>
                                {incident.fault_driver ? "Yes" : "No"}
                            </Typography>
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}  >
                        <Typography component="div" variant='body1' sx={{ fontWeight: 'bold' }} color={"common.black"}>
                            {"Bodily Injuries: "} <Typography variant='subtitle1' style={{ display: 'inline-block' }}>
                                {capitalCase(incident.bodily_injuries.toString())}
                            </Typography>
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}  >
                        <Typography component="div" variant='body1' sx={{ fontWeight: 'bold' }} color={"common.black"}>
                            {"Fatalities: "} <Typography variant='subtitle1' style={{ display: 'inline-block' }}>
                                {capitalCase(incident.fatalities.toString())}
                            </Typography>
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}  >
                        <Typography component="div" variant='body1' sx={{ fontWeight: 'bold' }} color={"common.black"}>
                            {"Truck Damage: "} <Typography variant='subtitle1' style={{ display: 'inline-block' }}>
                                {formatAsCurrency(incident.truck_damage)}
                            </Typography>
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}  >
                        <Typography component="div" variant='body1' sx={{ fontWeight: 'bold' }} color={"common.black"}>
                            {"Trailer Damage: "} <Typography variant='subtitle1' style={{ display: 'inline-block' }}>
                                {formatAsCurrency(incident.trailer_damage)}
                            </Typography>
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}  >
                        <Typography component="div" variant='body1' sx={{ fontWeight: 'bold' }} color={"common.black"}>
                            {"Cargo Damage: "} <Typography variant='subtitle1' style={{ display: 'inline-block' }}>
                                {formatAsCurrency(incident.cargo_damage)}
                            </Typography>
                        </Typography>

                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}  >
                        <Typography component="div" variant='body1' sx={{ fontWeight: 'bold' }} color={"common.black"}>
                            {"Weather: "} <Typography variant='subtitle1' style={{ display: 'inline-block' }}>
                                {capitalCase(incident.weather_condition)}
                            </Typography>
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}  >
                        <Typography component="div" variant='body1' sx={{ fontWeight: 'bold' }} color={"common.black"}>
                            {"Road Condition: "} <Typography variant='subtitle1' style={{ display: 'inline-block' }}>
                                {capitalCase(incident.road_condition)}
                            </Typography>
                        </Typography>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}  >
                        <Typography component="div" variant='body1' sx={{ fontWeight: 'bold' }} color={"common.black"}>
                            {"Date Last Truck Maintenance: "} <Typography variant='subtitle1' style={{ display: 'inline-block' }}>
                                {date_truck_maintenance.toString().slice(0, 16)}
                            </Typography>
                        </Typography>

                    </Grid>
                    <Grid item s={12} sm={12} md={12} lg={12} xl={12}  >
                        <TextField
                            value={incident.additional_details}
                            label={<Typography variant="headline" component="h3"> Additional Details</Typography>}
                            fullWidth
                            multiline
                            rows={4}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }} color={"common.black"}>{`Dashcam Video: `}
                            <Link href={incident.video_link} variant='subtitle1' style={{ display: 'inline-block' }}>
                                {incident.video_link}
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }} color={"common.black"}>Relevant Documents:
                            <Button type='button'
                                disabled={!incident.files_available.police_report}
                                onClick={() => viewFile('police_report')}>
                                Police Report
                            </Button>
                            <Button type='button'
                                disabled={!incident.files_available.signed_driver_statement}
                                onClick={() => viewFile('signed_driver_statement')}>
                                Driver Statement
                            </Button>
                            <Button type='button'
                                disabled={!incident.files_available.release_form}
                                onClick={() => viewFile('release_form')}>
                                Release Form
                            </Button>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }} color={"common.black"}>Images:
                            <Button type='button'
                                disabled={!incident.files_available.photo_1}
                                onClick={() => viewFile('photo_1')}>
                                Photo 1
                            </Button>
                            <Button type='button'
                                disabled={!incident.files_available.photo_2}
                                onClick={() => viewFile('photo_2')}>
                                Photo 2
                            </Button>
                            <Button type='button'
                                disabled={!incident.files_available.photo_3}
                                onClick={() => viewFile('photo_3')}>
                                Photo 3
                            </Button>
                            <Button type='button'
                                disabled={!incident.files_available.photo_4}
                                onClick={() => viewFile('photo_4')}>
                                Photo 4
                            </Button>
                            <Button type='button'
                                disabled={!incident.files_available.photo_5}
                                onClick={() => viewFile('photo_5')}>
                                Photo 5
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </form>

        </TableCell>
    )
}

export default DisplayIncidentInfo