import { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { capitalCase } from 'change-case';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Radio, FormControlLabel, RadioGroup, FormControl, FormLabel, IconButton,
    Divider, Button, ListItem, Typography, InputAdornment
} from '@mui/material';
import { countries, CanadaProvinces, USAStates, MexicoStates, AllViolation } from '../../../../utils/dropOptions';

function TrafficViolation({ DriverViolation, setDriverViolation }) {
    const yesValue = true
    const noValue = false

    const initialViolation = {
        'date': '',
        'type': 'speeding',
        'location': {
            'country': 'Canada',
            'province_state': 'ON - Ontario',
        },
        'commercial': null,
        'penalty': null,
        'penalty_info': ''
    }

    const addItem = () => {
        const updatedItem = DriverViolation.details
        updatedItem.push(initialViolation)
        setDriverViolation((prevData) => ({
            ...prevData, 'details': updatedItem
        }))
    }

    const deleteItem = (index) => {
        const updatedItems = DriverViolation.details.filter((_, i) => i !== index);
        setDriverViolation((prevData) => ({
            ...prevData, 'details': updatedItems
        }))

    }

    // Need to include index
    const handleDateChange = (date, e, index) => {
        if (e == null) {
            alert('Please Select Valid Date')
        } else {
            const updatedViolation = [...DriverViolation.details]
            updatedViolation[index] = {
                ...updatedViolation[index],
                [date]: e
            }
            setDriverViolation((prevData) => ({
                ...prevData, 'details': updatedViolation
            }))
        }
    }
    // Need to include index

    const handleInvolveChange = (e) => {
        const { name, value } = e.target
        if (value === 'true') {
            setDriverViolation({
                'received_violation': true,
                'details': [initialViolation]
            });
        } else {
            setDriverViolation({
                'received_violation': false,
                'details': []
            });
        }

    }

    const handleTFChange = (e, index) => {
        const { name, value } = e.target;
        const updatedViolation = [...DriverViolation.details]
        if (value === 'true') {
            updatedViolation[index] = {
                ...updatedViolation[index],
                [name]: true
            }
        } else {
            updatedViolation[index] = {
                ...updatedViolation[index],
                [name]: false
            }
        }

        setDriverViolation((prevData) => ({
            ...prevData, 'details': updatedViolation
        }))
    }

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedViolation = [...DriverViolation.details]
        // 'description': ''
        if (name === 'country') {
            let provinceState = ''

            if (value === "Canada") {
                provinceState = 'ON - Ontario'
            } else if (value === "U.S.A") {
                provinceState = 'NY - New York'
            } else {
                provinceState = 'SIN - Sinaloa'
            }

            setDriverViolation((prevData) => ({
                ...prevData, 'details': updatedViolation
            }))
            updatedViolation[index] = {
                ...updatedViolation[index],
                'location': {
                    'country': value,
                    'province_state': provinceState,
                }
            }

            setDriverViolation((prevData) => ({
                ...prevData, 'details': updatedViolation
            }))
        } else if (name === 'province_state') {
            updatedViolation[index] = {
                ...updatedViolation[index],
                'location': {
                    'country': updatedViolation[index].location.country,
                    'province_state': value,
                }
            }

            setDriverViolation((prevData) => ({
                ...prevData, 'details': updatedViolation
            }))
        } else {
            updatedViolation[index] = {
                ...updatedViolation[index],
                [name]: value
            }
            setDriverViolation((prevData) => ({
                ...prevData, 'details': updatedViolation
            }))
        }


    };

    console.log(DriverViolation)
    return (
        <form>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop={5}
                marginBottom={3}
                minWidth="800px"

            >
                <FormControl component="fieldset">
                    <Typography variant="h6">Have you ever received a traffic violation (besides parking violations) in last 3 years?</Typography>
                    <RadioGroup
                        row
                        name="received_violation"
                        value={DriverViolation.received_violation}
                        onChange={(e) => handleInvolveChange(e)}
                        required
                    >
                        <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                        <FormControlLabel value={noValue} control={<Radio />} label="No" />

                    </RadioGroup>
                </FormControl>


            </Box>
            {DriverViolation.received_violation === yesValue ?
                <>
                    <Divider sx={{ borderBottomWidth: 5 }} />

                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        marginTop={1}
                    >
                        <Typography variant='subtitle2'>Provide details of all traffic violations you have been involved in last 3 years. </Typography>
                    </Box>
                    {DriverViolation.details.map((value, index) => {
                        return (
                            <ListItem
                                key={index}
                                secondaryAction={
                                    index !== 0 ?
                                        <IconButton color='error' onClick={() => deleteItem(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        :
                                        <></>


                                }
                            >
                                <Grid key={index} container spacing={3} >
                                    <Grid item xs={12} md={12}>
                                        <Typography variant='h5'>VIOLATION #{index + 1}:</Typography>

                                    </Grid>

                                    <Grid item xs={12} md={8}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                required
                                                name='date'
                                                label={'*Date Occured'}
                                                value={value.date}
                                                onChange={(e) => handleDateChange('date', e, index)}
                                                maxDate={new Date()}

                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth >
                                            <InputLabel id='type-select-label'>What type of offence did you commit?</InputLabel>
                                            <Select
                                                labelId='type-select-label'
                                                id='type-select'
                                                name='type'
                                                onChange={(e) => handleChange(e, index)}
                                                value={value.type}
                                                // fullWidth
                                                input={<OutlinedInput label='What type of offence did you commit?' />}
                                            >
                                                {AllViolation.map((t) => (
                                                    <MenuItem key={t} value={t}>
                                                        <ListItemText primary={capitalCase(t)} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}  >
                                        <TextField
                                            name='country'
                                            onChange={(e) => handleChange(e, index)}
                                            style={{ width: '100%' }}
                                            select
                                            variant={'outlined'}
                                            value={value.location.country}
                                            label={'Country'}
                                        >
                                            {countries.map((t) => (

                                                <MenuItem key={t} value={t}>
                                                    <ListItemText primary={t} />
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} md={6}  >
                                        <TextField
                                            name='province_state'
                                            select
                                            onChange={(e) => handleChange(e, index)}
                                            style={{ width: '100%' }}
                                            variant={'outlined'}
                                            value={value.location.province_state}
                                            label={'Province/State'}
                                        >
                                            {(value.location.country === 'Canada') ?
                                                CanadaProvinces.map((t) => (
                                                    <MenuItem key={t} value={t}>
                                                        <ListItemText primary={t} />
                                                    </MenuItem>
                                                )) :
                                                (value.location.country === 'U.S.A') ?
                                                    USAStates.map((t) => (
                                                        <MenuItem key={t} value={t}>
                                                            <ListItemText primary={t} />
                                                        </MenuItem>
                                                    )) :
                                                    MexicoStates.map((t) => (
                                                        <MenuItem key={t} value={t}>
                                                            <ListItemText primary={t} />
                                                        </MenuItem>
                                                    ))

                                            }

                                        </TextField>
                                    </Grid>
                                    <Grid item md={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">*Did it occur while you were operating a commerical vehicle?</FormLabel>
                                            <RadioGroup
                                                row
                                                name="commercial"
                                                value={value.commercial}
                                                onChange={(e) => handleTFChange(e, index)}>
                                                <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                                                <FormControlLabel value={noValue} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">*Did you receive any penalities as a result of this offence?</FormLabel>
                                            <RadioGroup
                                                row
                                                name="penalty"
                                                value={value.penalty}
                                                onChange={(e) => handleTFChange(e, index)}>
                                                <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                                                <FormControlLabel value={noValue} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {value.penalty &&
                                        <Grid item md={12} sm={12} lg={12}>
                                            <Typography variant='subtitle'>*Please provide the details the penalities.</Typography>
                                            <TextField
                                                name="penalty_info"
                                                inputProps={{ maxLength: 250 }}
                                                value={value.penalty_info}
                                                onChange={(e) => handleChange(e, index)}
                                                fullWidth
                                                multiline
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Typography color="textSecondary">{`${value.penalty_info.length}/250`}</Typography>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </ListItem>
                        )
                    })}
                    {DriverViolation.details.length < 6 ?
                        <Box
                            display="flex"
                            alignItems="left"
                            justifyContent="left"
                            marginTop={3}
                        >
                            < Button variant="outlined" onClick={() => addItem()}>
                                Add Violation
                            </Button >
                        </Box>
                        : <></>
                    }
                </> :
                <></>
            }
        </form>

    )


}

export default TrafficViolation