export const companyData = {
    company_name: '',
    company_email: '',
    company_number: '',
    company_address: '',
    password: '',
    confirm_password: '',
    monthly_rev: '',
    hst_number: '',
    start_date: new Date(),
    services: {
        'driver_management': false,
        'claim_management': false
    },
    num_drivers: '',
    status: 'active',
    hiring_steps: ['',]
}