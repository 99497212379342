import React from 'react';
import { Typography, Grid, Checkbox, Box } from '@mui/material';
import clearingHouse from '../legal_paperwork/clearinghouse.pdf'
import consent from '../legal_paperwork/consent.pdf'
import disclosure from '../legal_paperwork/disclosure.pdf'
import prevEmployer from '../legal_paperwork/prevEmployer.pdf'
import license from '../legal_paperwork/license.pdf'
import medical from '../legal_paperwork/medical.pdf'
import psp from '../legal_paperwork/psp.pdf'

const ConsentForms = ({ DriverDetails, setDriverDetails }) => {

    const handleChange = (e) => {
        const { name, value } = e.target
        const updatedConsent = DriverDetails.consent_forms
        setDriverDetails((prevData) => ({
            ...prevData,
            'consent_forms': {
                ...updatedConsent,
                [name]: !updatedConsent[name]
            }
        }))
    }

    return (
        <><Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginTop={5}
            marginBottom={3}
        >
            <Typography variant='h6'>You required to consent to all authorizations and releases below to submit your application: </Typography>


        </Box>

            <Grid container spacing={3} paddingX={4}>


                <Grid item xs={12} md={12}>
                    <Typography variant='subtitle2'><a href={disclosure} target="_blank" >*Acknowledgement & Authorization for Background Investigation </a></Typography>

                </Grid>
                <Grid item xs={1} md={1}>
                    <Checkbox
                        id="disclosure"
                        name="disclosure"
                        checked={DriverDetails.consent_forms.disclosure}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
                <Grid item xs={11} md={11}>
                    By checking the box, I (a) acknowledge that I have read and understand the above and also have been
                    given the opportunity to copy/print it, and (b) agree to use an electronic signature to demonstrate my
                    consent. An electronic signature is as legally binding as an ink signature.
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant='subtitle2'><a href={psp} target="_blank" >*PSP Disclosure and Authorization </a></Typography>

                </Grid>
                <Grid item xs={1} md={1}>
                    <Checkbox
                        id="psp"
                        name="psp"
                        checked={DriverDetails.consent_forms.psp}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
                <Grid item xs={11} md={11}>

                    By checking the box, I (a) acknowledge that I have read and understand the PSP Disclosure and Authorization
                    and also have been given the opportunity to copy/print it, and (b) agree to use an electronic signature to
                    demonstrate my consent. An electronic signature is as legally binding as an ink signature.
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant='subtitle2'><a href={clearingHouse} target="_blank" >*Clearinghouse Release</a></Typography>

                </Grid>
                <Grid item xs={1} md={1}>
                    <Checkbox
                        id="clearing_house"
                        name="clearing_house"
                        checked={DriverDetails.consent_forms.clearing_house}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
                <Grid item xs={11} md={11}>

                    By checking the box, I (a) acknowledge that I have read and understand the above and also have been
                    given the opportunity to copy/print it, and (b) agree to use an electronic signature to demonstrate my
                    consent. An electronic signature is as legally binding as an ink signature.
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant='subtitle2'><a href={prevEmployer} target="_blank" >*Employment Verification Acknowledgment and Release (DOT Drug and Alcohol) </a></Typography>

                </Grid>
                <Grid item xs={1} md={1}>
                    <Checkbox
                        id="prev_employer"
                        name="prev_employer"
                        checked={DriverDetails.consent_forms.prev_employer}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
                <Grid item xs={11} md={11}>

                    By checking the box, I (a) acknowledge that I have read and understand the above and also have been
                    given the opportunity to copy/print it, and (b) agree to use an electronic signature to demonstrate my
                    consent. An electronic signature is as legally binding as an ink signature.
                </Grid>

                <Grid item xs={12} md={12}>
                    <Typography variant='subtitle2'><a href={medical} target="_blank" >*Medical Declaration </a></Typography>

                </Grid>
                <Grid item xs={1} md={1}>
                    <Checkbox
                        id="medical"
                        name="medical"
                        checked={DriverDetails.consent_forms.medical}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
                <Grid item xs={11} md={11}>

                    By checking the box, I (a) acknowledge that I have read and understand the above and also have been
                    given the opportunity to copy/print it, and (b) agree to use an electronic signature to demonstrate my
                    consent. An electronic signature is as legally binding as an ink signature.
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant='subtitle2'><a href={license} target="_blank" >*Certification of Compliance with Driver License Requirements</a></Typography>

                </Grid>
                <Grid item xs={1} md={1}>
                    <Checkbox
                        id="license"
                        name="license"
                        checked={DriverDetails.consent_forms.license}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
                <Grid item xs={11} md={11}>

                    By checking the box, I (a) acknowledge that I have read and understand the above and also have been
                    given the opportunity to copy/print it, and (b) agree to use an electronic signature to demonstrate my
                    consent. An electronic signature is as legally binding as an ink signature.
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant='subtitle2'><a href={consent} target="_blank" >*Consent Release of Individual Information</a></Typography>

                </Grid>
                <Grid item xs={1} md={1}>
                    <Checkbox
                        id="consent"
                        name="consent"
                        checked={DriverDetails.consent_forms.consent}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </Grid>
                <Grid item xs={11} md={11}>
                    By checking the box, I (a) acknowledge that I have read and understand the above and also have been
                    given the opportunity to copy/print it, and (b) agree to use an electronic signature to demonstrate my
                    consent. An electronic signature is as legally binding as an ink signature.
                </Grid>

            </Grid>
        </>
    );
};

export default ConsentForms;
