export const allDriverStatus = [
    'new_applicant',
    'in_progress', // when complete at least 1 step/progress to next step
    'hired',       // when all steps completed
    'inactive',     // after all completed
    'fired',        // after all completed
    'rejected',     // after in_progress but opposite of hired
]


export const allIncidentType = [
    'hit_third_party',
    'hit_by_third_party',
    'rollover',
    'low_bridge',
    'pedestrian_involved',
    'fender_bender',
    'cargo_damage',
    'hit_stationary_object',
    'other'
]

export const allIncidentStatus = [
    'open',
    'closed',
]

export const allWeatherOptions = [
    'high_visibility',
    'low_visibility',
    'snowing',
    'drug_and_alcohol',
    'windy_conditions',
    'rain',
    'dark',
    'other'
]

export const allRoadOptions = [
    'construction_zone',
    'highway_2_lane',
    'city_roads',
    'highway_single_lane',
    'highway_4_lane',
    'other'
]


export const allRegion = [
    'city',
    'team',
    'midwest',
    'long_haul',
    'west_coast'
]

export const allDriverType = [
    'owner_operator',
    'company_driver'
]

export const allTasks = [
    'drug_test',
    'insurance_approval',
    'interview_scheduled',
    'offer_letter',
]

export const allFiles = [
    'abstract',
    'additional_docs',
    'cvor',
    'drug_test',
    'letter_of_experience',
    'license',
    'offer_letter',
    'resume'
]

export const baseQuestions = [
    'Are you FAST approved?',
    'How many years of experience do you have?',
    'Are you legally eligble to enter the USA?',
    'What country is your citizenship from?',
    'Do you have a CSA card?'
]

export const countries = [
    "Canada",
    "U.S.A",
    "Mexico"
]

export const CanadaProvinces = [
    'AB - Alberta',
    'BC - British Columbia',
    'MB - Manitoba',
    'NB - New Brunswick',
    'NL - Newfoundland and Labrador',
    'NS - Nova Scotia',
    'NT - Northwest Territories',
    'NU - Nunavut',
    'ON - Ontario',
    'PE - Prince Edward Island',
    'QC - Quebec',
    'SK - Saskatchewan',
    'YT - Yukon',
]

export const USAStates = [
    'AL - Alabama',
    'AK - Alaska',
    'AZ - Arizona',
    'AR - Arkansas',
    'CA - California',
    'CO - Colorado',
    'CT - Connecticut',
    'DE - Delaware',
    'FL - Florida',
    'GA - Georgia',
    'HI - Hawaii',
    'ID - Idaho',
    'IL - Illinois',
    'IN - Indiana',
    'IA - Iowa',
    'KS - Kansas',
    'KY - Kentucky',
    'LA - Louisiana',
    'ME - Maine',
    'MD - Maryland',
    'MA - Massachusetts',
    'MI - Michigan',
    'MN - Minnesota',
    'MS - Mississippi',
    'MO - Missouri',
    'MT - Montana',
    'NE - Nebraska',
    'NV - Nevada',
    'NH - New Hampshire',
    'NJ - New Jersey',
    'NM - New Mexico',
    'NY - New York',
    'NC - North Carolina',
    'ND - North Dakota',
    'OH - Ohio',
    'OK - Oklahoma',
    'OR - Oregon',
    'PA - Pennsylvania',
    'RI - Rhode Island',
    'SC - South Carolina',
    'SD - South Dakota',
    'TN - Tennessee',
    'TX - Texas',
    'UT - Utah',
    'VT - Vermont',
    'VA - Virginia',
    'WA - Washington',
    'WV - West Virginia',
    'WI - Wisconsin',
    'WY - Wyoming',
]

export const MexicoStates = [
    'AGU - Aguascalientes',
    'BCN - Baja California',
    'BCS - Baja California Sur',
    'CAM - Campeche',
    'CHP - Chiapas',
    'CHH - Chihuahua',
    'COA - Coahuila',
    'COL - Colima',
    'DIF - Ciudad de Mexico (Federal District)',
    'DUR - Durango',
    'GUA - Guanajuato',
    'GRO - Guerrero',
    'HID - Hidalgo',
    'JAL - Jalisco',
    'MEX - Mexico State',
    'MIC - Michoacán',
    'MOR - Morelos',
    'NAY - Nayarit',
    'NLE - Nuevo Leon',
    'OAX - Oaxaca',
    'PUE - Puebla',
    'QUE - Queretaro',
    'ROO - Quintana Roo',
    'SIN - Sinaloa',
    'SLP - San Luis Potosi',
    'SON - Sonora',
    'TAB - Tabasco',
    'TAM - Tamaulipas',
    'TLA - Tlaxcala',
    'VER - Veracruz',
    'YUC - Yucatan',
    'ZAC - Zacatecas',
]

export const RequiredInfo = ["first_name", "last_name", "email", "phone"]
export const RequiredFiles = ["cvor", "abstract", "license"]

export const TruckType = ['cargo', 'dump_truck', 'tanker', 'semi_truck', 'tow_truck', 'utility_truck', 'bus']
export const TrailerType = ['none', 'flat', 'reefer', 'dry_van', 'containers']
export const ReasonLeft = ['quit', 'fired']

export const AllViolation = [
    'speeding',
    'reckless_driving',
    'driving_under_influece',
    'distracted_driving',
    'failure_obey_traffic_sign',
    'no_insurance',
    'failure_to_signal'
]

export const AllServices = [
    'driver_management',
    'claim_management'
]

export const AllClientStatus = [
    'active',
    'inactive'
]

export const AllEducationList = [
    'grade_1',
    'grade_2',
    'grade_3',
    'grade_4',
    'grade_5',
    'grade_6',
    'grade_7',
    'grade_8',
    'grade_9',
    'grade_10',
    'grade_11',
    'grade_12',
    'post_secondary_year_1',
    'post_secondary_year_2',
    'post_secondary_year_3',
    'post_secondary_year_4'
]

export const AllRangeMiles = [
    '0-500',
    '501-1000',
    '1001-2500',
    '2501-3500',
    '3500+'
]