import React from 'react';
import {
    Typography,
    List,
    ListItem,
    ListItemText,
    Paper,
    Grid,
} from '@mui/material';
import { capitalCase } from 'change-case';

const DisplayExperience = ({ workExperience }) => {

    return (
        <Grid item md={12}>
            <Paper elevation={3} style={{ padding: '20px' }}>
                <Typography variant="h5" gutterBottom>
                    Work Experience
                </Typography>
                {workExperience.map((experience, index) => {
                    const startDate = experience.start_date.toString().slice(0, 16)
                    const endDate = experience.end_date.toString().slice(0, 16)
                    return (
                        <List key={index}>
                            <ListItem >
                                <ListItemText
                                    primary={<Typography variant="body" >
                                        <strong>{experience.company_name}</strong> - {startDate} to {endDate}
                                    </Typography>}
                                    secondary={
                                        <>
                                            <Typography component="span" variant="body1" color="textPrimary">
                                                <strong>Position:</strong> {experience.position}
                                            </Typography>
                                            <br />
                                            <Typography component="span" variant="body1" color="textPrimary">
                                                <strong>Commercial Experience: </strong> {experience.operate_commercial ? 'Yes' : 'No'}
                                            </Typography>
                                            {experience.operate_commercial && (
                                                <>
                                                    <br />
                                                    <Typography component="span" variant="body2" color="textPrimary">
                                                        <strong>Truck Type:</strong> {capitalCase(experience.commercial_exp.truck)}
                                                    </Typography>
                                                    <br />
                                                    <Typography component="span" variant="body2" color="textPrimary">
                                                        <strong>Trailer Type:</strong> {capitalCase(experience.commercial_exp.trailer)}
                                                    </Typography>
                                                    <br />
                                                    <Typography component="span" variant="body2" color="textPrimary">
                                                        <strong>Driver Type:</strong> {capitalCase(experience.commercial_exp.type)}
                                                    </Typography>
                                                    <br />
                                                    <Typography component="span" variant="body2" color="textPrimary">
                                                        <strong>Cross Border:</strong>{' '}
                                                        {experience.commercial_exp.cross_border ? 'Yes' : 'No'}
                                                    </Typography>
                                                </>
                                            )}
                                        </>

                                    }
                                />
                            </ListItem>
                        </List>
                    )
                })}

            </Paper>
        </Grid>
    );
};

export default DisplayExperience;