import { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Grid, Paper, Typography } from '@mui/material';
import { format } from 'date-fns'; // Import date-fns for date formatting
import { capitalCase } from 'change-case';
import { toast } from 'react-toastify';
import { putRequest } from '../../../../shared/apiCalls';
import { isPhoneNumberValid, isEmailValid } from '../../../../shared/dataValidation';
import PersonalInfo from '../application_components/PersonalInfo';


const DisplayPersonal = ({ driverDetails, setDriverDetails }) => {
    const [editFields, setEditFields] = useState(false)
    const [disableSave, setDisableSave] = useState(false)

    const [tempDriverDetails, setTempDriverDetails] = useState({
        'first_name': driverDetails.first_name,
        'last_name': driverDetails.last_name,
        'birthday': driverDetails.birthday,
        'address': driverDetails.address,
        'email': driverDetails.email,
        'phone': driverDetails.phone,
        'region': driverDetails.region,
        'driver_type': driverDetails.driver_type
    })
    useEffect(() => {
        if (tempDriverDetails.first_name.trim() === '' || tempDriverDetails.last_name.trim() === ''
            || tempDriverDetails.address.street.trim() === '' || tempDriverDetails.address.city.trim() === ''
            || !isPhoneNumberValid(tempDriverDetails.phone) || tempDriverDetails.phone.trim() === ''
            || !isEmailValid(tempDriverDetails.email) || tempDriverDetails.email.trim() === ''
            || tempDriverDetails.address.postal_zip.trim() === '') {
            setDisableSave(true)
        } else {
            setDisableSave(false)
        }


    }, [tempDriverDetails])

    const handleSubmit = async (e) => {
        setDisableSave(true)
        const data = {
            'first_name': tempDriverDetails.first_name.trim(),
            'last_name': tempDriverDetails.last_name.trim(),
            'birthday': tempDriverDetails.birthday,
            'address': tempDriverDetails.address,
            'email': tempDriverDetails.email.trim(),
            'phone': tempDriverDetails.phone.trim(),
            'region': tempDriverDetails.region,
            'driver_type': tempDriverDetails.driver_type
        }
        try {
            const response = await toast.promise(putRequest(`driver/${driverDetails.id}`, data),
                {
                    pending: 'Updating Driver...',
                    success: 'Driver Updated',
                    error: 'Error Updating Driver'
                })

            setDriverDetails({
                ...driverDetails,
                ...data
            });
            handleClose()
        } catch (error) {
            console.error(`Error: ${error}`)
        }
        setDisableSave(true)
        setEditFields(false);

    }

    const handleOpen = () => {
        setTempDriverDetails({
            'first_name': driverDetails.first_name,
            'last_name': driverDetails.last_name,
            'birthday': driverDetails.birthday,
            'address': driverDetails.address,
            'email': driverDetails.email,
            'phone': driverDetails.phone,
            'region': driverDetails.region,
            'driver_type': driverDetails.driver_type
        })
        setEditFields(true)
    }

    const handleClose = () => {
        setEditFields(false)
        setTempDriverDetails({
            'first_name': driverDetails.first_name,
            'last_name': driverDetails.last_name,
            'birthday': driverDetails.birthday,
            'address': driverDetails.address,
            'email': driverDetails.email,
            'phone': driverDetails.phone
        })
    }

    return (
        <>
            <Grid item xs={6}>
                <Paper elevation={3} style={{ padding: '20px' }}>

                    <Typography variant="h5">Personal Information
                        <Button onClick={handleOpen}>Edit</Button>
                    </Typography>
                    <Typography>{`Name: ${driverDetails.first_name} ${driverDetails.last_name}`}</Typography>
                    <Typography>{`Email: ${driverDetails.email}`}</Typography>
                    <Typography>{`Phone: ${driverDetails.phone}`}</Typography>
                    <Typography>{`Birthday: ${format(new Date(driverDetails.birthday), 'MM/dd/yyyy')}`}</Typography>
                    <Typography>{`Preferred Region: ${capitalCase(driverDetails.region)}`} </Typography>
                    <Typography>{`Driver Type: ${capitalCase(driverDetails.driver_type)}`} </Typography>

                    {/* Add more personal information fields as needed */}
                </Paper>
            </Grid>

            {/* Address */}
            <Grid item xs={6}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                    <Typography variant="h5"> Address
                        <Button onClick={handleOpen}>Edit</Button>
                    </Typography>                    <Typography>{`Street: ${driverDetails.address.street}`}</Typography>
                    <Typography>{`City: ${driverDetails.address.city}`}</Typography>
                    <Typography>{`Province/State: ${driverDetails.address.province_state}`}</Typography>
                    <Typography>{`Postal/ZIP Code: ${driverDetails.address.postal_zip}`}</Typography>
                    {/* Add more address fields as needed */}
                </Paper>
            </Grid>

            {editFields &&
                <Dialog
                    onClose={handleClose} open={editFields}
                    aria-labelledby="form-dialog"
                    maxWidth='md'
                    fullWidth
                >

                    <DialogContent>
                        <PersonalInfo DriverDetails={tempDriverDetails} setDriverDetails={setTempDriverDetails}
                            setAllowNext={() => { }} update />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary"
                            variant="contained"
                            disabled={disableSave}
                            onClick={handleSubmit}>Confirm</Button>
                    </DialogActions>
                </Dialog>}


        </>


    );
};

export default DisplayPersonal;
