import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Stepper, Step, StepLabel, Button, Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import IncidentGeneralInfo from './incident_components/IncidentGeneralInfo';
import AddIncidentFiles from './incident_components/AddIncidentFiles';
import { postRequestFile, postRequest, putRequest } from '../../../shared/apiCalls';

// This is basic form used to Edit and Add Incidents
function IncidentForm({ incidentData, onClose, open, setIncidents, editIncident = false }) {
    const [allowNext, setAllowNext] = useState(false)
    const [IncidentInfo, setIncidentInfo] = useState(incidentData)
    const [IncidentFiles, setIncidentFiles] = useState({
        'photo_1': '',
        'photo_2': '',
        'photo_3': '',
        'photo_4': '',
        'photo_5': '',
        'police_report': '',
        'release_form': '',
        'signed_driver_statement': '',
    })

    const handleFileSubmit = async (e) => {
        //  DriverFiles
        // load multiple files and confirm 0 and single still works

        const files = ['police_report', 'release_form', 'signed_driver_statement',
            'photo_1', 'photo_2', 'photo_3', 'photo_4', 'photo_5']
        const formData = new FormData();
        files.forEach(key => {
            if (IncidentFiles[key] !== '') {
                formData.append(key, IncidentFiles[key])
            }
        })
        const response = await toast.promise(postRequestFile(
            `incidentdocuments/${IncidentInfo.id}`,
            formData
        ), {
            pending: 'Submitting Files...',
            success: 'Files Submitted',
            error: 'Error Submitting Files'
        })
        onClose()

    }

    const handleIncidentCreate = async (e) => {
        try {
            const data = {
                "additional_details": IncidentInfo.additional_details.trim(),
                "bodily_injuries": IncidentInfo.bodily_injuries,
                "cargo_damage": IncidentInfo.cargo_damage,
                "claim_number": IncidentInfo.claim_number,
                "date": IncidentInfo.date.toISOString(),
                "driver_id": IncidentInfo.driver_id,
                'fatalities': IncidentInfo.fatalities,
                'fault_driver': IncidentInfo.fault_driver,
                'files_available': IncidentInfo.files_available,
                'filing_claim': IncidentInfo.filing_claim,
                'incident_type': IncidentInfo.incident_type,
                'last_truck_maintenance': IncidentInfo.last_truck_maintenance.toISOString(),
                'location': IncidentInfo.location,
                'road_condition': IncidentInfo.road_condition,
                'status': IncidentInfo.status,
                'trailer_damage': IncidentInfo.trailer_damage,
                'truck_damage': IncidentInfo.truck_damage,
                'video_link': IncidentInfo.video_link,
                'weather_condition': IncidentInfo.weather_condition,
            }


            if (editIncident === true) {
                // await toast.promise(putRequest(`incidents/${IncidentInfo.id}`, data),
                // {
                //     pending: 'Adding Incident...',
                //     success: 'New Incident Created',
                //     error: 'Error Creating Incident'
                // })
            } else {
                const response = await toast.promise(postRequest('incidents', data),
                    {
                        pending: 'Adding Incident...',
                        success: 'New Incident Created',
                        error: 'Error Creating Incident'
                    })
                setIncidentInfo({
                    ...IncidentInfo,
                    'id': response.id
                })
                setIncidents((prev) => {
                    const oldlist = prev
                    oldlist.push({
                        'date': IncidentInfo.date,
                        'incident_type': IncidentInfo.incident_type,
                        'location': `${IncidentInfo.location.province_state}, ${IncidentInfo.location.country}`
                    })
                    return oldlist
                })
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);


        } catch (e) {
            console.error(e)
        }
    }


    const [activeStep, setActiveStep] = useState(0);

    const steps = ['Incident Info', 'Files'];

    return (
        <Dialog
            onClose={onClose} open={open}
            aria-labelledby="form-dialog"
            maxWidth='lg'
        >
            <DialogTitle id="form-dialog">Report Incident</DialogTitle>
            <DialogContent>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === 0 &&
                    <IncidentGeneralInfo IncidentInfo={IncidentInfo} setIncidentInfo={setIncidentInfo} setAllowNext={setAllowNext} />}
                {activeStep === 1 &&
                    <AddIncidentFiles IncidentFiles={IncidentFiles} setIncidentFiles={setIncidentFiles} setAllowNext={setAllowNext} />
                }

                <Grid container justify="center" mt={5}>
                    <Grid item xs={12} md={12} lg={12}>
                        {activeStep === 1 ?
                            <Button variant="contained"
                                fullWidth
                                disabled={!allowNext}
                                onClick={handleFileSubmit}
                                style={{
                                    maxWidth: '300px', maxHeight: '300px'
                                }}
                                color="primary">
                                Submit Files
                            </Button>
                            : (
                                <Button variant="contained"
                                    fullWidth
                                    disabled={!allowNext}
                                    style={{
                                        maxWidth: '200px', maxHeight: '300px'
                                    }}
                                    color="primary"
                                    onClick={handleIncidentCreate}>
                                    Create Incident
                                </Button>
                            )}

                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )


}

export default IncidentForm