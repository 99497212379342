import { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { Typography, FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import { capitalCase } from 'change-case';
import AddressDetails from '../../../../shared/AddressDetails';
import { isEmailValid, isPhoneNumberValid } from '../../../../shared/dataValidation';
import { allRegion, allDriverType } from '../../../../utils/dropOptions';


function PersonalInfo({ DriverDetails, setDriverDetails, companyName, update = false }) {
    const today = new Date();
    const maxBirthday = new Date(today);
    maxBirthday.setFullYear(today.getFullYear() - 24);


    const handleBirthdayChange = (e) => {
        if (e == null) {
            alert('Please Select Valid Date')
            setDriverDetails((prevData) => {
                return { ...prevData, 'birthday': maxBirthday }
            });
        } else if (e >= maxBirthday) {
            alert('Need to be 18 years or older to apply!');

        } else {
            setDriverDetails((prevData) => {
                return { ...prevData, 'birthday': e }
            });
        }
        // 

    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Add a regular expression to restrict the SIN to 9 digits

        if (name === 'can_contact'
            || name === 'privacy_policy' || name === 'terms_conditions') {
            setDriverDetails((prevData) => {
                return {
                    ...prevData,
                    [name]: e.target.checked
                }
            });

        } else if (name === 'country' || name === 'street'
            || name === 'city' || name === 'province_state'
            || name === 'postal_zip') {
            const data = DriverDetails.address
            data[name] = value

            if (name === 'country') {
                if (value === 'Canada') {
                    data.province_state = 'ON - Ontario'
                    data.postal_zip = ''
                } else if (value === 'U.S.A') {
                    data.province_state = 'NY - New York'
                    data.postal_zip = ''
                } else {
                    data.province_state = 'SIN - Sinaloa'
                    data.postal_zip = ''
                }
            }

            setDriverDetails(DriverDetails => {
                return { ...DriverDetails, address: data }
            });
        } else {
            setDriverDetails((prevData) => {
                return {
                    ...prevData,
                    [name]: value
                }
            });
        }
    };


    return (
        <form >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop={5}
                marginBottom={3}

            >
                <Typography variant="h6">Enter your personal information below</Typography>
            </Box>
            <Grid container spacing={3} paddingX={4}>

                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name='first_name'
                        onChange={(e) => handleChange(e)}
                        value={DriverDetails.first_name}
                        fullWidth
                        label='First Name'
                        autoComplete='given-name'
                        variant='standard'
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name='last_name'
                        onChange={(e) => handleChange(e)}
                        value={DriverDetails.last_name}
                        label='Last name'
                        fullWidth
                        autoComplete='family-name'
                        variant='standard'
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            required
                            name='birthday'
                            label={'Date of Birth'}
                            value={DriverDetails.birthday}
                            onChange={(e) => handleBirthdayChange(e)}
                            slotProps={{ textField: { fullWidth: true, } }}
                            maxDate={maxBirthday}
                        />
                    </LocalizationProvider>
                </Grid>
                <AddressDetails Address={DriverDetails.address} handleChange={handleChange} />

                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name='email'
                        label='Email'
                        onChange={(e) => handleChange(e)}
                        value={DriverDetails.email}
                        fullWidth
                        autoComplete='email'

                        variant='standard'
                        error={!isEmailValid(DriverDetails.email)}
                        helperText={
                            !isEmailValid(DriverDetails.email)
                                ? 'Invalid email format'
                                : ''
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name='phone'
                        label='Phone #'
                        onChange={(e) => handleChange(e)}
                        value={DriverDetails.phone}
                        fullWidth
                        autoComplete='tel'
                        variant='standard'
                        error={!isPhoneNumberValid(DriverDetails.phone)}
                        helperText={
                            !isPhoneNumberValid(DriverDetails.phone)
                                ? 'Phone number must have 10 digits'
                                : ''
                        }

                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name='sin'
                        label='S.I.N (9 digits)'
                        onChange={handleChange}
                        value={DriverDetails.sin}
                        fullWidth
                        inputProps={{ maxLength: 50 }}
                        variant='standard'

                    />
                </Grid>
                {update ?
                    <>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth >
                                <InputLabel id='region-select-label'>Preferred Working Region</InputLabel>
                                <Select
                                    labelId='region-select-label'
                                    id='region-select'
                                    name='region'
                                    onChange={handleChange}
                                    value={DriverDetails.region}
                                    // fullWidth
                                    input={<OutlinedInput label='Preferred Working Region' />}
                                >
                                    {allRegion.map((t) => (
                                        <MenuItem key={t} value={t}>
                                            <ListItemText primary={capitalCase(t)} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth >
                                <InputLabel id='driver_type-select-label'>Driver Type</InputLabel>
                                <Select
                                    labelId='driver_type-select-label'
                                    id='driver_type-select'
                                    name='driver_type'
                                    onChange={handleChange}
                                    value={DriverDetails.driver_type}
                                    // fullWidth
                                    input={<OutlinedInput label='Driver Type' />}
                                >
                                    {allDriverType.map((t) => (

                                        <MenuItem key={t} value={t}>
                                            <ListItemText primary={capitalCase(t)} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </>
                    : <>

                        <Grid item xs={12} md={12}>

                            <Checkbox
                                id="can_contact"
                                name="can_contact"
                                checked={DriverDetails.can_contact}
                                onChange={(e) => handleChange(e)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            {`Do you accept to be contacted via SMS, phone and/or email in regards to your application?`}
                            {!DriverDetails.can_contact && <Typography variant="body2" style={{ color: 'red' }}>
                                Required
                            </Typography>}
                        </Grid>


                    </>

                }
            </Grid>

        </form >

    )


}

export default PersonalInfo