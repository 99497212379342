import { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { capitalCase } from 'change-case';
import Box from '@mui/material/Box';
import {
    Radio, FormControlLabel, RadioGroup, FormControl, FormLabel, IconButton,
    Button, ListItem, Typography, Divider, InputAdornment
} from '@mui/material';
import { countries, CanadaProvinces, USAStates, MexicoStates, allIncidentType } from '../../../../utils/dropOptions';


function RecentAccident({ DriverAccident, setDriverAccident }) {
    const yesValue = true
    const noValue = false

    const initialAccident = {
        'date': '',
        'type': 'hit_third_party',
        'hazmat': false,
        'towed_vehicle': false,
        'location': {
            'country': 'Canada',
            'province_state': 'ON - Ontario',
        },
        'commercial': null, // check
        'dot_recordable': null, // if above yes
        'at_fault': null,
        'receive_ticket': null,
        'detailed_info': '' // add provide detailed info if chargeable, recordable, reportable, at fault?
    }
    const addItem = () => {
        // add limit of 5
        const updatedItem = DriverAccident.details
        updatedItem.push(initialAccident)
        setDriverAccident((prevData) => ({
            ...prevData, 'details': updatedItem
        }))
    }

    const deleteItem = (index) => {

        const updatedItems = DriverAccident.details.filter((_, i) => i !== index);
        setDriverAccident((prevData) => ({
            ...prevData, 'details': updatedItems
        }))

    }

    // Need to include index
    const handleDateChange = (date, e, index) => {
        if (e == null) {
            alert('Please Select Valid Date')
        } else {
            const updatedExperience = [...DriverAccident.details]
            updatedExperience[index] = {
                ...updatedExperience[index],
                [date]: e
            }
            setDriverAccident((prevData) => ({
                ...prevData, 'details': updatedExperience
            }))
        }
    }
    // Need to include index

    const handleInvolveChange = (e) => {
        const { name, value } = e.target
        if (value === 'true') {
            setDriverAccident({
                'involved_accident': true,
                'details': [initialAccident]
            });
        } else {
            setDriverAccident({
                'involved_accident': false,
                'details': []
            });

        }

    }

    const handleTFChange = (e, index) => {
        const { name, value } = e.target;
        const updatedExperience = [...DriverAccident.details]
        if (value === 'true') {
            updatedExperience[index] = {
                ...updatedExperience[index],
                [name]: true
            }
        } else {
            updatedExperience[index] = {
                ...updatedExperience[index],
                [name]: false
            }
        }

        setDriverAccident((prevData) => ({
            ...prevData, 'details': updatedExperience
        }))
    }
    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedExperience = [...DriverAccident.details]
        // 'description': ''
        if (name === 'hazmat' || name === 'towed_vehicle') {
            updatedExperience[index] = {
                ...updatedExperience[index],
                [name]: value
            }
            setDriverAccident((prevData) => ({
                ...prevData, 'details': updatedExperience
            }))
        } else if (name === 'country') {
            let provinceState = ''

            if (value === "Canada") {
                provinceState = 'ON - Ontario'
            } else if (value === "U.S.A") {
                provinceState = 'NY - New York'
            } else {
                provinceState = 'SIN - Sinaloa'
            }

            updatedExperience[index] = {
                ...updatedExperience[index],
                'location': {
                    'country': value,
                    'province_state': provinceState,
                }
            }

            setDriverAccident((prevData) => ({
                ...prevData, 'details': updatedExperience
            }))
        } else if (name === 'province_state') {
            updatedExperience[index] = {
                ...updatedExperience[index],
                'location': {
                    'country': updatedExperience[index].location.country,
                    'province_state': value,
                }
            }

            setDriverAccident((prevData) => ({
                ...prevData, 'details': updatedExperience
            }))
        } else {
            updatedExperience[index] = {
                ...updatedExperience[index],
                [name]: value
            }
            setDriverAccident((prevData) => ({
                ...prevData, 'details': updatedExperience
            }))
        }


    };


    return (
        <form>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop={5}
                marginBottom={3}
                minWidth="800px"
            >
                <FormControl component="fieldset">
                    <Typography variant="h6">Have you been in an accident in last 5 years, even if not at fault?</Typography>
                    <RadioGroup
                        row
                        name="involved_accident"
                        value={DriverAccident.involved_accident}
                        onChange={(e) => handleInvolveChange(e)}
                        required
                    >
                        <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                        <FormControlLabel value={noValue} control={<Radio />} label="No" />


                    </RadioGroup>
                </FormControl>


            </Box>
            {DriverAccident.involved_accident === true ?
                <>
                    <Divider sx={{ borderBottomWidth: 5 }} />

                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        marginTop={1}
                    >
                        <Typography variant='subtitle2'>Provide details of all accidents you have been involved in last 5 years. </Typography>
                    </Box>
                    {DriverAccident.details.map((value, index) => {
                        return (
                            <ListItem
                                key={index}
                                secondaryAction={
                                    index !== 0 ?
                                        <IconButton color='error' onClick={() => deleteItem(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        :
                                        <></>


                                }
                            >
                                <Grid key={index} container spacing={3} >
                                    <Grid item xs={12} md={12}>
                                        <Typography variant='h5'>ACCIDENT #{index + 1}:</Typography>

                                    </Grid>

                                    <Grid item xs={12} md={8}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                required
                                                name='date'
                                                label={'Date Occured*'}
                                                value={value.date}
                                                onChange={(e) => handleDateChange('date', e, index)}
                                                maxDate={new Date()}

                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth >
                                            <InputLabel id='type-select-label'>What type of accident were you involved in?</InputLabel>
                                            <Select
                                                labelId='type-select-label'
                                                id='type-select'
                                                name='type'
                                                onChange={(e) => handleChange(e, index)}
                                                value={value.type}
                                                // fullWidth
                                                input={<OutlinedInput label='What type of accident were you involved in?' />}
                                            >
                                                {allIncidentType.map((t) => (
                                                    <MenuItem key={t} value={t}>
                                                        <ListItemText primary={capitalCase(t)} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <FormControl fullWidth >
                                            <InputLabel id='hazmat-select-label'>Was any hazardous material involved?</InputLabel>
                                            <Select
                                                labelId='hazmat-select-label'
                                                id='hazmat-select'
                                                name='hazmat'
                                                onChange={(e) => handleChange(e, index)}
                                                value={value.hazmat}
                                                input={<OutlinedInput label='Was any hazardous material involved?' />}
                                            >
                                                <MenuItem value={yesValue}>
                                                    <ListItemText primary='Yes' />
                                                </MenuItem>
                                                <MenuItem value={noValue}>
                                                    <ListItemText primary='No' />
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <FormControl fullWidth style={{
                                            maxWidth: '370px'
                                        }}  >
                                            <InputLabel id='towed_vehicle-select-label'>Were any vehicle towed from the accident?</InputLabel>
                                            <Select
                                                labelId='towed_vehicle-select-label'
                                                id='towed_vehicle-select'
                                                name='towed_vehicle'
                                                onChange={(e) => handleChange(e, index)}
                                                value={value.towed_vehicle}
                                                input={<OutlinedInput label='Were any vehicle towed from the accident scene?' />}
                                            >
                                                <MenuItem value={yesValue}>
                                                    <ListItemText primary='Yes' />
                                                </MenuItem>
                                                <MenuItem value={noValue}>
                                                    <ListItemText primary='No' />
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}  >

                                        <TextField
                                            name='country'
                                            onChange={(e) => handleChange(e, index)}
                                            style={{ width: '100%' }}
                                            select
                                            variant={'outlined'}
                                            value={value.location.country}
                                            label={'Which country did accident occured?'}
                                        >
                                            {countries.map((t) => (

                                                <MenuItem key={t} value={t}>
                                                    <ListItemText primary={t} />
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} md={6}  >
                                        <TextField
                                            name='province_state'
                                            select
                                            onChange={(e) => handleChange(e, index)}
                                            style={{ width: '100%' }}
                                            variant={'outlined'}
                                            value={value.location.province_state}
                                            label={'Which Province/State?'}
                                        >
                                            {(value.location.country === 'Canada') ?
                                                CanadaProvinces.map((t) => (
                                                    <MenuItem key={t} value={t}>
                                                        <ListItemText primary={t} />
                                                    </MenuItem>
                                                )) :
                                                (value.location.country === 'U.S.A') ?
                                                    USAStates.map((t) => (
                                                        <MenuItem key={t} value={t}>
                                                            <ListItemText primary={t} />
                                                        </MenuItem>
                                                    )) :
                                                    MexicoStates.map((t) => (
                                                        <MenuItem key={t} value={t}>
                                                            <ListItemText primary={t} />
                                                        </MenuItem>
                                                    ))

                                            }

                                        </TextField>
                                    </Grid>
                                    <Grid item md={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">*Did it occur while you were operating a commerical vehicle?</FormLabel>
                                            <RadioGroup
                                                row
                                                name="commercial"
                                                value={value.commercial}
                                                onChange={(e) => handleTFChange(e, index)}>
                                                <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                                                <FormControlLabel value={noValue} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {
                                        value.commercial &&
                                        <>
                                            <Grid item md={1} />
                                            <Grid item md={11}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">*Was this a Department of Transportation recordable accident?</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        name="dot_recordable"
                                                        value={value.dot_recordable}
                                                        onChange={(e) => handleTFChange(e, index)}>
                                                        <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                                                        <FormControlLabel value={noValue} control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                        </>

                                    }
                                    <Grid item md={12} sm={12} lg={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">*Did you receive a ticket?</FormLabel>
                                            <RadioGroup
                                                row
                                                name="receive_ticket"
                                                value={value.receive_ticket}
                                                onChange={(e) => handleTFChange(e, index)}>
                                                <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                                                <FormControlLabel value={noValue} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12} sm={12} lg={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">*Were you at fault?</FormLabel>
                                            <RadioGroup
                                                row
                                                name="at_fault"
                                                value={value.at_fault}
                                                onChange={(e) => handleTFChange(e, index)}>
                                                <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                                                <FormControlLabel value={noValue} control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12} sm={12} lg={12}>
                                        <Typography variant='subtitle'>*Please provide the details regarding your accident.</Typography>
                                        <TextField
                                            name="detailed_info"
                                            inputProps={{ maxLength: 250 }}
                                            value={value.detailed_info}
                                            onChange={(e) => handleChange(e, index)}
                                            fullWidth
                                            multiline
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Typography color="textSecondary">{`${value.detailed_info.length}/250`}</Typography>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>

                                </Grid>
                            </ListItem>
                        )
                    })}
                    {DriverAccident.details.length < 5 ?
                        <Box
                            display="flex"
                            alignItems="left"
                            justifyContent="left"
                            marginTop={3}
                        >
                            < Button variant="outlined" onClick={() => addItem()}>
                                Add Accident
                            </Button >
                        </Box>
                        : <></>
                    }
                </> :
                <></>
            }
        </form>

    )


}

export default RecentAccident