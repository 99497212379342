import { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Select from '@mui/material/Select';
import { capitalCase } from 'change-case';
import Box from '@mui/material/Box';
import { Radio, FormControlLabel, RadioGroup, FormControl, Typography } from '@mui/material';
import { allRegion, allDriverType } from '../../../../utils/dropOptions';

function GeneralInfo({ DriverGeneral, setDriverGeneral }) {
    const today = new Date();
    const yesValue = true
    const noValue = false
    console.log(DriverGeneral)
    const handleDateChange = (e) => {
        setDriverGeneral((prevData) => ({
            ...prevData,
            'fast_expiry': e
        }))
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'fast' || name === 'enter_usa' || name === 'bonded') {
            if (value === 'true') {
                setDriverGeneral((prevData) => ({
                    ...prevData, [name]: true
                }))
            } else {
                setDriverGeneral((prevData) => ({
                    ...prevData, [name]: false
                }))
            }

        } else {
            setDriverGeneral((prevData) => ({
                ...prevData, [name]: value
            }))
        }

    };

    return (
        <form>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop={5}
                marginBottom={3}
            >
                <Typography variant='h6'>Enter the following information. </Typography>


            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth >
                        <InputLabel id='region-select-label'>Preferred Working Region</InputLabel>
                        <Select
                            labelId='region-select-label'
                            id='region-select'
                            name='region'
                            onChange={handleChange}
                            value={DriverGeneral.region}
                            // fullWidth
                            input={<OutlinedInput label='Preferred Working Region' />}
                        >
                            {allRegion.map((t) => (
                                <MenuItem key={t} value={t}>
                                    <ListItemText primary={capitalCase(t)} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth >
                        <InputLabel id='driver_type-select-label'>Driver Type</InputLabel>
                        <Select
                            labelId='driver_type-select-label'
                            id='driver_type-select'
                            name='driver_type'
                            onChange={handleChange}
                            value={DriverGeneral.driver_type}
                            // fullWidth
                            input={<OutlinedInput label='Driver Type' />}
                        >
                            {allDriverType.map((t) => (

                                <MenuItem key={t} value={t}>
                                    <ListItemText primary={capitalCase(t)} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={8} >
                    <FormControl component="fieldset">
                        <Typography variant='subtitle'>*Are you FAST approved?</Typography>
                        <RadioGroup
                            row
                            name="fast"
                            value={DriverGeneral.fast}
                            onChange={handleChange}>
                            <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                            <FormControlLabel value={noValue} control={<Radio />} label="No" />
                        </RadioGroup>

                    </FormControl>
                </Grid>


                {DriverGeneral.fast &&
                    <>
                        <Grid item xs={1} md={1} />
                        <Grid item xs={12} md={5} >
                            <Typography variant='subtitle'>*Please provide your FAST number.</Typography>
                            <TextField
                                name="fast_num"
                                required={DriverGeneral.fast_num === true}
                                value={DriverGeneral.fast_num}
                                onChange={handleChange}
                                fullWidth
                                InputProps={{ inputProps: { maxLength: 20 } }}
                                multiline />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Typography variant='subtitle'>*Please provide when your FAST expires.</Typography>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    required
                                    name='fast_expiry'
                                    value={DriverGeneral.fast_expiry}
                                    onChange={handleDateChange}
                                    slotProps={{ textField: { fullWidth: true, } }}
                                    maxDate={today}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </>

                }
                <Grid item xs={12} md={9}>
                    <FormControl component="fieldset">
                        <Typography variant='subtitle'>*Can you legally travel across Canada\USA border?</Typography>
                        <RadioGroup
                            row
                            name="enter_usa"
                            value={DriverGeneral.enter_usa}
                            onChange={handleChange}>
                            <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                            <FormControlLabel value={noValue} control={<Radio />} label="No" />
                        </RadioGroup>

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={9}>
                    <FormControl component="fieldset">
                        <Typography variant='subtitle'>*Have you ever been bonded?</Typography>
                        <RadioGroup
                            row
                            name="bonded"
                            value={DriverGeneral.bonded}
                            onChange={handleChange}>
                            <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                            <FormControlLabel value={noValue} control={<Radio />} label="No" />
                        </RadioGroup>

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={9}>
                    <Typography variant='subtitle'>*Enter your country citizenship.</Typography>
                    <TextField
                        name="citizenship"
                        value={DriverGeneral.citizenship}
                        onChange={handleChange}
                        fullWidth
                        inputProps={{ maxLength: 25 }} // Example: set max length to 250 characters
                    />
                </Grid>
                <Grid item xs={12} md={9}>
                    <Typography variant='subtitle'>Provide the name of current/former employee who referred you to our company.</Typography>
                    <TextField
                        name="driver_referral"
                        value={DriverGeneral.driver_referral}
                        onChange={handleChange}
                        fullWidth
                        inputProps={{ maxLength: 25 }} // Example: set max length to 250 characters
                        multiline />
                </Grid>

            </Grid>

        </form>

    )


}

export default GeneralInfo