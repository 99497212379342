import { useEffect } from 'react';
import { InputAdornment, Typography } from '@mui/material'
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { capitalCase } from 'change-case';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'react-toastify/dist/ReactToastify.css';
import {
    allIncidentType, allIncidentStatus,
    allWeatherOptions, allRoadOptions,
} from '../../../../utils/dropOptions';
import AddressDetails from '../../../../shared/AddressDetails';

// This is basic form used to Edit and Add Incident Info
function IncidentGeneralInfo({ IncidentInfo, setIncidentInfo, setAllowNext }) {

    const handleChangeDate = (e, name) => {
        if (e == null) {
            alert("Please Select Valid Date")
            setIncidentInfo(IncidentInfo => {
                return { ...IncidentInfo, [name]: new Date() }
            });
        } else {
            setIncidentInfo(IncidentInfo => {
                return { ...IncidentInfo, [name]: e }
            });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        const numericValues = ['bodily_injuries', 'cargo_damage', 'fatalities',
            'trailer_damage', 'truck_damage']

        if (numericValues.includes(name) && !/^\d*$/.test(value)) {
            return;
        }
        if (name === "country" || name === "street"
            || name === "city" || name === "province_state"
            || name === "postal_zip") {
            const data = IncidentInfo.location
            data[name] = e.target.value

            if (name === "country") {
                if (e.target.value === "Canada") {
                    data.province_state = 'ON - Ontario'
                } else if (e.target.value === "U.S.A") {
                    data.province_state = "NY - New York"
                } else {
                    data.province_state = 'SIN - Sinaloa'
                }
            }

            setIncidentInfo(IncidentInfo => {
                return { ...IncidentInfo, location: data }
            });
        } else {
            setIncidentInfo(IncidentInfo => {
                return { ...IncidentInfo, [name]: e.target.value }
            });
        }
    }

    useEffect(() => {
        if (IncidentInfo.location.city !== '' &&
            IncidentInfo.location.street !== '' && IncidentInfo.location.postal_zip !== ''
            && IncidentInfo.date && IncidentInfo.last_truck_maintenance) {
            setAllowNext(true)
        } else {
            setAllowNext(false)
        }

    }, [IncidentInfo])

    return (

        <form >

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} />
                <Grid item xs={12} md={12}  >
                    <TextField
                        name='status'
                        onChange={(e) => handleChange(e)}
                        select
                        variant={"outlined"} style={{ width: "25%" }}
                        value={IncidentInfo.status}
                        label={"Status"}
                    >
                        {allIncidentStatus.map((t) => (

                            <MenuItem key={t} value={t}>
                                <ListItemText primary={capitalCase(t)} />
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6}  >
                    <TextField
                        name='incident_type'
                        onChange={(e) => handleChange(e)}
                        select
                        variant={"outlined"} style={{ width: "100%" }}
                        value={IncidentInfo.incident_type}
                        label={"Type"}
                    >
                        {allIncidentType.map((t) => (

                            <MenuItem key={t} value={t}>
                                <ListItemText primary={capitalCase(t)} />
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            required
                            name="date"
                            label={"Date"}
                            value={IncidentInfo.date}
                            onChange={(e) => handleChangeDate(e, 'date')}
                            slotProps={{ textField: { fullWidth: true, } }}
                        />
                    </LocalizationProvider>
                </Grid>

                <AddressDetails Address={IncidentInfo.location} handleChange={handleChange} />

                <Grid item xs={12} md={12} lg={12}>
                    <Divider sx={{ borderBottomWidth: '2px' }} />
                </Grid>

                <Grid item xs={12} md={7} lg={7} >

                    <TextField
                        name='filing_claim'
                        onChange={(e) => handleChange(e)}
                        select
                        variant={"outlined"}
                        style={{ width: "60%" }}
                        value={IncidentInfo.filing_claim}
                        label={"Do you want to file insurance claim?"}
                    >
                        <MenuItem key={"false-fault"} value={false}>
                            <ListItemText primary={"No"} />
                        </MenuItem>
                        <MenuItem key={"true-fault"} value>
                            <ListItemText primary={"Yes"} />
                        </MenuItem>
                    </TextField>
                </Grid>

                {IncidentInfo.filing_claim ?
                    <Grid item xs={12} md={5} lg={5} >
                        <TextField
                            name='claim_number'
                            onChange={(e) => handleChange(e)}
                            variant={"outlined"} style={{ width: "90%" }}
                            value={IncidentInfo.claim_number}
                            label={"Claim Number"}
                        />

                    </Grid>
                    : <></>
                }
                <Grid item xs={12} md={7}  >
                    <TextField
                        name='fault_driver'
                        onChange={(e) => handleChange(e)}
                        select
                        variant={"outlined"} style={{ width: "60%" }}
                        value={IncidentInfo.fault_driver}
                        label={"Is Driver at Fault?"}
                    >
                        <MenuItem key={"false-fault"} value={false}>
                            <ListItemText primary={"No"} />
                        </MenuItem>
                        <MenuItem key={"true-fault"} value>
                            <ListItemText primary={"Yes"} />
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name='truck_damage'
                        onChange={(e) => handleChange(e)}
                        variant={"outlined"} style={{ width: "90%" }}
                        value={IncidentInfo.truck_damage}
                        InputProps={{
                            inputProps: {
                                min: 0
                            }
                        }}
                        label={"Estimated Tractor Damage (CAD)"}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            required
                            name="last_truck_maintenance"
                            label={"Date Last Truck Maintenance"}
                            value={IncidentInfo.last_truck_maintenance}
                            onChange={(e) => handleChangeDate(e, 'last_truck_maintenance')}
                            slotProps={{ textField: { fullWidth: true, } }}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        name='trailer_damage'
                        onChange={(e) => handleChange(e)}
                        variant={"outlined"} style={{ width: "90%" }}
                        value={IncidentInfo.trailer_damage}
                        InputProps={{
                            inputProps: {
                                min: 0
                            }
                        }}
                        label={"Estimated Trailer Damage (CAD)"}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name='cargo_damage'
                        onChange={(e) => handleChange(e)}
                        variant={"outlined"} style={{ width: "90%" }}
                        value={IncidentInfo.cargo_damage}
                        InputProps={{
                            inputProps: {
                                min: 0
                            },
                        }}
                        label={"Estimated Cargo Damage (CAD)"}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name='bodily_injuries'
                        onChange={(e) => handleChange(e)}
                        variant={"outlined"} style={{ width: "90%" }}
                        value={IncidentInfo.bodily_injuries}
                        InputProps={{
                            inputProps: {
                                min: 0
                            },
                        }}
                        label={"Number of People Injured"}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name='fatalities'
                        onChange={(e) => handleChange(e)}
                        variant={"outlined"} style={{ width: "90%" }}
                        value={IncidentInfo.fatalities}
                        InputProps={{
                            inputProps: {
                                min: 0
                            },
                        }}
                        label={"Number of Fatalities"}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name='weather_condition'
                        onChange={(e) => handleChange(e)}
                        select
                        variant={"outlined"} style={{ width: "90%" }}
                        value={IncidentInfo.weather_condition}
                        label={"Weather Condition at Time of Incident"}
                    >
                        {allWeatherOptions.map((t) => (

                            <MenuItem key={t} value={t}>
                                <ListItemText primary={capitalCase(t)} />
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name='road_condition'
                        onChange={(e) => handleChange(e)}
                        select
                        variant={"outlined"} style={{ width: "90%" }}
                        value={IncidentInfo.road_condition}
                        label={"Road Condition at Time of Incident"}
                    >
                        {allRoadOptions.map((t) => (

                            <MenuItem key={t} value={t}>
                                <ListItemText primary={capitalCase(t)} />
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="additional_details"
                        name="additional_details"
                        onChange={(e) => handleChange(e)}
                        value={IncidentInfo.additional_details}
                        label={"Additional Details (Disciplinary Actions, etc.)"}
                        fullWidth
                        multiline
                        inputProps={{ maxLength: 250 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography color="textSecondary">{`${IncidentInfo.additional_details.length}/250`}</Typography>
                                </InputAdornment>
                            ),
                        }}
                        rows={4}
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <TextField
                        name='video_link'
                        onChange={(e) => handleChange(e)}
                        style={{ width: "80%" }}
                        value={IncidentInfo.video_link}
                        label={"URL to Dashcam Video"}
                    />
                </Grid>
            </Grid>

        </form>

    )


}

export default IncidentGeneralInfo