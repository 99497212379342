import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { capitalCase } from 'change-case';
import { Paper, Divider, Typography } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet-async';
import DriverApplication from '../sections/@dashboard/drivers/DriverApplication';
import LoadingDisplay from './LoadingDisplay';
import ErrorPage from './ErrorPage';

function DisplayJobPost() {
    const { company, id } = useParams()
    const [postInfo, setPostInfo] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        const BASE_URL = process.env.REACT_APP_API_URL
        const url = `${BASE_URL}/jobs/${company}/${id}`
        const getPostingInfo = async () => {
            try {
                const response = await fetch(url, {
                    method: 'GET',
                });
                if (!response.ok) {
                    setError(true)
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json()
                setPostInfo(result)
                setLoading(false)
            } catch (error) {
                setError(true)
                console.error('Error Fetching Data: ', error)
            }
        }
        getPostingInfo()
    }, [])

    if (error) {
        return <p>404 Error. This page does not exist</p>
    }

    return (
        <>
            {postInfo &&
                <Helmet>
                    <title> {`${company} | ${postInfo.job_title}`} </title>
                </Helmet>
            }
            {loading ?
                <LoadingDisplay />
                :
                <Grid container spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}

                >
                    < Grid item xs={12} s={12} md={12} lg={12} xl={12} >
                        <Typography align="center" color={"textSecondary"} variant="h3" >
                            {postInfo.job_title}
                        </Typography>
                        <Typography align="center" variant="h6" color={'grey'}>{postInfo.job_location}</Typography>
                    </Grid >

                    < Grid item xs={12} s={12} md={12} lg={12} xl={12} >
                        <Typography color={"textSecondary"} variant="h4" sx={{ px: 10, py: 2 }} >
                            {`Job Description:`}
                        </Typography>
                        <Typography sx={{ px: 10 }} variant="body1" color={'grey'}>{postInfo.job_description}</Typography>

                    </Grid >
                    <Grid item xs={12} s={12} md={12} lg={12} xl={12} >
                        <Divider orientation="horizontal" sx={{ borderBottomWidth: 2, backgroundColor: "grey", marginX: "75px" }} />
                    </Grid>

                    < Grid item xs={12} s={12} md={12} lg={12} xl={12} >
                        <Typography color={"textSecondary"} variant="h4" sx={{ px: 10, py: 2 }} >
                            {`Job Requirements:`}
                        </Typography>
                        <Typography sx={{ px: 10 }} variant="body1" color={'grey'}>{postInfo.job_requirements}</Typography>

                    </Grid >
                    <Grid item xs={12} s={12} md={12} lg={12} xl={12} >
                        <Divider orientation="horizontal" sx={{ borderBottomWidth: 2, backgroundColor: "grey", marginX: "75px" }} />
                    </Grid>

                    < Grid item xs={12} s={12} md={12} lg={12} xl={12} >
                        <Typography color={"textSecondary"} variant="h4" sx={{ px: 10, py: 2 }} >
                            {`Company Benefits:`}
                        </Typography>
                        <Typography sx={{ px: 10 }} variant="body1" color={'grey'}>{postInfo.company_offer}</Typography>

                    </Grid >
                    <Grid item xs={12} s={12} md={12} lg={12} xl={12} >
                        <Divider orientation="horizontal" sx={{ borderBottomWidth: 2, backgroundColor: "grey", marginX: "75px" }} />
                    </Grid>
                    < Grid item xs={12} s={12} md={12} lg={12} xl={12} >
                        <Typography align="center" color={"textSecondary"} variant="h3" >
                            {`Join ${capitalCase(postInfo.company_name)}`}
                        </Typography>


                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Paper elevation={5} sx={{ mx: 4, my: 5, pl: 2, pr: 2, py: 2, maxWidth: '1100px' }}  >
                                <DriverApplication onClose={() => true} jobPostId={id} companyName={company} />
                            </Paper>
                        </Box>

                    </Grid>
                    <ToastContainer />

                </Grid >}
        </>

    )


}

export default DisplayJobPost