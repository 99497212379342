import { useState, useEffect } from 'react';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom'
import { getAuth } from "firebase/auth";

// components
import Iconify from '../../../components/iconify';
import { logInWithEmailAndPassword } from "../../../firebase";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate()



  const handleClick = async () => {
    setIsLoading(true)
    await logInWithEmailAndPassword(email, password)
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      navigate('/dashboard/drivers')
    } else {
      alert('Unable to sign user in')
    }
    setIsLoading(false)
  };



  return (
    <>
      {isLoading ?

        <CircularProgress size={24} />
        :
        <>

          <Stack spacing={3}>
            <TextField name="email" label="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />

            <TextField
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <Link variant="subtitle2" underline="hover" >
              Forgot password?
            </Link>
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
            Login
          </LoadingButton>
        </>
      }
    </>
  );
}
