import React, { useEffect } from 'react';
import { Typography, Grid, Checkbox, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Pdf from '../legal_paperwork/privacypolicy.pdf';

const CriteriaToHire = ({ DriverDetails, setDriverDetails }) => {
    const requirements = ['24 Years Old', '2 Years of Experience', 'Valid AZ License', 'SIN Number']

    const handleChange = (e) => {
        setDriverDetails((prevData) => ({
            ...prevData, [e.target.name]: !prevData[e.target.name]
        }))
    }

    return (
        <Grid container spacing={3} paddingX={4}>

            <Grid item xs={12} md={12}>
                <Typography variant='h5'>Requirements to Complete Application: </Typography>
                <List>
                    {requirements.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                <Checkbox icon={<CheckIcon />} />
                            </ListItemIcon>
                            <ListItemText primary={item} />
                        </ListItem>
                    ))}
                </List>

            </Grid>
            <Grid item xs={12} md={12}>

                <Checkbox
                    id="privacy_policy"
                    name="privacy_policy"
                    checked={DriverDetails.privacy_policy}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                *By checking this box you accept our <a href={Pdf} >privacy policy</a>.
                {!DriverDetails.privacy_policy && <Typography variant="body2" style={{ color: 'red' }}>
                    Required
                </Typography>}
            </Grid>
            <Grid item xs={12} md={12}>


                <Checkbox
                    id="terms_conditions"
                    name="terms_conditions"
                    checked={DriverDetails.terms_conditions}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}

                />
                *By checking this box you accept our <a href={Pdf} >terms and conditions</a>.
                {!DriverDetails.terms_conditions && <Typography variant="body2" style={{ color: 'red' }}>
                    Required
                </Typography>}
            </Grid>

        </Grid>
    );
};

export default CriteriaToHire;
