import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import { capitalCase } from 'change-case';
import Box from '@mui/material/Box';
import { FormControl, Typography } from '@mui/material';
import { AllEducationList, } from '../../../../utils/dropOptions';


function Education({ DriverEducation, setDriverEducation }) {

    const handleChange = (e) => {
        const { name, value } = e.target
        setDriverEducation({
            ...DriverEducation,
            [name]: value
        })

    };

    return (
        <form>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop={5}
                marginBottom={5}
                flexDirection="column"
                minWidth="850px"
            >
                <Typography variant='h6'>Please provide details on your education</Typography>

            </Box>

            <Grid container spacing={3} >
                <Grid item xs={12} md={8}>
                    <FormControl fullWidth >
                        <InputLabel id='type-select-label'>*What is the highest level of education you completed?</InputLabel>
                        <Select
                            labelId='type-select-label'
                            id='type-select'
                            name='highest_grade_completed'
                            onChange={handleChange}
                            value={DriverEducation.highest_grade_completed}
                            input={<OutlinedInput label='*What is the highest level of education you completed?' />}
                        >
                            {AllEducationList.map((t) => (

                                <MenuItem key={t} value={t}>
                                    <ListItemText primary={capitalCase(t)} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={8} >
                    <TextField
                        name='school_name'
                        onChange={handleChange}
                        value={DriverEducation.school_name}
                        fullWidth
                        inputProps={{ maxLength: 50 }}
                        label='*School Name'
                        variant='outlined'
                    />
                </Grid>
                <Grid item xs={12} md={8} >
                    <TextField
                        name='school_address'
                        onChange={handleChange}
                        value={DriverEducation.school_address}
                        fullWidth
                        label='*School Address (City, Prov/State)'
                        inputProps={{ maxLength: 100 }}
                        variant='outlined'
                    />
                </Grid>

                <Grid item xs={12} md={8}>
                    <TextField
                        name='other_courses'
                        onChange={handleChange}
                        value={DriverEducation.other_courses}
                        fullWidth
                        label='List other courses or training that you may have: '
                        variant='outlined'
                        multiline
                        inputProps={{ maxLength: 100 }}

                    />
                </Grid>


            </Grid>

        </form >

    )


}

export default Education