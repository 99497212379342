import React from 'react';
import { Typography, Grid, Paper, } from '@mui/material';

const DisplayGeneral = ({ DriverDetails }) => {
    return (
        <Grid item xs={12} md={12}>
            <Paper elevation={3} style={{ padding: '20px' }}>
                <Typography variant='h5'>General Info</Typography>
                <Typography component="span" variant="body1" color="textPrimary">
                    <strong>Criminal Charges:</strong> {DriverDetails.charges ? DriverDetails.charges_details : 'N/A'}
                </Typography>
                <br />
                <Typography component="span" variant="body1" color="textPrimary">
                    <strong>Misdemeanours:</strong> {DriverDetails.misdemeanours ? DriverDetails.misdemeanours_detail : 'N/A'}
                </Typography>
                <br />
                <Typography component="span" variant="body1" color="textPrimary">
                    <strong>Fast Num:</strong> {DriverDetails.fast ? DriverDetails.fast_num : 'N/A'}
                </Typography>
                <br />
                <Typography component="span" variant="body1" color="textPrimary">
                    <strong>Able to Enter USA:</strong> {DriverDetails.enter_usa ? 'Yes' : 'No'}
                </Typography>
                <br />
                <Typography component="span" variant="body1" color="textPrimary">
                    <strong>Citizenship:</strong> {DriverDetails.citizenship}
                </Typography>
                <br />
                <Typography component="span" variant="body1" color="textPrimary">
                    <strong>Driver Referral:</strong> {DriverDetails.driver_referral || 'N/A'}
                </Typography>
                <br />

            </Paper>
        </Grid>
    );
};

export default DisplayGeneral;
