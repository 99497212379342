import React from 'react';
import { List, ListItem, ListItemText, Paper, Typography, Grid } from '@mui/material';
import ViolationDetails from './ViolationDetails'; // Import the ViolationDetails component

const DisplayViolationList = ({ violations }) => {
    // use params
    return (
        <Grid item xs={6} md={6}>
            <Paper elevation={3} style={{ padding: '20px' }}>
                <Typography variant='h5'>Previous Violations</Typography>
                <List>
                    {violations.map((violation, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={<ViolationDetails violation={violation} />} // Use the ViolationDetails component
                            />
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </Grid>
    );
};

export default DisplayViolationList;
