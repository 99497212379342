import { useState, useEffect } from "react";
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { createTheme } from '@mui/material/styles';
// @mui
import {
    Stack,
    Button,
    Container,
    Typography,
    Grid,
    TableCell,
    DialogContent
} from '@mui/material';
import { capitalCase } from "change-case";
import ClientForm from "./ClientForm";
import Label from "../../../components/label";
import LoadingDisplay from "../../../pages/LoadingDisplay";
import { getRequest } from "../../../shared/apiCalls";
import CompanyDetailsPage from "./CompanyDetailsPage";

function ManageClients() {

    const [allClients, setAllClients] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getAllClients = async () => {
            try {
                const response = await getRequest(`company`)
                const result = await response.json()
                setAllClients(result.data)
                setLoading(false)
            } catch (error) {
                console.error('Error:', error);
                alert('An Error Occured! Please try again!')
            }

        }

        getAllClients()
    }, [])
    const theme = createTheme({
        overrides: {
            MUIDataTableSelectCell: {
                expandDisabled: {
                    // Soft hide the button.
                    visibility: 'hidden',
                },
            },
        },
    });
    const components = {
        ExpandButton: (props) => {
            return <ExpandButton {...props} />;
        },
    };
    const [expandedRows, setExpandedRows] = useState([]);


    const columns = [
        {
            name: 'id',
            label: 'ID',
        },
        {
            name: 'company_name',
            label: 'Company Name',
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const status = allClients[dataIndex].status
                    if (status) {
                        return (
                            <Label color={(status === 'inactive' && 'error') || 'primary'}>
                                {capitalCase(status)}
                            </Label>
                        )
                    }
                    return (
                        <div>-</div>
                    )

                },

            }

        },
        {
            name: 'start_date',
            label: 'Start Date',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const d = allClients[dataIndex].start_date
                    return (
                        <>
                            {d.toString().slice(0, 10)}
                        </>
                    )
                },
            }
        },

        {
            name: 'company_email',
            label: 'Email',
        },
        {
            name: 'monthly_rev',
            label: 'Monthly Revenue',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const d = allClients[dataIndex].monthly_rev
                    const formattedDollarAmount = Number(d).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    });
                    return (
                        <>
                            {formattedDollarAmount}
                        </>
                    )
                },
            }
        },
        {
            name: 'num_drivers',
            label: 'Number of Drivers',
        },
        {
            name: 'Edit',
            label: 'Edit',
            options: {
                customBodyRenderLite: (dataIndex) => {

                    return (
                        <Button onClick={() => alert('Coming Soon')}>Edit</Button>

                    )
                },

            }

        },

    ];

    const options = {
        filter: true,
        filterType: "dropdown",
        selectableRows: "none",
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        rowsExpanded: expandedRows,
        responsive: "standard",
        rowsPerPage: 10,
        tableBodyMaxHeight: '600',
        renderExpandableRow: (rowData, rowMeta) => {
            const colSpan = rowData.length + 1;
            const rowIndex = rowMeta.dataIndex
            return (
                // 
                //     <ClientForm id={rowData[0]} updateExisitng={true} />
                // 
                <TableCell colSpan={colSpan}>
                    <CompanyDetailsPage data={allClients[rowIndex]} />
                </TableCell>
            )
        },
        sortOrder: {
            name: 'date',
            direction: 'desc'
        },
        onRowsDelete: () => {
            console.log('Delete')
            return false
        },

    }



    return (
        <>

            {loading ?
                <LoadingDisplay /> :
                <Container>
                    <Stack direction="column" justifyContent="space-between" mb={5}>
                        <Typography
                            sx={{ mb: 3 }}
                            variant="h4"
                        >
                            All Clients
                        </Typography>
                        <MUIDataTable

                            columns={columns}
                            data={allClients}
                            options={options}
                            components={components}

                        />
                    </Stack>

                </Container>

            }

        </>
    )
}

export default ManageClients