import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';

import ClientForm from '../sections/@dashboard/admin/ClientForm'
import ManageClients from '../sections/@dashboard/admin/ManageClients';

export default function AdminPage() {
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1, 1);

    // Make get call here

    return (
        <>
            <Helmet>
                <title>
                    TFS | Admin
                </title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 4
                }}
            >
                <ClientForm id={-1} updateExisitng={false} />
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 4
                }}
            >
                <ManageClients />
            </Box>
        </>
    )
};