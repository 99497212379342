import { useEffect, useState } from 'react';
import {
    Container, FormGroup, Typography, List, ListItem, FormControlLabel, Box,
    Button, Card, CardContent, CardHeader, Divider, Grid, TextField, InputAdornment,
    IconButton
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { capitalCase } from 'change-case';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';
import HireSteps from './HireSteps';
import { companyData } from './initValues';
import Iconify from '../../../components/iconify/Iconify';
import { postRequest, putRequest } from '../../../shared/apiCalls';
import { AllServices, AllClientStatus } from '../../../utils/dropOptions';

function arrayToDictionary(arr) {
    const dictionary = {};

    arr.forEach((value, index) => {
        dictionary[index] = value;
    });

    return dictionary;
}


export default function ClientForm({ data, updateExisitng = false }) {
    const [values, setValues] = useState(companyData);

    useEffect(() => {
        if (data) {
            setValues({
                ...data,
                'company_number': data.company_number.slice(2),
                'start_date': new Date(data.start_date)
            })
        }
    }, []);

    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1, 1);

    const [showPassword, setShowPassword] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(false)


    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'company_number' && value.match(/[^0-9]/)) {
        } else {
            setValues({
                ...values,
                [name]: value
            });
        }
    };

    const handleServicesChange = (item) => {
        const updatedservices = {
            ...values.services,
            [item]: !values.services[item]
        }
        setValues({
            ...values,
            'services': updatedservices
        });
    }

    const handleDateChange = (name, e, index) => {
        setValues(values => {
            return { ...values, [name]: e }
        });
    };

    const handleSubmit = async (e) => {
        const phoneNumberRegex = /^\d{10}$/;
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        const cleanedCompanyName = values.company_name.trim()
        const data = {
            company_name: cleanedCompanyName.replace(/\s+/g, '-'),
            company_email: values.company_email.trim(),
            company_number: `+1${values.company_number}`,
            company_address: values.company_address.trim(),
            password: values.password.trim(),
            monthly_rev: values.monthly_rev.trim(),
            hst_number: values.hst_number.trim(),
            start_date: values.start_date.toISOString(), // Convert date to string
            services: values.services,
            num_drivers: values.num_drivers,
            status: values.status,
            hiring_steps: arrayToDictionary(values.hiring_steps),
            policy_period: 0,
        };

        if (values.password !== values.confirm_password && updateExisitng === false
            && values.password.trim() !== '') {
            alert('Passwords Do Not Match!')
        } else if (!emailRegex.test(values.company_email) && updateExisitng === false) {
            alert('Enter valid email')
        } else if (values.company_number.length < 10 || !phoneNumberRegex.test(values.company_number)) {
            alert('Provide Valid Phone Number. (i.e. 1234567890)')
        } else if (values.hiring_steps === 0) {
            alert('Enter Steps')
        } else if (updateExisitng === true) {
            // updating existing
            setSaveDisabled(true)
            try {
                const response = await toast.promise(putRequest('company', data),
                    {
                        pending: 'Updating Client...',
                        success: 'Client Updated',
                        error: 'Error Updating Client'
                    })
                setValues(companyData)
            } catch (error) {
                console.error('Error:', error);
            }
            setSaveDisabled(false)

        } else {
            // Construct the data to be sent in the request body
            // setSaveDisabled(true)
            try {
                const response = await toast.promise(postRequest('company', data),
                    {
                        pending: 'Adding Client...',
                        success: 'Client Added',
                        error: 'Error Adding Client'
                    })
                setValues(companyData)
            } catch (error) {
                console.error(error.message);
            }
            // setSaveDisabled(false)
        }
    }

    return (
        <Container maxWidth="lg">

            <form
                autoComplete="off"
                noValidate
            >
                {updateExisitng ?
                    <></> :
                    <Typography
                        sx={{ mb: 3, mt: 3 }}
                        variant="h4"
                    >
                        Add New Client
                    </Typography>
                } <Card>
                    <CardHeader
                        title={updateExisitng ? "Update Profie"
                            : "New Profile"}
                    />
                    <CardContent>

                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                item
                                md={8}
                                xs={12}
                            >

                                <TextField
                                    name='status'
                                    onChange={(e) => handleChange(e)}
                                    style={{ width: '75%' }}
                                    select
                                    variant={'outlined'}
                                    value={values.status}
                                    label={'Status'}
                                >
                                    {AllClientStatus.map((t) => (

                                        <MenuItem key={t} value={t}>
                                            <ListItemText primary={capitalCase(t)} />
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid
                                item
                                md={8}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Company Name"
                                    name="company_name"
                                    value={values.company_name}
                                    required
                                    variant="outlined"
                                    style={{ width: "75%" }}
                                    onChange={(e) => handleChange(e)}
                                    inputProps={{ maxLength: 50 }}

                                />

                            </Grid>

                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    helperText="This will be used by the company to login"
                                    label="Company Email"
                                    name="company_email"
                                    value={values.company_email}
                                    required
                                    variant="outlined"
                                    inputProps={{ maxLength: 100 }}
                                    onChange={(e) => handleChange(e)} />
                            </Grid>
                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    helperText="Must be able to receive text message"
                                    label="Company Phone"
                                    name="company_number"
                                    value={values.company_number}
                                    required
                                    variant="outlined"
                                    onChange={(e) => handleChange(e)} />
                            </Grid>
                            {updateExisitng ?
                                <></> :
                                <>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            name="password"
                                            label="Password"
                                            type={showPassword ? 'text' : 'password'}
                                            value={values.password}
                                            inputProps={{ maxLength: 50 }}
                                            onChange={(e) => handleChange(e)}
                                            style={{ width: "75%" }}

                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />

                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        xs={12}
                                    >
                                        <TextField
                                            name="confirm_password"
                                            label="Confirm Password"
                                            type={showPassword ? 'text' : 'password'}
                                            value={values.confirm_password}
                                            onChange={(e) => handleChange(e)}
                                            style={{ width: "75%" }}
                                            inputProps={{ maxLength: 50 }}


                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12} md={8}  >

                                <TextField
                                    fullWidth
                                    label="Company Address"
                                    name="company_address"
                                    value={values.company_address}
                                    required
                                    variant="outlined"
                                    onChange={(e) => handleChange(e)}
                                    inputProps={{ maxLength: 50 }}

                                />
                            </Grid>


                            <Grid
                                item
                                md={6}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Total Revenue/Month"
                                    name="monthly_rev"
                                    value={values.monthly_rev}
                                    required
                                    variant="outlined"
                                    style={{ width: "75%" }}

                                    onChange={(e) => handleChange(e)} />
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        required
                                        label="Start Date"
                                        name="start_date"
                                        value={values.start_date}
                                        onChange={(e) => handleDateChange('start_date', e)}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                md={8}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="HST Number"
                                    name="hst_number"
                                    value={values.hst_number}
                                    required
                                    variant="outlined"
                                    style={{ width: "56%" }}

                                    onChange={(e) => handleChange(e)} />
                            </Grid>
                            <Grid
                                item
                                md={8}
                                xs={12}
                            >
                                <TextField
                                    fullWidth
                                    label="Expected Number of Drivers "
                                    name="num_drivers"
                                    value={values.num_drivers}
                                    required
                                    style={{ width: "56%" }}
                                    variant="outlined"
                                    onChange={(e) => handleChange(e)} />
                            </Grid>
                            <Grid item md={12}>
                                <Typography variant="h6">Services Included:</Typography>

                                <FormGroup>
                                    <List>
                                        {AllServices.map((item, index) => (
                                            <ListItem key={index}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={values.services[item]}
                                                            onChange={() => handleServicesChange(item)}
                                                        />

                                                    }
                                                    label={<ListItemText primary={capitalCase(item)} />}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </FormGroup>
                            </Grid>
                            <Grid item md={6}>
                                <Typography variant="h6">Hiring Steps:</Typography>

                                <HireSteps values={values} setValues={setValues} />
                            </Grid>

                        </Grid>

                        <br />
                    </CardContent>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2
                        }}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => handleSubmit()}
                            disabled={saveDisabled}
                        >
                            {updateExisitng ?
                                'Update Client'
                                :
                                'Add New Client'}
                        </Button>
                    </Box>
                </Card>
            </form>
        </Container>

    )
};
