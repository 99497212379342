import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import Grid from '@mui/material/Grid';
import EditPosting from '../sections/@dashboard/posting/singlePost/EditPosting';
import LoadingDisplay from './LoadingDisplay';
import { getRequest } from '../shared/apiCalls'



function SinglePostPage() {
    const { id } = useParams()
    const [post, setPost] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getPostingInfo = async () => {
            try {
                const response = await getRequest(`jobpostings/${id}`)
                const result = await response.json()
                setPost({
                    ...result.data,
                    'posting_start_date': new Date(result.data.posting_start_date),
                    'posting_end_date': new Date(result.data.posting_end_date)
                })
                setLoading(false)
            } catch (error) {
                console.error('Error:', error);
                alert('An Error Occured! Please try again!')
            }

        }
        getPostingInfo()
    }, [])

    return (
        <>
            <Helmet>
                <title> TFS | Job Post </title>
            </Helmet>

            {
                loading ?
                    <LoadingDisplay />
                    :
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} s={12} md={12} lg={12} xl={12} >
                            <EditPosting post={post} setPost={setPost} />
                        </Grid>
                        <Grid item xs={12} s={12} md={12} lg={12} xl={12} >
                            {/* <ApplicantManager id={post.id} /> */}
                        </Grid>
                    </Grid>}
        </>
    );
}

export default SinglePostPage;