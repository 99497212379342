import { useState } from 'react';
import { InputAdornment, Typography } from '@mui/material'
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { postRequest } from '../../../shared/apiCalls';

/* function to add new task to firestore */
function AddPosting({ onClose, open, }) {
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1, 1);
    const [post, setPost] = useState({

        'company_id': localStorage.getItem('company_id'),
        'company_name': localStorage.getItem('company_name'),
        'company_offer': '',
        'job_description': '',
        'job_location': '',
        'job_requirements': '',
        'job_title': '',
        'last_update': new Date(),
        'posting_end_date': endDate,
        'posting_start_date': new Date(),
    })


    const handleDateChange = (name, e) => {

        if (e == null) {
            alert("Please Select Valid Date")
            setPost(PostingInfo => {
                return { ...PostingInfo, [name]: new Date() }
            });
        } else {
            setPost(PostingInfo => {
                return { ...PostingInfo, [name]: e }
            });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setPost(post => {
            return {
                ...post,
                [name]: value
            }
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const today = new Date()
            // Check if any required field is empty or undefined
            if (
                !post.company_id ||
                !post.company_offer.trim() ||
                !post.job_description.trim() ||
                !post.job_location.trim() ||
                !post.job_requirements.trim() ||
                !post.job_title.trim() ||
                !post.posting_end_date ||
                !post.posting_start_date
            ) {
                alert('Error: All fields are required');
            } else {
                const data = {
                    company_id: post.company_id,
                    company_offer: post.company_offer.trim(),
                    job_description: post.job_description.trim(),
                    job_location: post.job_location.trim(),
                    job_requirements: post.job_requirements.trim(),
                    job_title: post.job_title.trim(),
                    last_update: today.toISOString(),
                    posting_end_date: post.posting_end_date.toISOString(),
                    posting_start_date: post.posting_start_date.toISOString(),
                }
                await toast.promise(postRequest('jobpostings', data),
                    {
                        pending: 'Adding Job Posting...',
                        success: 'New Job Posting Created',
                        error: 'Error Creating Job Posting'
                    })
                onClose()
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    return (
        <Dialog
            onClose={onClose} open={open}
            aria-labelledby="form-dialog"
            maxWidth='md'
        >

            <DialogTitle id="form-dialog">Add New Job Posting</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit}>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <TextField
                                required
                                name='job_title'
                                onChange={(e) => handleChange(e)}
                                value={post.job_title}
                                fullWidth
                                label="Job Title"
                                variant="outlined"
                                inputProps={{ maxLength: 50 }} // Example: set max length to 50 characters

                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <TextField
                                required
                                name='job_location'
                                onChange={(e) => handleChange(e)}
                                value={post.job_location}
                                fullWidth
                                label="Job Location"
                                autoComplete="address-level2"
                                variant="outlined"
                                inputProps={{ maxLength: 50 }} // Example: set max length to 50 characters

                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    required
                                    label="Start Date"
                                    minDate={new Date()}
                                    value={post.posting_start_date}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                        },
                                    }}
                                    onChange={(e) => handleDateChange('posting_start_date', e)}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    required
                                    label="End Date"
                                    minDate={post.posting_start_date}
                                    value={post.posting_end_date}
                                    onChange={(e) => handleDateChange('posting_end_date', e)}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                required
                                name={"job_description"}
                                onChange={(e) => handleChange(e)}
                                value={post.job_description}
                                label='Give a brief description of this job.'
                                fullWidth
                                variant="outlined"
                                minRows={3}
                                maxRows={3}
                                multiline
                                inputProps={{ maxLength: 250 }} // Example: set max length to 250 characters
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Typography color="textSecondary">{`${post.job_description.length}/250`}</Typography>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                required
                                name={"job_requirements"}
                                onChange={(e) => handleChange(e)}
                                value={post.job_requirements}
                                label='What are some of the requirements for this job?'
                                fullWidth
                                variant="outlined"
                                minRows={3}
                                maxRows={3}
                                multiline
                                inputProps={{ maxLength: 250 }} // Example: set max length to 250 characters
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Typography color="textSecondary">{`${post.job_requirements.length}/250`}</Typography>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                            <TextField
                                required
                                name={"company_offer"}
                                onChange={(e) => handleChange(e)}
                                value={post.company_offer}
                                label='List some benefits for working for your company.'
                                fullWidth
                                variant="outlined"
                                minRows={3}
                                maxRows={3}
                                multiline
                                inputProps={{ maxLength: 250 }} // Example: set max length to 250 characters
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Typography color="textSecondary">{`${post.company_offer.length}/250`}</Typography>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>


                    <Box mt={6}>
                        <Button variant="contained" fullWidth
                            pt={10} type='submit' onClick={handleSubmit}>Create Posting</Button>
                    </Box>
                </form>
            </DialogContent>
        </Dialog>
    )


}

export default AddPosting