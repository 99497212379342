import { faker } from '@faker-js/faker';
import { sample } from 'lodash';
import { allRegion, allRoadOptions, allIncidentType, allIncidentStatus, allWeatherOptions, MexicoStates } from '../utils/dropOptions';

// ----------------------------------------------------------------------
const incidents = [...Array(100)].map((_, index) => ({
    abstract: 'example abstract from driver',
    additional_details: faker.random.words(faker.datatype.number(25)),
    bodily_injuries: faker.datatype.number({ min: 0, max: 4 }),
    cargo_damage: faker.datatype.number({ min: 0, max: 100000 }),
    claim_number: faker.datatype.number({ min: 0, max: 47 }),
    cvor: 'example cvor from driver',
    date: faker.date.between('2022-01-01T00:00:00.000Z', '2023-05-01T00:00:00.000Z'),
    driver_id: faker.datatype.number({ min: 0, max: 47 }),
    company_id: faker.datatype.number({ min: 0, max: 3 }),
    fatalities: faker.datatype.number({ min: 0, max: 4 }),
    fault_driver: faker.datatype.boolean(),
    filing_claim: faker.datatype.boolean(),
    id: index,
    incident_type: sample(allIncidentType),
    last_truck_maintenance: faker.date.between('2022-01-01T00:00:00.000Z', '2023-05-01T00:00:00.000Z'),
    license: 'example license from driver',
    location: {
        'city': faker.address.city(),
        'country': "Mexico",
        'postal_zip': faker.address.zipCode(),
        'provinceState': sample(MexicoStates),
        'street': faker.random.words(2),
    },
    photo_1: {
        'name': 'File 1',
        'url': 'https://student.cs.uwaterloo.ca/~cs240/f22/modules/module02.pdf'
    },
    photo_2: {
        'name': 'File 2',
        'url': 'https://student.cs.uwaterloo.ca/~cs240/f22/modules/module02.pdf'
    },
    photo_3: {
        'name': 'File 3',
        'url': 'https://student.cs.uwaterloo.ca/~cs240/f22/modules/module02.pdf'
    },
    photo_4: {
        'name': 'File 4',
        'url': 'https://student.cs.uwaterloo.ca/~cs240/f22/modules/module02.pdf'
    },
    photo_5: {
        'name': 'File 5',
        'url': 'https://student.cs.uwaterloo.ca/~cs240/f22/modules/module02.pdf'
    },

    police_report: {
        'name': 'File for Police',
        'url': 'https://student.cs.uwaterloo.ca/~cs240/f22/modules/module02.pdf'
    },
    release_form: {
        'name': 'File for Release',
        'url': 'https://student.cs.uwaterloo.ca/~cs240/f22/modules/module02.pdf'
    },
    road_condition: sample(allRoadOptions),
    signed_driver_statement: {
        'name': 'File for Driver',
        'url': 'https://student.cs.uwaterloo.ca/~cs240/f22/modules/module02.pdf'
    },
    status: sample(allIncidentStatus),
    trailer_damage: faker.datatype.number({ min: 0, max: 100000 }),
    truck_damage: faker.datatype.number({ min: 0, max: 100000 }),
    video_link: 'https://www.youtube.com/watch?v=y3nVx05W4xQ',
    weather_condition: sample(allWeatherOptions),
}));


export default incidents; 
