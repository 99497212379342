import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
// @mui
import {
    Grid,
    Button,
    Container,
} from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import { capitalCase } from "change-case";
import DisplayIncidentInfo from "../sections/@dashboard/incidents/DisplayIncidentInfo"
import Label from '../components/label';
import LoadingDisplay from "./LoadingDisplay";
import ErrorPage from "./ErrorPage"
import { getRequest } from "../shared/apiCalls";

function IncidentManager() {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [allIncidents, setAllIncidents] = useState([])

    const navigate = useNavigate();
    const theme = createTheme({
        overrides: {
            MUIDataTableSelectCell: {
                expandDisabled: {
                    // Soft hide the button.
                    visibility: 'hidden',
                },
            },
        },
    });
    const components = {
        ExpandButton: (props) => {
            return <ExpandButton {...props} />;
        },
    };

    useEffect(() => {
        const getAllIncidents = async () => {
            try {
                const response = await getRequest('incidents')
                const result = await response.json()
                setAllIncidents(result.data)
                setLoading(false)
                setError(false)
            } catch (error) {
                setError(true)
            }
        }
        getAllIncidents()
    }, [])

    const personalPage = (id) => {
        navigate(
            {
                pathname: `/dashboard/drivers/${id}`,
            }
        );
    }

    const columns = [
        {
            name: 'id',
            label: 'ID',

        },
        {
            name: 'status',
            label: 'Status',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const status = allIncidents[dataIndex].status
                    return (
                        <Label color={(status === 'settled' && 'error') || (status === 'open' && 'warning')
                            || (status === 'closed' && 'default') || 'primary'}>
                            {capitalCase(status)}
                        </Label>
                    )
                },

            }

        },

        {
            name: 'date',
            label: 'Date',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const d = new Date(allIncidents[dataIndex].date)
                    return (
                        <>
                            {d.toString().slice(0, 25)}
                        </>
                    )
                },
            }
        },

        {
            name: 'driver_id',
            label: 'Driver Id',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const d = allIncidents[dataIndex].driver_id
                    return (
                        <Button variant='contained' color="inherit"
                            size="small"
                            onClick={() => personalPage(d)}>
                            {d}
                        </Button>
                    )
                },
            }

        },
        {
            name: 'location',
            label: 'Location',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const location = allIncidents[dataIndex].location
                    const provinceState = location.province_state.substring(0, location.province_state.indexOf('-') - 1)
                    return (
                        `${location.city}, ${provinceState}, ${location.country}`
                    )
                },

            }
        },
        {
            name: 'incident_type',
            label: 'Type',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const incidentType = allIncidents[dataIndex].incident_type
                    return (
                        <Label color={(incidentType === 'hit_third_party' && 'error') || (incidentType === 'pedestrian_involved' && 'error')
                            || (incidentType === 'low_bridge' && 'warning') || (incidentType === 'rollover' && 'warning') || 'primary'}>
                            {/* primary or default color */}
                            {capitalCase(incidentType)}
                        </Label>
                    )
                },

            }

        },
        {
            name: 'filing_claim',
            label: 'Claim Filed',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const resp = allIncidents[dataIndex].filing_claim
                    if (resp === false) {
                        return (

                            <CancelIcon color="error" />
                        )
                    }
                    return (
                        <CheckBoxIcon color="success" />
                    )


                },
            }
        },
        {
            name: 'fault_driver',
            label: 'Driver Fault',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const resp = allIncidents[dataIndex].fault_driver
                    if (resp === false) {
                        return (
                            <CancelIcon color="error" />
                        )
                    }
                    return (
                        <CheckBoxIcon color="success" />
                    )

                },
            }
        },

    ];

    const options = {
        filter: true,
        filterType: "dropdown",
        selectableRows: "none",
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        rowsExpanded: [],
        renderExpandableRow: (rowData, rowMeta) => {
            const incidentData = allIncidents.filter((incident) => incident.id === rowData[0])
            return (
                <DisplayIncidentInfo incident={incidentData[0]} />
            )
        },

        responsive: "standard",
        rowsPerPage: 10,
        tableBodyMaxHeight: '600',
        sortOrder: {
            name: 'date',
            direction: 'desc'
        },
        onRowsDelete: () => {
            console.log('Delete')
            return false
        },

    }

    if (loading) {
        return <LoadingDisplay />
    }

    if (error) {
        return <ErrorPage />
    }

    return (
        <>
            <Helmet>
                <title> TFS | Incidents </title>
            </Helmet>

            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={12} >
                        <ThemeProvider theme={theme}>
                            <MUIDataTable
                                title={"Incidents"}
                                columns={columns}
                                data={allIncidents}
                                options={options}
                                components={components}

                            />
                        </ThemeProvider>
                    </Grid>


                </Grid>


            </Container>

        </>
    )
}

export default IncidentManager