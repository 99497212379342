import { Helmet } from 'react-helmet-async';
// @mui

import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ErrorPage() {

    return (
        <>
            <Container>
                <StyledContent sx={{ textAlign: 'center' }}>
                    <Typography variant="h4" paragraph>
                        An error occured. Please refresh page or contact admin.
                    </Typography>


                </StyledContent>
            </Container>
        </>
    );
}
