import React, { useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import { Button, Typography, Paper, TextField, Box, Checkbox, List } from '@mui/material';
import {
    countries, CanadaProvinces, USAStates, MexicoStates
} from '../../../../utils/dropOptions';

function SignConsentForms({ DriverPdfData, setDriverPdfData, DriverExperience, setDriverExperience }) {

    const handlePDFDataChange = (e) => {
        const { name, value } = e.target;
        setDriverPdfData({
            ...DriverPdfData,

            [name]: value,

        });
    }

    const handleVerifyChange = (e, index) => {
        const { name, value } = e.target;
        const updatedExperience = [...DriverExperience]
        if (name === 'operate_commercial' || name === 'auth_to_contact') {
            updatedExperience[index] = {
                ...updatedExperience[index],
                'auth_to_contact': !updatedExperience[index]['auth_to_contact']
            }

            setDriverExperience(updatedExperience)
        }
    }

    const handleDateChange = (type, e) => {
        if (e == null) {
            alert('Please Select Valid Date')
        } else if (type === 'expiry') {
            setDriverPdfData({
                ...DriverPdfData,
                'license': {
                    ...DriverPdfData.license,
                    [type]: e,
                },
            });
        } else {
            setDriverPdfData({
                ...DriverPdfData,
                [type]: e,
            });

        }
    }

    const handleLicenseDataChange = (e) => {
        const { name, value } = e.target
        if (name === 'country') {
            let provinceState = ''
            if (value === "Canada") {
                provinceState = 'ON - Ontario'
            } else if (value === "U.S.A") {
                provinceState = 'NY - New York'
            } else {
                provinceState = 'SIN - Sinaloa'
            }
            setDriverPdfData({
                ...DriverPdfData,
                'license': {
                    ...DriverPdfData.license,
                    'country': value,
                    'province_state': provinceState
                },
            });
        } else {
            setDriverPdfData({
                ...DriverPdfData,
                'license': {
                    ...DriverPdfData.license,
                    [name]: value
                },
            });
        }

    }


    const signatureCanvas = useRef(null);

    const clearSignature = () => {
        signatureCanvas.current.clear();
        setDriverPdfData({
            ...DriverPdfData,
            'signature_data': '',

        });
    };

    const saveSignature = () => {
        const signatureData = signatureCanvas.current.toDataURL();
        setDriverPdfData({
            ...DriverPdfData,
            'signature_data': signatureData,

        });
    };

    return (
        <form>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop={5}
                marginBottom={3}
                minWidth="800px"
            >

                <Typography variant='h6'>Complete form below to sign consent forms and create profile: </Typography>

            </Box>
            <Grid container spacing={3} paddingX={5} mt={1}>
                {/* // spacing={3}
                // direction='column'
                // minWidth='800px'
                // style={{ paddingX: 4 }} */}


                <Grid item xs={12} md={8}>
                    <Typography marginBottom={1} variant='subtitle2'>*Enter your name in following format (First Name, MI, Last Name)</Typography>
                    <TextField
                        required
                        name='name'
                        onChange={handlePDFDataChange}
                        value={DriverPdfData.name}
                        style={{ width: '300px' }} // Set the width to 300px
                        label='First Name, MI, Last Name'
                        variant='outlined'
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography marginBottom={1} variant='subtitle2'>*Enter your SIN (9 digits)</Typography>
                    <TextField
                        label='S.I.N.'
                        variant="outlined"
                        name="sin"
                        type="number"
                        value={DriverPdfData.sin}
                        onChange={handlePDFDataChange}
                        fullWidth
                        style={{ maxWidth: '300px' }}
                    />
                </Grid>



                <Grid item xs={12} md={8}>
                    <Typography marginBottom={1} variant='subtitle2'>*Enter information on you commericial license below</Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            required
                            name='expiry'
                            label={'Expiry Date'}
                            value={DriverPdfData.license.expiry}
                            onChange={(e) => handleDateChange('expiry', e)}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6}  >

                    <TextField
                        name='country'
                        onChange={handleLicenseDataChange}
                        style={{ width: '100%' }}
                        select
                        variant={'outlined'}
                        value={DriverPdfData.license.country}
                        label={'Issuing Country'}
                    >
                        {countries.map((t) => (

                            <MenuItem key={t} value={t}>
                                <ListItemText primary={t} />
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6}  >
                    <TextField
                        required
                        name='province_state'
                        select
                        onChange={handleLicenseDataChange}
                        style={{ width: '100%' }}
                        variant={'outlined'}
                        value={DriverPdfData.license.province_state}
                        label={'Issuing Province/State'}
                    >
                        {(DriverPdfData.license.country === 'Canada') ?
                            CanadaProvinces.map((t) => (
                                <MenuItem key={t} value={t}>
                                    <ListItemText primary={t} />
                                </MenuItem>
                            )) :
                            (DriverPdfData.license.country === 'U.S.A') ?
                                USAStates.map((t) => (
                                    <MenuItem key={t} value={t}>
                                        <ListItemText primary={t} />
                                    </MenuItem>
                                )) :
                                MexicoStates.map((t) => (
                                    <MenuItem key={t} value={t}>
                                        <ListItemText primary={t} />
                                    </MenuItem>
                                ))

                        }

                    </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                    <TextField
                        required
                        name='num'
                        label='License #'
                        inputProps={{ maxLength: 50 }}
                        onChange={handleLicenseDataChange}
                        value={DriverPdfData.license.num}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography marginBottom={1} variant='subtitle2'>Please confirm we can reach out to below employers to verify experience:</Typography>
                    <List>
                        {DriverExperience.map((exp, index) => {
                            return (<ListItem key={index}>
                                <ListItemIcon>
                                    <Checkbox
                                        name="auth_to_contact"
                                        checked={exp.auth_to_contact}
                                        onChange={(e) => handleVerifyChange(e, index)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={exp.company_name} />
                            </ListItem>)
                        })}

                    </List>

                </Grid>
            </Grid>


            <Grid container
                justifyContent="center"   // Horizontally center the items
                alignItems="center" // Vertically center the items
                spacing={3}
                direction='column'
                minWidth='800px'
                style={{ paddingX: 4 }}>
                <Grid item>
                    <Paper style={{
                        paddingInline: '70px',
                        marginInline: '100px',
                        backgroundColor: '#D3D3D3',
                        border: '0.5px solid #000',
                        marginTop: '20px'
                    }}>
                        <Typography>
                            By signing my application below, I agree to use an electronic signature to
                            demonstrate my consent. An electronic signature is as legally binding as an
                            ink signature. This certifies that this application was completed by me,
                            and that all entries on it and information in it are true and complete to
                            the best of my knowledge.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle">Please use your finger or mouse to sign your name in the rectangle below. Click "Save" when finished.</Typography>

                </Grid>
                <Grid item >

                    <SignatureCanvas
                        ref={signatureCanvas}
                        penColor='black'
                        canvasProps={{ width: 500, height: 100, style: { border: '1px solid #000' } }}
                    />
                </Grid>
                <Grid item >

                    <Button variant='contained'
                        style={{ backgroundColor: 'grey', color: 'white', borderRadius: '10px', boxShadow: 'none', marginRight: '10px' }}
                        onClick={clearSignature}>Clear</Button>
                    <Button variant='contained'
                        color='primary'
                        style={{ borderRadius: '10px', boxShadow: 'none' }}
                        onClick={saveSignature}>Save</Button>

                </Grid>


            </Grid>



        </form >

    )


}

export default SignConsentForms