import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { isPostalCodeValid } from './dataValidation';
import {
    countries, CanadaProvinces, USAStates, MexicoStates
} from '../utils/dropOptions';/* function to add new task to firestore */

function AddressDetails
    ({ Address, handleChange }) {


    return (
        <>

            <Grid item xs={12} md={3}  >
                <TextField
                    name='country'
                    onChange={(e) => handleChange(e)}
                    style={{ width: '100%' }}
                    select
                    variant={'outlined'}
                    value={Address.country}
                    label={'Country'}
                >
                    {countries.map((t) => (

                        <MenuItem key={t} value={t}>
                            <ListItemText primary={t} />
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} md={9}  >
                <TextField
                    required
                    name='street'
                    onChange={(e) => handleChange(e)}
                    style={{ width: '100%' }}
                    variant={'outlined'}
                    value={Address.street}
                    inputProps={{ maxLength: 50 }} // Example: set max length to 250 characters
                    label='Street address'
                />
            </Grid>
            <Grid item xs={12} md={4}  >
                <TextField
                    required
                    name='city'
                    onChange={(e) => handleChange(e)}
                    style={{ width: '100%' }}
                    variant={'outlined'}
                    value={Address.city}
                    inputProps={{ maxLength: 50 }} // Example: set max length to 250 characters

                    label='City'
                />
            </Grid>
            <Grid item xs={12} md={4}  >
                <TextField
                    required
                    name='province_state'
                    select
                    onChange={(e) => handleChange(e)}
                    style={{ width: '100%' }}
                    variant={'outlined'}
                    value={Address.province_state}
                    label={'Province/State'}
                >
                    {(Address.country === 'Canada') ?
                        CanadaProvinces.map((t) => (
                            <MenuItem key={t} value={t}>
                                <ListItemText primary={t} />
                            </MenuItem>
                        )) :
                        (Address.country === 'U.S.A') ?
                            USAStates.map((t) => (
                                <MenuItem key={t} value={t}>
                                    <ListItemText primary={t} />
                                </MenuItem>
                            )) :
                            MexicoStates.map((t) => (
                                <MenuItem key={t} value={t}>
                                    <ListItemText primary={t} />
                                </MenuItem>
                            ))

                    }

                </TextField>
            </Grid>
            <Grid item xs={12} md={4}  >
                <TextField
                    required
                    name='postal_zip'
                    onChange={(e) => handleChange(e)}
                    style={{ width: '100%' }}
                    variant={'outlined'}
                    value={Address.postal_zip}
                    inputProps={{ maxLength: 7 }} // Limit input to 9 characters
                    label='Postal / Zip Code'
                />
            </Grid>

        </>

    )


}

export default AddressDetails
