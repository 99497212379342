import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import { IconButton, ListItem, Typography } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';

// This is basic form used to Edit and Add Incident Files
function AddIncidentFiles({ IncidentFiles, setIncidentFiles, setAllowNext }) {
    const numPhotos = [1, 2, 3, 4, 5]
    const fileList = ['photo_1', 'photo_2', 'photo_3', 'photo_4', 'photo_5',
        'police_report', 'release_form', 'signed_driver_statement']
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const maxFileSize = 5 * 1024 * 1024 // 5 mb
        if (files[0].size > maxFileSize) {
            alert('File Exceed Max Size Allowed!')
            e.target.value = ''
        } else {
            setIncidentFiles({
                ...IncidentFiles,
                [name]: files[0],
            });
        }

    };


    const handleRemoveFile = (name) => {
        setIncidentFiles({
            ...IncidentFiles,
            [name]: '',
        });
        const fileInput = document.getElementById(name);
        fileInput.value = null;

    }

    useEffect(() => {
        fileList.forEach((file) => {
            if (IncidentFiles[file] !== '') {
                setAllowNext(true)
            }
        })


    }, [IncidentFiles])
    return (

        <form >

            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <InputLabel>Police Report (.pdf, .jpg)</InputLabel>

                    <input
                        type="file"
                        id="police_report"
                        name="police_report"
                        onChange={handleFileChange}
                        accept=".pdf, image/*"
                    />
                    {IncidentFiles.police_report.name ?
                        <>
                            <IconButton color='error' onClick={() => handleRemoveFile('police_report', 0)}>
                                <Typography variant="button" style={{ color: 'red' }}>Delete</Typography>
                            </IconButton>
                            <ListItem
                                key={0}
                            >
                                <Typography variant="h6">{`${IncidentFiles.police_report.name}`}</Typography>
                            </ListItem>
                        </>

                        :
                        <></>
                    }
                </Grid>

                <Grid item xs={12} md={8}>
                    <InputLabel>Signed Driver Statement (.pdf, .jpg)</InputLabel>

                    <input
                        type="file"
                        id="signed_driver_statement"
                        name="signed_driver_statement"
                        onChange={handleFileChange}
                        accept=".pdf, image/*"
                    />
                    {IncidentFiles.signed_driver_statement.name ?
                        <>
                            <IconButton color='error' onClick={() => handleRemoveFile('signed_driver_statement', 0)}>
                                <Typography variant="button" style={{ color: 'red' }}>Delete</Typography>
                            </IconButton>
                            <ListItem
                                key={0}
                            >
                                <Typography variant="h6">{`${IncidentFiles.signed_driver_statement.name}`}</Typography>
                            </ListItem>
                        </>
                        :
                        <></>
                    }
                </Grid>
                <Grid item xs={12} md={8}>
                    <InputLabel>Release Form (.pdf, .jpg)</InputLabel>

                    <input
                        type="file"
                        id="release_form"
                        name="release_form"
                        onChange={handleFileChange}
                        accept=".pdf, image/*"
                    />
                    {IncidentFiles.release_form.name ?
                        <>
                            <IconButton color='error' onClick={() => handleRemoveFile('release_form', 0)}>
                                <Typography variant="button" style={{ color: 'red' }}>Delete</Typography>
                            </IconButton>
                            <ListItem
                                key={0}
                            >
                                <Typography variant="h6">{`${IncidentFiles.release_form.name}`}</Typography>
                            </ListItem>
                        </>

                        :
                        <></>
                    }
                </Grid>
                {
                    numPhotos.map((num) => (
                        <Grid item xs={12} md={8} key={`photo_${num}`}>
                            <InputLabel>{`Photo ${num} (.pdf, .jpg)`}</InputLabel>

                            <input
                                type="file"
                                id={`photo_${num}`}
                                name={`photo_${num}`}
                                onChange={handleFileChange}
                                accept=".pdf, image/*"
                            />
                            {IncidentFiles[`photo_${num}`].name ?
                                <>
                                    <IconButton color='error' onClick={() => handleRemoveFile(`photo_${num}`, 0)}>
                                        <Typography variant="button" style={{ color: 'red' }}>Delete</Typography>
                                    </IconButton>
                                    <ListItem
                                        key={0}
                                    >
                                        <Typography variant="h6">{`${IncidentFiles[`photo_${num}`].name}`}</Typography>
                                    </ListItem>
                                </>

                                :
                                <></>
                            }
                        </Grid>
                    ))
                }


            </Grid>
        </form>
    )


}

export default AddIncidentFiles