import { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Select from '@mui/material/Select';
import { capitalCase } from 'change-case';
import Box from '@mui/material/Box';
import { Radio, FormControlLabel, RadioGroup, FormControl, Typography } from '@mui/material';
import { allRegion, allDriverType } from '../../../../utils/dropOptions';

function FMCSRQuestions({ DriverFMCSR, setDriverFMCSR }) {
    const today = new Date();
    const yesValue = true
    const noValue = false
    console.log(DriverFMCSR)

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value === 'true') {
            setDriverFMCSR((prevData) => ({
                ...prevData, [name]: true
            }))
        } else {
            setDriverFMCSR((prevData) => ({
                ...prevData, [name]: false
            }))
        }



    };

    return (
        <form>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop={5}
                marginBottom={3}
            >
                <Typography variant='h6'>Answer the following FMCSA questions accurately. </Typography>


            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} >
                    <FormControl component="fieldset">
                        <Typography variant='subtitle'>*Under FMSCR 391.15 are you currently disqualified
                            from driving a commericial motor vehicle? [49 CFR 391.15]</Typography>
                        <RadioGroup
                            row
                            name="question_1"
                            value={DriverFMCSR.question_1}
                            onChange={handleChange}>
                            <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                            <FormControlLabel value={noValue} control={<Radio />} label="No" />
                        </RadioGroup>

                    </FormControl>
                </Grid>


                <Grid item xs={12} md={8} >
                    <FormControl component="fieldset">
                        <Typography variant='subtitle'>*Has your license, permit or privilege to
                            drive ever been suspended or revoked for any reason? [49 CFR 391.21(b)(9)]</Typography>
                        <RadioGroup
                            row
                            name="question_2"
                            value={DriverFMCSR.question_2}
                            onChange={handleChange}>
                            <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                            <FormControlLabel value={noValue} control={<Radio />} label="No" />
                        </RadioGroup>

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={8} >
                    <FormControl component="fieldset">
                        <Typography variant='subtitle'>*Have you ever been denied a license, permit,
                            or privilege to operate a motor vehicle?[49 CFR 391.21(b)(9)]</Typography>
                        <RadioGroup
                            row
                            name="question_3"
                            value={DriverFMCSR.question_3}
                            onChange={handleChange}>
                            <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                            <FormControlLabel value={noValue} control={<Radio />} label="No" />
                        </RadioGroup>

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={8} >
                    <FormControl component="fieldset">
                        <Typography variant='subtitle'>*Within the past two years, have you tested
                            positive, or refused to test, on a pre-employment drug or alcohol test by an
                            employer to whom you applied, but did not obtain, safety-sensitive
                            transportation work covered by DOT agency drug and alcohol testing rules? [49 CFR 40.25(j)]</Typography>
                        <RadioGroup
                            row
                            name="question_4"
                            value={DriverFMCSR.question_4}
                            onChange={handleChange}>
                            <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                            <FormControlLabel value={noValue} control={<Radio />} label="No" />
                        </RadioGroup>

                    </FormControl>
                </Grid>
                <Grid item xs={12} md={8} >
                    <FormControl component="fieldset">
                        <Typography variant='subtitle'>*In the past three(3) years, have you ever been
                            convicted of any of the following offenses: [49 CFR 391.15]:

                        </Typography>
                        <ul>
                            <li>
                                Driving a commercial motor vehicle with a blood alcohol concentration
                                ("BAC") of .04 percent or more
                            </li>
                            <li>
                                Driving under the influence of alcohol, as prescribed by provincial/state law
                            </li>
                            <li>
                                Refusal to undergo drug and alcohol testing as required by any jurisdiction
                                for the enforcement of Federal Motor Carrier Safety Act regulations
                            </li>
                            <li>
                                Driving a commercial motor vehicle under the influence of any 21
                                C.F.R. 1308.11 Schedule I identified controlled substance, an amphetamine,
                                a narcotic drug, a formulation of an amphetamine, or a derivative of a
                                narcotic drug
                            </li>
                            <li>
                                Transportation, possession, or unlawful use of a 21 C.F.R. 1308.11 Schedule I
                                identified controlled substance, amphetamines, narcotic drugs, formulations of
                                an amphetamine, or derivatives of narcotic drugs while you were on duty driving
                                for a motor carrier
                            </li>
                            <li>
                                Leaving the scene of an accident while operating a commercial motor vehicle
                            </li>
                            <li>
                                Or any other felony involving the use of a commercial motor vehicle
                            </li>
                        </ul>

                        <RadioGroup
                            row
                            name="question_5"
                            value={DriverFMCSR.question_5}
                            onChange={handleChange}>
                            <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                            <FormControlLabel value={noValue} control={<Radio />} label="No" />
                        </RadioGroup>

                    </FormControl>
                </Grid>
            </Grid>

        </form>

    )


}

export default FMCSRQuestions