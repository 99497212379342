import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { capitalCase } from 'change-case';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import { Radio, FormControlLabel, RadioGroup, FormControl, Button, ListItem, Typography, IconButton } from '@mui/material';
import { allDriverType, TruckType, TrailerType, AllRangeMiles } from '../../../../utils/dropOptions';
import { isEmailValid, isPhoneNumberValid } from '../../../../shared/dataValidation';


function WorkExperience({ DriverExperience, setDriverExperience }) {

    const today = new Date();
    const earliestStartDate = new Date(today.getFullYear() - 7, today.getMonth(), 1);


    const yesValue = true
    const noValue = false

    const initialWorkExperience = {
        'company_name': '',
        'position': '',
        'start_date': '',
        'end_date': '',
        'operate_commercial': null,
        'commercial_exp': {
            'truck': 'cargo',
            'trailer': 'none',
            'cross_border': noValue,
            'subject_regulations': null,
            'safety_sensitive': null,
            'miles_weekly': '0-500',
            'pay_per_mile': '',
            'prov_state_driven': '',
        },
        'reason_leaving': '',
        'auth_to_contact': null,
        'email': '',
        'phone': '',
        'address': '',
        'verified': noValue, //set by company
    }

    const addItem = () => {
        if (DriverExperience.length < 8) {
            setDriverExperience(prevItems => [...prevItems, initialWorkExperience])
        } else {
            alert('Only provide 8 most recent experiences.')
        }
    }

    const deleteItem = (index) => {
        const updatedItems = DriverExperience.filter((_, i) => i !== index);
        setDriverExperience(updatedItems)
    }

    // Need to include index
    const handleDateChange = (date, e, index) => {
        if (e == null) {
            alert('Please Select Valid Date')
        } else {
            const updatedExperience = [...DriverExperience]
            updatedExperience[index] = {
                ...updatedExperience[index],
                [date]: e
            }
            setDriverExperience(updatedExperience)
        }
    }

    const handleExperienceChange = (e, index) => {
        const { name, value } = e.target;
        const updatedExperience = [...DriverExperience]
        if (name === 'operate_commercial' || name === 'auth_to_contact') {
            if (value === 'true') {
                updatedExperience[index] = {
                    ...updatedExperience[index],
                    [name]: true
                }
            } else {
                updatedExperience[index] = {
                    ...updatedExperience[index],
                    [name]: false
                }
            }

            setDriverExperience(updatedExperience)
        } else {
            updatedExperience[index] = {
                ...updatedExperience[index],
                [name]: value
            }
            setDriverExperience(updatedExperience)
        }
    };

    const handleCommericialChange = (e, index) => {
        const { name, value } = e.target
        const updatedExperience = [...DriverExperience]
        const updatedCommercial = updatedExperience[index].commercial_exp
        if (name === 'subject_regulations' || name === 'safety_sensitive') {
            if (value === 'true') {
                updatedCommercial[name] = true
            } else {
                updatedCommercial[name] = false
            }
        } else if (name === 'pay_per_mile') {
            console.log(value === null, value === '')
            if (/^\d{1,3}$/.test(value)) {
                updatedCommercial[name] = parseInt(value, 10)
            }
        } else {
            updatedCommercial[name] = value
        }
        updatedExperience[index] = {
            ...updatedExperience[index],
            'commercial_exp': updatedCommercial
        }
        setDriverExperience(updatedExperience)
    }

    return (
        <form>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop={5}
                flexDirection="column"
                minWidth="800px"
            >
                <Typography variant='h6'> Provide details of the last 7 years of your work history (employment/contract). </Typography>


            </Box>
            {
                DriverExperience.map((value, index) => {
                    return (
                        <ListItem
                            key={index}
                            secondaryAction={
                                <IconButton color='error' onClick={() => deleteItem(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <Grid key={index} container spacing={3} >
                                <Grid item xs={12} md={12} >
                                    <Typography mt={5} variant='h5'>EXPERIENCE #{index + 1}:</Typography>
                                </Grid>

                                <Grid item xs={12} md={6} >
                                    <TextField
                                        required
                                        name='company_name'
                                        onChange={(e) => handleExperienceChange(e, index)}
                                        value={value.company_name}
                                        fullWidth
                                        inputProps={{ maxLength: 50 }}
                                        label='Company Name'
                                        variant='standard'
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <TextField
                                        required
                                        name='position'
                                        onChange={(e) => handleExperienceChange(e, index)}
                                        value={value.position}
                                        fullWidth
                                        label='Job Position'
                                        inputProps={{ maxLength: 50 }}
                                        variant='standard'
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            required
                                            name='start_date'
                                            label={'Start Date*'}
                                            value={value.start_date}
                                            minDate={earliestStartDate}
                                            onChange={(e) => handleDateChange('start_date', e, index)}
                                            maxDate={value.end_date === '' ? today : value.end_date}
                                            views={['year', 'month']}

                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12} md={8}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            name='end_date'
                                            label={'End Date*'}
                                            value={value.end_date}
                                            onChange={(e) => handleDateChange('end_date', e, index)}
                                            minDate={value.start_date}
                                            maxDate={new Date()}
                                            views={['year', 'month']}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <TextField

                                        name='reason_leaving'
                                        onChange={(e) => handleExperienceChange(e, index)}
                                        value={value.reason_leaving}
                                        fullWidth
                                        label='Reason Leaving'
                                        variant='outlined'
                                        inputProps={{ maxLength: 50 }}

                                    />

                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography variant='subtitle2'>Please provide contact information for this employer below. </Typography>

                                </Grid>

                                <Grid item xs={12} md={5}>
                                    <TextField
                                        required
                                        name='email'
                                        label='Email'
                                        onChange={(e) => handleExperienceChange(e, index)}
                                        value={value.email}
                                        fullWidth
                                        autoComplete='email'
                                        variant='outlined'
                                        error={!isEmailValid(value.email)}
                                        helperText={
                                            !isEmailValid(value.email)
                                                ? 'Invalid email format'
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={5}>

                                    <TextField
                                        required
                                        name='phone'
                                        label='Phone #'
                                        onChange={(e) => handleExperienceChange(e, index)}
                                        value={value.phone}
                                        fullWidth
                                        autoComplete='tel'
                                        variant='outlined'
                                        error={!isPhoneNumberValid(value.phone)}
                                        helperText={
                                            !isPhoneNumberValid(value.phone)
                                                ? 'Phone number must have 10 digits'
                                                : ''
                                        }

                                    />

                                </Grid>
                                <Grid item xs={12} md={7}>

                                    <TextField
                                        required
                                        name='address'
                                        label='Address'
                                        onChange={(e) => handleExperienceChange(e, index)}
                                        value={value.address}
                                        fullWidth
                                        variant='outlined'
                                        inputProps={{ maxLength: 100 }}
                                    />

                                </Grid>
                                <Grid item xs={12} md={9} alignItems="center">
                                    <FormControl component="fieldset">
                                        <Typography variant="subtitle1">Did you operate a commercial vehicle in this position?</Typography>
                                        <RadioGroup
                                            name="operate_commercial"
                                            value={value.operate_commercial}
                                            onChange={(e) => handleExperienceChange(e, index)}
                                            required
                                            row
                                        >
                                            <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                                            <FormControlLabel value={noValue} control={<Radio />} label="No" />

                                        </RadioGroup>
                                        {value.operate_commercial === null && <Typography variant="body2" style={{ color: 'red' }}>
                                            Required
                                        </Typography>}
                                    </FormControl>
                                </Grid>
                                {value.operate_commercial ?
                                    <>
                                        {/* values not getting across for truck, trailer and type */}
                                        <Grid item xs={12} md={5}>
                                            <FormControl fullWidth >
                                                <InputLabel id='truck-select-label'>What type of commercial vehicle did you operate?</InputLabel>
                                                <Select
                                                    labelId='truck-select-label'
                                                    id='truck-select'
                                                    name='truck'
                                                    onChange={(e) => handleCommericialChange(e, index)}
                                                    value={value.commercial_exp.truck}
                                                    // fullWidth
                                                    input={<OutlinedInput label='What type of commercial vehicle did you operate?' />}
                                                >
                                                    {TruckType.map((t) => (
                                                        <MenuItem key={t} value={t}>
                                                            <ListItemText primary={capitalCase(t)} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>


                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <FormControl fullWidth >
                                                <InputLabel id='trailer-select-label'>What type of trailer was used?</InputLabel>
                                                <Select
                                                    labelId='trailer-select-label'
                                                    id='trailer-select'
                                                    name='trailer'
                                                    onChange={(e) => handleCommericialChange(e, index)}
                                                    value={value.commercial_exp.trailer}
                                                    // fullWidth
                                                    input={<OutlinedInput label='What type of trailer was used?' />}
                                                >
                                                    {TrailerType.map((t) => (
                                                        <MenuItem key={t} value={t}>
                                                            <ListItemText primary={capitalCase(t)} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} md={5}>
                                            <FormControl fullWidth >
                                                <InputLabel id='cross_border-select-label'>Did you cross US/Canada Border?</InputLabel>
                                                <Select
                                                    labelId='cross_border-select-label'
                                                    id='cross_border-select'
                                                    name='cross_border'
                                                    onChange={(e) => handleCommericialChange(e, index)}
                                                    value={value.commercial_exp.cross_border}
                                                    input={<OutlinedInput label='Did you cross US/Canada Border?' />}
                                                >
                                                    <MenuItem value={yesValue}>
                                                        <ListItemText primary='Yes' />
                                                    </MenuItem>
                                                    <MenuItem value={noValue}>
                                                        <ListItemText primary='No' />
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <FormControl fullWidth >
                                                <InputLabel id='miles-weekly-select-label'>What was your avg. weekly miles driven?</InputLabel>
                                                <Select
                                                    labelId='miles-weekly-select-label'
                                                    id='miles-weekly-select'
                                                    name='miles_weekly'
                                                    onChange={(e) => handleCommericialChange(e, index)}
                                                    value={value.commercial_exp.miles_weekly}
                                                    // fullWidth
                                                    input={<OutlinedInput label='What was your avg. weekly miles driven?' />}
                                                >
                                                    {AllRangeMiles.map((t) => (
                                                        <MenuItem key={t} value={t}>
                                                            <ListItemText primary={t} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <TextField
                                                type="number"
                                                name='pay_per_mile'
                                                fullWidth
                                                InputProps={{
                                                    inputProps: {
                                                        min: 0,
                                                        max: 500
                                                    }
                                                }}
                                                value={value.commercial_exp.pay_per_mile}
                                                onChange={(e) => handleCommericialChange(e, index)}
                                                label="What was you pay/mile (cents)"
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={8}>
                                            <TextField
                                                name='prov_state_driven'
                                                fullWidth
                                                inputProps={{ maxLength: 60 }}
                                                value={value.commercial_exp.prov_state_driven}
                                                onChange={(e) => handleCommericialChange(e, index)}
                                                label="List provinces/states you have driven a commericial vehicle"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={9}>
                                            <FormControl component="fieldset">
                                                <Typography variant="subtitle1">Were you subject to the Federal Motor Carrier or Transport Canada Safety Regulations while
                                                    employed/contracted by this employer/contractor?* </Typography>
                                                <RadioGroup
                                                    name="subject_regulations"
                                                    value={value.commercial_exp.subject_regulations}
                                                    onChange={(e) => handleCommericialChange(e, index)}
                                                    required
                                                    row
                                                >
                                                    <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                                                    <FormControlLabel value={noValue} control={<Radio />} label="No" />

                                                </RadioGroup>
                                                {value.commercial_exp.subject_regulations === null &&
                                                    <Typography variant="body2" style={{ color: 'red' }}>
                                                        Required
                                                    </Typography>
                                                }

                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={9} alignItems="center">
                                            <FormControl component="fieldset">
                                                <Typography variant="subtitle1">Did you perform any safety sensitive functions in this job,
                                                    regulated by DOT, and subject to drug and alcohol testing?* </Typography>
                                                <RadioGroup
                                                    name="safety_sensitive"
                                                    value={value.commercial_exp.safety_sensitive}
                                                    onChange={(e) => handleCommericialChange(e, index)}
                                                    required
                                                    row
                                                >
                                                    <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                                                    <FormControlLabel value={noValue} control={<Radio />} label="No" />

                                                </RadioGroup>
                                                {value.commercial_exp.safety_sensitive === null &&
                                                    <Typography variant="body2" style={{ color: 'red' }}>
                                                        Required
                                                    </Typography>
                                                }

                                            </FormControl>
                                        </Grid>
                                    </>
                                    : <></>
                                }
                                <Grid item xs={12} md={9} alignItems="center">
                                    <FormControl component="fieldset">
                                        <Typography variant="subtitle1">May we contact this employer to verify your experience?* </Typography>
                                        <RadioGroup
                                            name="auth_to_contact"
                                            value={value.auth_to_contact}
                                            onChange={(e) => handleExperienceChange(e, index)}
                                            required
                                            row
                                        >
                                            <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                                            <FormControlLabel value={noValue} control={<Radio />} label="No" />

                                        </RadioGroup>
                                        {value.auth_to_contact === null &&
                                            <Typography variant="body2" style={{ color: 'red' }}>
                                                Required
                                            </Typography>
                                        }

                                    </FormControl>
                                </Grid>
                            </Grid>
                        </ListItem>
                    )
                })
            }

            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop={3}
            >
                < Button variant="outlined" onClick={() => addItem()}>
                    + Add
                </Button >
            </Box>

        </form >

    )


}

export default WorkExperience