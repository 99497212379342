import { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    TextField
} from '@mui/material';
import { toast } from 'react-toastify';

import LoadingDisplay from '../../../pages/LoadingDisplay';
import { getRequest, putRequest } from '../../../shared/apiCalls';
import ErrorPage from '../../../pages/ErrorPage';

function InsuranceGridComponent({ onChange, data }) {

    return (
        <>
            {data.map((item, index) => ( // Make sure you have parentheses () around the map function's return value.
                <Grid
                    item
                    md={6}
                    xs={12}
                    key={index}
                >
                    <TextField
                        fullWidth
                        helperText={item.helperText}
                        label={item.label}
                        name={item.name}
                        onChange={onChange}
                        required={item.required}
                        value={item.value}
                        variant={item.variant}
                        disabled={item.disabled}
                    />
                </Grid>
            ))}
        </>
    );
}

export const InsuranceDetails = () => {
    const companyName = localStorage.getItem('name')
    const [values, setValues] = useState([])
    const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        const getInsurance = async () => {
            try {
                const response = await getRequest(`company/${companyName}`)
                const result = await response.json()
                setValues(result)
                setLoading(false)
            } catch (error) {
                console.error('Error:', error);
                setError(true)
            }

        }

        getInsurance()
    }, [])

    const handleChange = (event) => {

        const { name, value } = event.target;
        if ((name === 'policy_period' || name === 'insurance_phone_number') && value.match(/[^0-9]/)) {

        } else {
            setValues({
                ...values,
                [name]: value
            });
        }

    };

    const handleSubmit = async (e) => {
        const phoneNumberRegex = /^\d{10}$/;
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

        if (values.insurance_phone_number.length !== 10 || !phoneNumberRegex.test(values.insurance_phone_number)) {
            alert('Enter phone number with no spaces. (i.e. 1234567890)')
        } else if (!emailRegex.test(values.insurance_email)) {
            alert('Enter valid email. (i.e. name@domain.com)')
        } else {
            setEdit(false)
            try {
                await toast.promise(putRequest(`company/${companyName}`,
                    values), {
                    pending: 'Updating Insurance...',
                    success: 'Insurance Updated',
                    error: 'Error Updating Insurnace'
                })
            } catch (error) {
                console.error('Error:', error);
                alert('An Error Occured! Please try again!')
            }
        }
    }

    const insuranceContactData = [
        {
            helperText: "Please specify the broker's name",
            label: "Agent Name",
            name: "insurance_agent_name",
            required: true,
            value: values.insurance_agent_name,
            variant: "outlined",
            disabled: !edit
        }, {
            helperText: "Please specify the brokerage name",
            label: "Brokage name",
            name: "brokerage_name",
            required: true,
            value: values.brokerage_name,
            variant: "outlined",
            disabled: !edit
        }, {
            helperText: "Please specify the broker's email address",
            label: "Email Address",
            name: "insurance_email",
            required: true,
            value: values.insurance_email,
            variant: "outlined",
            disabled: !edit
        }, {
            helperText: "Please specify the phone number",
            label: "Phone Number",
            name: "insurance_phone_number",
            required: false,
            value: values.insurance_phone_number,
            variant: "outlined",
            disabled: !edit
        }

    ];

    if (error) {
        return (
            <ErrorPage />
        )
    }


    return (
        <>
            {loading ? <LoadingDisplay /> :
                <form
                    autoComplete="off"
                    noValidate
                >
                    <Card>
                        <CardHeader
                            title={<>Details<Button onClick={() => setEdit(!edit)}>{edit ? 'Cancel' : 'Edit'}</Button></>}

                        >
                            <Button>Edit</Button>
                        </CardHeader>


                        <CardContent>

                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        helperText="Please specify the Insurance Policy Number"
                                        label="Policy Number"
                                        name="policy_number"
                                        required
                                        value={values.policy_number}
                                        variant="outlined"
                                        onChange={handleChange}
                                        disabled={!edit}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        fullWidth
                                        helperText="Please specify the Insurance Policy Period in Months"
                                        label="Policy Period"
                                        name="policy_period"
                                        required
                                        value={values.policy_period}
                                        variant="outlined"
                                        onChange={handleChange}
                                        disabled={!edit}
                                        inputProps={{
                                            inputMode: 'numeric',
                                        }}
                                    />
                                </Grid>

                            </Grid>
                            <br />
                            <Grid
                                container
                                spacing={3}
                            >
                                <InsuranceGridComponent
                                    onChange={(event) => handleChange(event)}
                                    data={insuranceContactData}
                                />

                            </Grid>
                        </CardContent>
                        <Divider />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                p: 2
                            }}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => handleSubmit()}
                                disabled={!edit}
                            >
                                Save details
                            </Button>
                        </Box>
                    </Card>
                </form>
            }
        </>
    );
};
