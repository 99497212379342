import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, ListItem, Typography, IconButton } from '@mui/material';


function Unemployment({ DriverUnemployment, setDriverUnemployment }) {
    const today = new Date()
    const earliestDate = new Date(today)
    earliestDate.setFullYear(today.getFullYear() - 7)


    const initUnemployment = {
        'start_date': '',
        'end_date': ''
    }

    const addItem = () => {
        if (DriverUnemployment.length < 8) {
            setDriverUnemployment(prevItems => [...prevItems, initUnemployment])
        } else {
            alert('Only provide 8 most recent experiences.')
        }
    }


    const deleteItem = (index) => {
        const updatedItems = DriverUnemployment.filter((_, i) => i !== index);
        setDriverUnemployment(updatedItems)
    }

    // Need to include index
    const handleDateChange = (date, e, index) => {
        if (e == null) {
            alert('Please Select Valid Date')
        } else {
            const updatedExperience = [...DriverUnemployment]
            updatedExperience[index] = {
                ...updatedExperience[index],
                [date]: e
            }
            setDriverUnemployment(updatedExperience)
        }
    }



    return (
        <form>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop={5}
                flexDirection="column"
                minWidth="800px"
            >
                <Typography variant='h6'> Provide dates of any unemployment periods you may have had in the last 7 years.</Typography>


            </Box>
            {
                DriverUnemployment.map((value, index) => {
                    return (
                        <ListItem
                            key={index}
                            secondaryAction={
                                <IconButton color='error' onClick={() => deleteItem(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <Grid key={index} container spacing={3} >
                                <Grid item xs={12} md={12} >
                                    <Typography mt={5} variant='h5'>PERIOD #{index + 1}:</Typography>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            required
                                            name='start_date'
                                            label={'Start Date*'}
                                            value={value.start_date}
                                            onChange={(e) => handleDateChange('start_date', e, index)}
                                            maxDate={value.end_date === '' ? today : value.end_date}
                                            minDate={earliestDate}
                                            views={['year', 'month']}

                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12} md={8}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            name='end_date'
                                            label={'End Date'}
                                            value={value.end_date}
                                            onChange={(e) => handleDateChange('end_date', e, index)}
                                            minDate={value.start_date}
                                            maxDate={today}
                                            views={['year', 'month']}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>

                        </ListItem>
                    )
                })
            }

            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop={3}
            >
                < Button variant="outlined" onClick={() => addItem()}>
                    + Add
                </Button >
            </Box>

        </form >

    )


}

export default Unemployment