import { useState } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { capitalCase } from 'change-case';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import 'react-toastify/dist/ReactToastify.css';
import UpdateStatus from './status_components/UpdateStatus';
import ConfirmHire from './status_components/ConfirmHire';
import { putRequest } from '../../../../shared/apiCalls';

// expect date in format of: 2024-02-02T00:34:26+00:00
function formatDate({ date }) {
    return new Date(date.slice(0, -6))
}


export default function Status({ DriverDetails, setDriverDetails }) {

    const [openChangeModal, setOpenChangeModal] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [disableAll, setDisableAll] = useState(false)
    // returns # of completed steps
    const completedSteps = (taskCompleted) => {
        const count = Object.keys(taskCompleted).reduce((acc, e) => {
            if (taskCompleted[e].completed === true) {
                return acc + 1;
            }
            return acc;
        }, 0);
        return count
    }


    const handleStepsChange = async (step) => {
        const done = !DriverDetails.steps_completed[step].completed;
        const date = done ? new Date() : '';
        const updatedSteps = {
            ...DriverDetails.steps_completed,
            [step]: {
                completed: done,
                date
            }
        }
        const data = { "steps_completed": updatedSteps }
        if (DriverDetails.status === 'new_applicant' &&
            completedSteps(DriverDetails.steps_completed) === 0) {
            data.status = 'in_progress'
        }
        setDisableAll(true)
        try {
            const response = await toast.promise(putRequest(`driver/${DriverDetails.id}`, data),
                {
                    pending: 'Updating Driver...',
                    success: 'Driver Updated',
                    error: 'Error Updating Driver'
                })

            setDriverDetails({
                ...DriverDetails,
                'steps_completed': updatedSteps,
                'status': 'in_progress'
            });
        } catch (error) {
            console.error(`Error: ${error}`)
        }
        setDisableAll(false)

    }

    const handleHireOrReject = async (newStatus) => {
        try {
            if (newStatus === 'hired') {
                if (!DriverDetails.drug_test_date
                    || !DriverDetails.offer_letter_date) {
                    setOpenConfirm(true)
                } else {
                    const response = await toast.promise(putRequest(`driver/${DriverDetails.id}`, {
                        'status': 'hired'
                    }),
                        {
                            pending: 'Updating Status...',
                            success: 'Status Updated',
                            error: 'Error Updating Status'
                        })
                    setDriverDetails(driver => {
                        return { ...driver, 'status': 'hired' }
                    });
                }
            } else {
                const response = await toast.promise(putRequest(`driver/${DriverDetails.id}`, {
                    'status': 'rejected'
                }),
                    {
                        pending: 'Updating Status...',
                        success: 'Status Updated',
                        error: 'Error Updating Status'
                    })

                setDriverDetails(driver => {
                    return { ...driver, 'status': 'rejected' }
                });
            }
        } catch (error) {
            console.error(error)
        }
    }

    // will need to use data from query id
    // const userColRef = query(collection(db, 'users'), where("uid", '===', searchParams.get('query'))
    return (

        <Paper elevation={3} style={{ padding: '20px' }}>
            <Grid container spacing={3} justifyContent="center" >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography display="inline" variant="h5" gutterBottom>
                        Status:{" "}
                    </Typography>

                    <Typography display="inline" color={(DriverDetails.status === 'fired' && 'error')
                        || (DriverDetails.status === 'inactive' && 'warning.dark')
                        || (DriverDetails.status === 'rejected' && 'error')
                        || (DriverDetails.status === 'hired' && 'default')
                        || 'primary'} variant="h5" >
                        {capitalCase(DriverDetails.status)}
                    </Typography>

                    {(DriverDetails.status === "hired" || DriverDetails.status === "inactive"
                        || DriverDetails.status === "fired") ?
                        <Button onClick={() => setOpenChangeModal(true)} size='medium'> Edit </Button> : <></>
                    }

                    <Typography variant="body2" >
                        {`Last Updated: ${DriverDetails.last_update.toString().slice(0, 25)}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <List sx={{ width: '95%', bgcolor: 'background.paper' }}>
                        {Object.keys(DriverDetails.steps_completed).map((step, index) => {
                            const completed = DriverDetails.steps_completed[step].completed
                            const dateCompleted = new Date(DriverDetails.steps_completed[step].date)
                            const disableCheck = (DriverDetails.status === 'inactive' || DriverDetails.status === 'fired'
                                || DriverDetails.status === "hired" || DriverDetails.status === "rejected" || disableAll)

                            return (
                                <ListItem
                                    key={index}
                                >
                                    <ListItemIcon>
                                        <Tooltip title={completed ? 'Task Completed' : 'Incomplete'}>
                                            <Checkbox
                                                edge="start"
                                                checked={DriverDetails.steps_completed[step].completed}
                                                tabIndex={-1}
                                                disableRipple
                                                disabled={disableCheck}
                                                onChange={() => handleStepsChange(step)}
                                            />
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={capitalCase(step)}
                                        secondary={
                                            completed
                                                ?
                                                `Completed on: ${dateCompleted.toString().slice(0, 25)}`
                                                :
                                                'Incomplete'
                                        } />
                                </ListItem>
                            )
                        })}
                    </List>
                </Grid>
                {(DriverDetails.status === 'inactive' || DriverDetails.status === 'fired' || DriverDetails.status === "hired"
                    || DriverDetails.status === "rejected") ? <></>
                    :
                    <>
                        <Button onClick={() => handleHireOrReject('reject')} color='error' size={'large'} >
                            Reject
                        </Button>
                        {(completedSteps(DriverDetails.steps_completed) === Object.keys(DriverDetails.steps_completed).length ?
                            <Button onClick={() => handleHireOrReject('hired')} size={'large'} >
                                Hire
                            </Button> :
                            <Tooltip title="Complete All Tasks to Hire">
                                <span>
                                    <Button size={'large'} disabled>
                                        Hire
                                    </Button>
                                </span>
                            </Tooltip>)}

                    </>
                }
                {openConfirm &&
                    <ConfirmHire onClose={() => setOpenConfirm(false)} open={openConfirm} DriverDetails={DriverDetails} setDriverDetails={setDriverDetails} />
                }
                {openChangeModal &&
                    <UpdateStatus onClose={() => setOpenChangeModal(false)} open={openChangeModal} DriverDetails={DriverDetails} setDriverDetails={setDriverDetails} />}
            </Grid>
        </Paper>
    )

}