import React from 'react';
import { Container, Paper, Typography, Grid, Checkbox } from '@mui/material';
import { capitalCase } from 'change-case';


const CompanyDetailsPage = ({ data }) => {
    return (
        <Container maxWidth="md" style={{ marginTop: '20px' }}>
            <Paper elevation={3} style={{ padding: '20px' }}>
                <Typography variant="h5" gutterBottom>
                    Company Details
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                            <strong>Company Number:</strong> {data.company_number}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                            <strong>Company Address:</strong> {data.company_address}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                            <strong>HST Number:</strong> {data.hst_number}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography variant="subtitle1">
                            <strong>Driver Management:</strong>
                            {data.services && data.services.driver_management ? ' Activated' : ' Inactive'}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="subtitle1">
                            <strong>Claim Management:</strong>
                            {data.services && data.services.claim_management ? ' Activated' : ' Inactive'}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <Typography variant="subtitle1">
                            <strong>Hiring Steps:</strong> {Object.values(data.hiring_steps).join(', ')}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default CompanyDetailsPage;