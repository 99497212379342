import { useNavigate, Navigate, Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth'
// layouts
import DashboardLayout from './layouts/dashboard';
import PostingsManager from './pages/PostingsManager';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import CalendarPage from './pages/Calendar';
import IncidentManager from './pages/IncidentsManager';
import InsurancePage from './pages/InsurancePage';
import DriverManager from './pages/DriverManager';
import SinglePostPage from './pages/SinglePostPage';
import DisplayJobPost from './pages/DisplayJobPost';
import SingleDriverPage from './pages/SingleDriverPage';
import LoadingDisplay from './pages/LoadingDisplay';
import AdminPage from './pages/AdminPage';
import { auth } from './firebase';

// ----------------------------------------------------------------------



export default function Router() {
  const [user, loading, error] = useAuthState(auth)
  const [admin, setAdmin] = useState(false)
  const [adminLoading, setAdminLoading] = useState(true)
  useEffect(() => {
    if (user) {
      user.getIdTokenResult()
        .then((idTokenResult) => {
          if (idTokenResult.claims.admin) {
            setAdmin(true)
            console.log('Is Admin')
          } else {
            setAdmin(false)
          }
          setAdminLoading(false)
        })
    }

  }, [user, loading]);



  const PrivateRoute = ({ element, path }) => {
    if (loading) {
      return <LoadingDisplay />;
    }
    if (user) {
      return element
    }
    return <Page404 />

  };

  const AdminRoute = ({ element, path }) => {
    if (adminLoading) {
      console.log('admin loading')
      return <LoadingDisplay />;
    }
    if (admin) {
      return element
    }
    return <Page404 />

  };


  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<LoginPage />} />
      <Route path="/dashboard/" element={<PrivateRoute element={<DashboardLayout admin={admin} />} exact />}>
        <Route index element={<Navigate to="/dashboard/drivers" />} exact />
        <Route path="drivers" element={<DriverManager />} exact />
        <Route path="drivers/:id" element={<SingleDriverPage />} exact />
        <Route path="jobpostings" element={<PostingsManager />} exact />
        <Route path="jobpostings/:id" element={<SinglePostPage />} exact />
        <Route path="insurance" element={<InsurancePage />} />
        <Route path="incidents" element={<IncidentManager />} />
        <Route path="admin" element={<AdminRoute element={<AdminPage />} />} />
      </Route>
      <Route path="/jobs/:company/:id" element={<DisplayJobPost />} />
      <Route path="/404" element={<Page404 />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );

}

