import { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';

import { capitalCase } from 'change-case';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Typography from '@mui/material/Typography';
import 'react-toastify/dist/ReactToastify.css';
import { putRequest } from '../../../../shared/apiCalls';

function EditPosting({ post, setPost }) {
    const [Editable, setEditable] = useState(false)

    // pass in post to editPosting

    const handleDateChange = (name, e) => {

        if (e == null) {
            alert("Please Select Valid Date")
            setPost(PostingInfo => {
                return { ...PostingInfo, [name]: new Date() }
            });
        } else {
            setPost(PostingInfo => {
                return { ...PostingInfo, [name]: e }
            });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setPost(post => {
            return {
                ...post,
                [name]: value
            }
        });
    };


    const handleSubmit = async (event) => {
        // need backend to ensure all required items are filled
        // handleChange('last_update', new Date())
        event.preventDefault()
        if (post.job_title === '' || post.job_location === ''
            || post.job_description === '' || post.posting_end_date === ''
            || post.posting_start_date === '') {
            alert('Missing Required Fields')
        } else {
            const today = new Date()
            const data = {
                id: post.id,
                company_id: post.company_id,
                company_name: post.company_name.trim(),
                company_offer: post.company_offer.trim(),
                job_description: post.job_description.trim(),
                job_location: post.job_location.trim(),
                job_requirements: post.job_requirements.trim(),
                job_title: post.job_title.trim(),
                last_update: today.toISOString(),
                posting_end_date: post.posting_end_date.toISOString(),
                posting_start_date: post.posting_start_date.toISOString(),
            }
            try {
                await toast.promise(putRequest(`jobpostings/${post.id}`, data),
                    {
                        pending: 'Updating Job Posting...',
                        success: `Job Post #${post.id} Updated`,
                        error: 'Error Updating Job Posting'
                    })
            } catch (error) {
                console.error('Error:', error)
            }
            setEditable(false)
        }

    }





    return (
        <Paper elevation={5} sx={{ mx: 4, pl: 2, pr: 2, py: 2 }} >
            <Typography color={"textSecondary"} variant="h5" gutterBottom>
                {`Job Post # ${post.id}`}
                {Editable ? <></> : <Button onClick={() => setEditable(true)}>Edit</Button>}
            </Typography>
            <form >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <TextField
                            required
                            name='job_title'
                            disabled={!Editable}
                            onChange={handleChange}
                            value={post.job_title}
                            fullWidth
                            label="Job Title"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <TextField
                            required
                            disabled={!Editable}
                            name='job_location'
                            onChange={handleChange}
                            value={post.job_location}
                            fullWidth
                            label="Job Location"
                            autoComplete="address-level2"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                required
                                disabled={!Editable}
                                label="Start Date"
                                value={post.posting_start_date}
                                onChange={(e) => handleDateChange('posting_start_date', e)}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                required
                                disabled={!Editable}
                                label="End Date"
                                minDate={post.posting_start_date}
                                value={post.posting_end_date}
                                onChange={(e) => handleDateChange('posting_end_date', e)}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                        <TextField
                            required
                            name={"job_description"}
                            disabled={!Editable}
                            onChange={handleChange}
                            value={post.job_description}
                            label={capitalCase("job_description")}
                            fullWidth
                            variant="outlined"
                            minRows={3}
                            maxRows={3}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            required
                            name={"job_requirements"}
                            disabled={!Editable}
                            onChange={handleChange}
                            value={post.job_requirements}
                            label={capitalCase("job_requirements")}
                            fullWidth
                            variant="outlined"
                            minRows={3}
                            maxRows={3}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                            name={"company_offer"}
                            disabled={!Editable}
                            onChange={handleChange}
                            value={post.company_offer}
                            label={capitalCase("company_offer")}
                            fullWidth
                            variant="outlined"
                            minRows={3}
                            maxRows={3}
                            multiline
                            required
                        />                </Grid>



                </Grid>
                {Editable ?
                    <Box mt={6}>
                        <Button variant="contained" fullWidth
                            pt={10} type='submit' onClick={(e) => {
                                handleSubmit(e)
                            }}>Update Posting</Button>
                    </Box> :
                    <></>
                }
            </form>
        </Paper>


    );
}

export default EditPosting;