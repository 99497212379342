import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { capitalCase } from 'change-case';
import INCIDENTLIST from '../../../../_mock/incidents';
import { getRequest } from '../../../../shared/apiCalls';
import IncidentForm from '../../incidents/IncidentForm';
import {
    allIncidentType, allRoadOptions, allIncidentStatus, allWeatherOptions
} from '../../../../utils/dropOptions';

const IncidentCard = ({ driverDetails, setDriverDetails }) => {
    const initIncidentInfo = {
        'additional_details': '',
        'bodily_injuries': 0,
        'cargo_damage': 0,
        'claim_number': '',
        'date': null,
        'driver_id': driverDetails.id,
        'fatalities': 0,
        'fault_driver': false,
        'files_available': {
            'photo_1': false,
            'photo_2': false,
            'photo_3': false,
            'photo_4': false,
            'photo_5': false,
            'police_report': false,
            'signed_driver_statement': false,
            'release_form': false
        },
        'filing_claim': false,
        'id': -1,
        'incident_type': allIncidentType[0],
        'last_truck_maintenance': null,
        'location': {
            'city': '',
            'country': "Canada",
            'postal_zip': '',
            'province_state': 'ON - Ontario',
            'street': ''
        },
        'road_condition': allRoadOptions[0],
        'status': allIncidentStatus[0],
        'trailer_damage': 0,
        'truck_damage': 0,
        'video_link': '',
        'weather_condition': allWeatherOptions[0],

    }
    const navigate = useNavigate();
    const [displayForm, setDisplayForm] = useState(false);
    const [incidents, setIncidents] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const handleOpen = () => {
        setDisplayForm(!displayForm);
    };

    const navigateIncidentPage = () => {
        navigate('/dashboard/incidents');
    };

    useEffect(() => {
        const getAllIncidents = async () => {
            try {
                const response = await getRequest(`incidents/driver/${driverDetails.id}`)
                const result = await response.json()
                setIncidents(result.data)
                setLoading(false)
                setError(false)
            } catch (error) {
                setError(true)
            }
        }
        getAllIncidents()
    }, [])

    if (error) {
        return (
            <p>
                An error occured retrieivng incidents.
            </p>
        )
    }

    return (
        <Paper elevation={5} sx={{ mx: 4, pl: 2, pr: 2, py: 2, mb: 4 }}>
            {loading ?
                <p>Loading...</p>
                :
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Incidents
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <List sx={{ width: '95%', bgcolor: 'background.paper' }}>
                            {incidents.map((incident, index) => {
                                const incidentDate = new Date(incident.date)
                                return (
                                    <Button key={index} type="button" color="inherit" onClick={navigateIncidentPage} fullWidth>
                                        <ListItem>
                                            <ListItemText
                                                primary={incidentDate.toString().slice(0, 25)}
                                                secondary={
                                                    <>
                                                        <Typography color={"textSecondary"} variant="subtext" gutterBottom>Type: </Typography>
                                                        <Typography color={"textSecondary"} variant="subtext" gutterBottom>{capitalCase(incident.incident_type)}</Typography>
                                                        <br />
                                                        <Typography color={"textSecondary"} variant="subtext" gutterBottom>Location: </Typography>
                                                        {/* update to only incident.locaition */}
                                                        <Typography color={"textSecondary"} variant="subtext" gutterBottom>{incident.location}</Typography>
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                    </Button>
                                )
                            })}
                        </List>
                    </Grid>

                    {displayForm ? (
                        <IncidentForm
                            incidentData={initIncidentInfo}
                            setIncidents={setIncidents}
                            onClose={() => setDisplayForm(false)}
                            open={displayForm}
                        />
                    ) : (
                        <Grid item xs={12}>
                            <Button variant="contained" fullWidth color="warning" onClick={handleOpen}>
                                Report Incident
                            </Button>
                        </Grid>
                    )}
                </Grid>}
        </Paper>
    );
};

export default IncidentCard;
