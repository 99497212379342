import React from 'react';
import { Typography } from '@mui/material';
import { capitalCase } from 'change-case';

const AccidentDetails = ({ accident }) => {
    const formattedDate = new Date(accident.date).toLocaleDateString();

    return (
        <div>
            <Typography component="span" variant="body1" color="textPrimary">
                <strong>Date:</strong> {formattedDate}
            </Typography>
            <br />
            <Typography component="span" variant="body1" color="textPrimary">
                <strong>Type:</strong> {capitalCase(accident.type)}
            </Typography>
            <br />
            <Typography component="span" variant="body1" color="textPrimary">
                <strong>Hazmat:</strong> {accident.hazmat ? 'Yes' : 'No'}
            </Typography>
            <br />
            <Typography component="span" variant="body1" color="textPrimary">
                <strong>Towed Vehicle:</strong> {accident.towed_vehicle ? 'Yes' : 'No'}
            </Typography>
            <br />
            <Typography component="span" variant="body1" color="textPrimary">
                <strong>Location:</strong> {`${accident.location.province_state}, ${accident.location.country}`}
            </Typography>
            <br />
            <Typography component="span" variant="body1" color="textPrimary">
                <strong>Personal Vehicle:</strong> {accident.commercial ? 'No' : 'Yes'}
            </Typography>
            <br />
            <Typography component="span" variant="body1" color="textPrimary">
                <strong>At Fault:</strong> {accident.at_fault ? 'Yes' : 'No'}
            </Typography>
            <br />
            <Typography component="span" variant="body1" color="textPrimary">
                <strong>Receive Ticket:</strong> {accident.receive_ticket ? 'Yes' : 'No'}
            </Typography>
        </div>
    );
};

export default AccidentDetails;
