import React from 'react';
import { Typography } from '@mui/material';
import { capitalCase } from 'change-case';

const ViolationDetails = ({ violation }) => {
    const formattedDate = new Date(violation.date).toLocaleDateString();

    return (
        <div>
            <Typography component="span" variant="body1" color="textPrimary">
                <strong>Date:</strong> {formattedDate}
            </Typography>
            <br />
            <Typography component="span" variant="body1" color="textPrimary">
                <strong>Type:</strong> {capitalCase(violation.type)}
            </Typography>
            <br />
            <Typography component="span" variant="body1" color="textPrimary">
                <strong>Location:</strong> {`${violation.location.province_state}, ${violation.location.country}`}
            </Typography>
            <br />
            <Typography component="span" variant="body1" color="textPrimary">
                <strong>Commercial:</strong> {violation.commercial ? 'Yes' : 'No'}
            </Typography>
            <br />
            <Typography component="span" variant="body1" color="textPrimary">
                <strong>Penalty:</strong> {violation.penalty ? 'Yes' : 'No'}
            </Typography>
        </div>
    );
};

export default ViolationDetails;
