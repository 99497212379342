import Box from '@mui/material/Box';
import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Grid from '@mui/material/Grid';
import { Radio, FormControlLabel, RadioGroup, FormControl, Button, Typography, Paper } from '@mui/material';

function ReviewForm({ DriverDetails, setDriverDetails }) {

    const yesValue = true
    const noValue = false
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (value === 'true') {
            setDriverDetails((prevData) => ({
                ...prevData, [name]: true
            }))
        } else {
            setDriverDetails((prevData) => ({
                ...prevData, [name]: false
            }))
        }
    };


    const signatureCanvas = useRef(null);

    const clearSignature = () => {
        signatureCanvas.current.clear();
        setDriverDetails((prevData) => ({
            ...prevData,
            'signature_certify': {
                'data': '',
                'date': ''
            }
        }))
    };

    const saveSignature = () => {
        const signatureData = signatureCanvas.current.toDataURL();
        setDriverDetails((prevData) => ({
            ...prevData,
            'signature_certify': {
                'data': signatureData,
                'date': new Date()
            }
        }))
    };

    return (
        <form>
            <Grid container
                justifyContent="center"   // Horizontally center the items
                alignItems="center" // Vertically center the items
                spacing={3}
                direction='column'
                minWidth='800px'
                style={{ paddingX: 4 }}>

                <Grid item >
                    <FormControl component="fieldset">
                        <Typography variant="h6">*Do you confirm all the data provided for this application is true and accurate?</Typography>
                        <RadioGroup
                            row
                            name="valid_data"
                            value={DriverDetails.valid_data}
                            onChange={(e) => handleChange(e)}
                            required
                        >
                            <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                            <FormControlLabel value={noValue} control={<Radio />} label="No" />

                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item >

                    <Grid item>
                        <Paper style={{
                            paddingInline: '70px',
                            marginInline: '100px',
                            backgroundColor: '#D3D3D3',
                            border: '0.5px solid #000'
                        }}>
                            <Typography>
                                By signing my application below, I agree to use an electronic signature to
                                demonstrate my consent. An electronic signature is as legally binding as an
                                ink signature. This certifies that this application was completed by me,
                                and that all entries on it and information in it are true and complete to
                                the best of my knowledge.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle">Please use your finger or mouse to sign your name in the rectangle below. Click "Save" when finished.</Typography>
                </Grid>
                <Grid item >

                    <SignatureCanvas
                        ref={signatureCanvas}
                        penColor='black'
                        canvasProps={{ width: 500, height: 100, style: { border: '1px solid #000' } }}
                    />
                </Grid>
                <Grid item >

                    <Button variant='contained'
                        style={{ backgroundColor: 'grey', color: 'white', borderRadius: '10px', boxShadow: 'none', marginRight: '10px' }}
                        onClick={clearSignature}>Clear</Button>
                    <Button variant='contained'
                        color='primary'
                        style={{ borderRadius: '10px', boxShadow: 'none' }}
                        onClick={saveSignature}>Save</Button>

                </Grid>


            </Grid>



        </form >

    )


}

export default ReviewForm