import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider'
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, List, ListItem, ListItemText, Typography, IconButton } from '@mui/material';


function VerifyExperience({ DriverUnemployment, DriverExperience, setAllowNext }) {
    const [missingIntervals, setMissingIntervals] = useState([])

    const validateDatesCovered = (startDateList, endDateList) => {
        // Determine the start and end dates for the largest possible interval between current month, year, and 10 years ago
        const currentDate = new Date();
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const currentYear = startOfMonth.getFullYear();
        const currentMonth = startOfMonth.getMonth() + 1;
        const sevenYearsAgo = new Date(currentYear - 7, currentMonth - 1); // Subtracting 1 to align with JavaScript month indexing

        // Initialize an array to store all months within the interval
        const allMonths = [];

        // Iterate over the interval to generate all months
        let current = new Date(sevenYearsAgo);
        while (current < startOfMonth) {
            allMonths.push(new Date(current));
            current.setMonth(current.getMonth() + 1);
        }

        // Iterate over the provided start and end dates to mark the months covered
        startDateList.forEach((startDate, index) => {
            const endDate = endDateList[index];
            let tempDate = new Date(startDate);
            while (tempDate <= endDate) {
                const index = allMonths.findIndex(month => month.getFullYear() === tempDate.getFullYear() && month.getMonth() === tempDate.getMonth());
                if (index !== -1) {
                    allMonths.splice(index, 1); // Remove the month from the array if it exists
                }
                tempDate.setMonth(tempDate.getMonth() + 1);
            }
        });

        // Format remaining months as strings in "YYYY-MM" format
        const missingMonths = allMonths.map(month => month.getFullYear() + '-' + String(month.getMonth() + 1).padStart(2, '0'));
        if (missingMonths.length > 0) {
            const intervals = getIntervals(missingMonths)
            setAllowNext(false)
        } else {
            setAllowNext(true)
        }
    }

    const getIntervals = (months) => {
        const intervals = [];
        let startMonth = months[0];
        let endMonth = months[0];

        for (let i = 1; i < months.length; i++) {
            const currentMonth = months[i];
            const prevMonth = months[i - 1];

            // Check if the current month is consecutive to the previous month
            if (getNextMonth(prevMonth) === currentMonth) {
                endMonth = currentMonth; // Extend the interval
            } else {
                // If not consecutive, push the interval and reset
                intervals.push(`${startMonth} to ${endMonth}`);
                startMonth = currentMonth;
                endMonth = currentMonth;
            }
        }

        intervals.push(`${startMonth} to ${endMonth}`);
        setMissingIntervals(intervals)
        return intervals;
    }

    const getNextMonth = (month) => {
        const [year, monthNumber] = month.split('-').map(Number);
        if (monthNumber === 12) {
            return `${year + 1}-01`;
        } else {
            const nextMonth = monthNumber + 1;
            return `${year}-${nextMonth.toString().padStart(2, '0')}`;
        }
    }

    console.log(missingIntervals)

    useEffect(() => {
        const allObjs = DriverExperience.concat(DriverUnemployment)
        const startDateList = allObjs.map(obj => obj.start_date)
        const endDateList = allObjs.map(obj => obj.end_date)
        validateDatesCovered(startDateList, endDateList)
    }, [])

    return (
        <form>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop={5}
                flexDirection="column"
                minWidth="800px"
            >
                <Typography variant='h6'> Verify Work Experience and Unemployment.</Typography>


            </Box>

            <Grid container spacing={3} >
                <Grid item xs={12} md={12} >
                    <Typography mt={3} variant='h5'>Work Experience: </Typography>
                </Grid>
                <Grid item xs={12} md={12} >
                    {DriverExperience.map((item, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={item.company_name}
                                secondary={
                                    <>
                                        <Typography variant="body2" component="span">
                                            Start Date: {item.start_date && item.start_date.toString().slice(0, 15)}
                                        </Typography>
                                        <br />
                                        <Typography variant="body2" component="span">
                                            End Date: {item.end_date === '' ?
                                                'Currently Working Here'
                                                : item.end_date.toString().slice(0, 15)}
                                        </Typography>
                                    </>
                                }
                            />
                        </ListItem>
                    ))}
                </Grid>
                <Grid item xs={12} md={12} >
                    <Divider sx={{ borderBottomWidth: 1, backgroundColor: "grey" }} />
                </Grid>
                <Grid item xs={12} md={12} >
                    <Typography mt={3} variant='h5'>Unemployment: </Typography>
                    <Grid item xs={12} md={12} >
                        {DriverUnemployment.map((item, index) => (
                            <ListItem key={index}>
                                <ListItemText
                                    primary={`Period #${index + 1}`}
                                    secondary={
                                        <>
                                            <Typography variant="body2" component="span">
                                                Start Date: {item.start_date && item.start_date.toString().slice(0, 15)}
                                            </Typography>
                                            <br />
                                            <Typography variant="body2" component="span">
                                                End Date: {item.end_date && item.end_date.toString().slice(0, 15)}
                                            </Typography>
                                        </>
                                    }
                                />
                            </ListItem>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            {
                missingIntervals.length > 0 &&
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    marginTop={2}
                    flexDirection="column"
                >
                    <Typography color='red' mt={3} variant='body1'>Cannot continue please go back and provide employment/unemployment for following dates: </Typography>
                    {missingIntervals.map((interval, index) => (
                        <Typography key={index} color='red' variant='body1'>{`${interval}`}</Typography>

                    ))}
                </Box>

            }





        </form >

    )


}

export default VerifyExperience