// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
    sendEmailVerification,
    reauthenticateWithCredential,
    updateProfile
} from "firebase/auth";

import {
    getFirestore,
    collection,
    addDoc,
} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDvPuwCGdnvhcr_rP6pSlq6lDzGS_pIc18",
    authDomain: "transpoapp-28e83.firebaseapp.com",
    databaseURL: "https://transpoapp-28e83-default-rtdb.firebaseio.com",
    projectId: "transpoapp-28e83",
    storageBucket: "transpoapp-28e83.appspot.com",
    messagingSenderId: "601454695195",
    appId: "1:601454695195:web:0030f48024f02f5fe0b9be",
    measurementId: "G-M8VLNW4YY3"
};

const BASE_URL = process.env.REACT_APP_API_URL
console.log(BASE_URL)

async function getCompanyDetails(companyName) {
    const bearerToken = localStorage.getItem('bearerToken')
    console.log(`${BASE_URL}/company/${companyName}`)
    try {
        const response = await fetch(`${BASE_URL}/company/${companyName}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${bearerToken}`,
                'Content-Type': 'application/json', // Add this line if required
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error getting company data:', error);
        alert('Error Fetching User Data')
        throw error;
    }
}


async function sendAuthToBackend(token) {
    try {
        const response = await fetch(`${BASE_URL}/auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        localStorage.setItem('bearerToken', data.access_token);
        return data.access_token;

    } catch (error) {
        console.error('Error sending authentication to backend:', error);
        throw error;
    }
}


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);


const logInWithEmailAndPassword = async (email, password) => {
    try {
        const res = await signInWithEmailAndPassword(auth, email, password);
        const user = res.user;
        localStorage.setItem('name', user.displayName);
        localStorage.setItem('email', user.email);

        const token = await auth.currentUser.getIdToken();
        const authToken = await sendAuthToBackend(token);
        const companyData = await getCompanyDetails(user.displayName);
        localStorage.setItem('company_id', companyData.id)
    } catch (err) {
        console.error(err);
    }
};

const registerWithEmailAndPassword = async (email, password, superUser) => {

    try {
        const res = await createUserWithEmailAndPassword(auth, email, password);

        const user = res.user;

        await sendEmailVerification(user).then(() => {
            alert('Please Confirm Your Email Before Signing In.')
        })
        await logInWithEmailAndPassword(superUser.user, superUser.password)

    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

const logout = () => {
    localStorage.removeItem('bearerToken');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('company_id')

    signOut(auth);
};

export {
    auth,
    db,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
};