import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useSearchParams } from "react-router-dom";
import Paper from '@mui/material/Paper';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { TextField, InputAdornment } from '@mui/material';
import Iconify from '../../../../components/iconify';
import { putRequest } from '../../../../shared/apiCalls';

function listToDictionary(list) {
    const dictionary = {};
    for (let i = 0; i < list.length; i += 1) {
        dictionary[i] = list[i];
    }
    return dictionary;
}

export default function Comments({ driverDetails, setDriverDetails }) {

    const [showField, setShowField] = useState(false)
    const [newComment, setNewComment] = useState('')
    // Updates tasks if upload or schedule successful

    const handleDelete = async (index) => {
        try {
            const updatedComments = driverDetails.comments.splice(index, 0)
            const response = await toast.promise(putRequest(`driver/${driverDetails.id}`, {
                'comments': listToDictionary(updatedComments)
            }),
                {
                    pending: 'Removing Comment...',
                    success: 'Removed Comment',
                    error: 'Error Removing Comment'
                })
            setDriverDetails({
                ...driverDetails,
                'comments': updatedComments
            })
        } catch (error) {
            alert('An Error Occurred')
        }

    }

    const handleSubmit = async () => {
        // render comment space, don't allow for empty comments
        try {
            if (newComment.trim() !== "") {
                const updatedComments = [...driverDetails.comments, {
                    'date': new Date(),
                    'txt': newComment
                }]
                const response = await toast.promise(putRequest(`driver/${driverDetails.id}`, {
                    'comments': listToDictionary(updatedComments)

                }),
                    {
                        pending: 'Adding Comment...',
                        success: 'Added Comment',
                        error: 'Error Adding Comment'
                    })
                setDriverDetails({
                    ...driverDetails,
                    'comments': updatedComments
                })
            }
        } catch (error) {
            alert('An Error Occured')
        }
        setShowField(!showField)
        setNewComment("")
    }


    return (

        <Paper elevation={5} sx={{ mx: 4, pl: 2, pr: 2, py: 2, mb: 4 }} >
            <Grid container spacing={1} justifyContent="center" >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h5">Comments</Typography>

                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <List sx={{ width: '95%', bgcolor: 'background.paper' }}>

                        {driverDetails.comments.map((comment, index) => {
                            return (
                                <ListItem
                                    key={index}
                                    secondaryAction={
                                        <IconButton onClick={() => handleDelete(index)}  >
                                            <DeleteIcon fontSize="small" sx={{ color: "#B72136" }} />
                                        </IconButton>
                                    }
                                >
                                    {/* <ListItemText primary={capitalCase(comment.date.toString().slice(0, 24))}
                                        secondary={comment.txt} /> */}
                                    <ListItemText primary={(comment.date.toString().slice(0, 24))}
                                        secondary={comment.txt} />

                                </ListItem>

                            )
                        })}
                    </List>
                </Grid>
                {showField ?
                    <>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                name="new_comment"
                                label="Comment"
                                multiline
                                rows={4}
                                inputProps={{ maxLength: 250 }} // Example: set max length to 50 characters
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Typography color="textSecondary">{`${newComment.length}/250`}</Typography>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                            // style={{ width: 100 }}
                            />

                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Button variant="contained" fullWidth
                                pt={10} type='submit' onClick={handleSubmit}>Submit</Button>
                        </Grid>

                    </>
                    :
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button variant="contained" fullWidth startIcon={<Iconify icon="eva:plus-fill" />}
                            pt={10} type='submit' onClick={() => setShowField(!showField)} >New Comment</Button>

                    </Grid>
                }


            </Grid >
        </Paper >
    )




}