import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid, Typography } from '@mui/material';
import { InsuranceDetails } from '../sections/@dashboard/insurance';

export default function InsurancePage() {


  return (
    <>
      <Helmet>
        <title>
          TFS | Insurance
        </title>
      </Helmet>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="lg">
          <Typography
            sx={{ mb: 3 }}
            variant="h4"
          >
            Company Insurance
          </Typography>
          <Grid
            container
            spacing={3}
          >

            <Grid
              item
              lg={12}
              md={12}
              xs={12}
            >
              <InsuranceDetails />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
};