import { toast } from 'react-toastify';
import { logout } from "../firebase";


export const checkTimeOut = async (response) => {
    if (response.status === 401 && response.statusText === "UNAUTHORIZED") {
        logout()
        alert('Session Timed Out!')
    }
}

const BASE_URL = process.env.REACT_APP_API_URL
// add to each below if auth failed logout
export const postRequest = async (endPoint, data) => {
    // Get the bearer token from localStorage
    const bearerToken = localStorage.getItem('bearerToken');
    try {
        const response = await fetch(`${BASE_URL}/${endPoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearerToken}`,
            },
            body: JSON.stringify(data),
        });

        checkTimeOut(response)

        // send error.message if exists
        if (!response.ok) {
            const res = await response.json()
            console.error(res.error)
            throw new Error(`An Error Occured!(${response.status})`);
        }
        const result = await response.json()
        return result
    } catch (error) {
        toast.error(error.message)
        throw error;
    }
};

export const getRequest = async (endPoint) => {
    const bearerToken = localStorage.getItem('bearerToken');

    try {
        const response = await fetch(`${BASE_URL}/${endPoint}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${bearerToken}`,
            },
        });

        checkTimeOut(response)

        if (!response.ok) {
            const res = await response.json()
            console.error(`Problem: ${res.error}`)
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response
    } catch (error) {
        toast.error(error.message)
        throw error;
    }
}

export const getRequestDirectResponse = async (endPoint) => {
    const bearerToken = localStorage.getItem('bearerToken');

    try {
        const response = await fetch(`${BASE_URL}/${endPoint}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${bearerToken}`
            },
        });

        checkTimeOut(response)

        if (!response.ok) {
            const res = await response.json()
            console.error(`Problem: ${res.error}`)
            throw new Error('Network Response Failed');
        }

        return response;
    } catch (error) {
        console.error('Error Fetching Data: ', error);
        throw error;
    }
}

// add to each below if auth failed logout
export const postRequestFile = async (endPoint, data) => {
    // Get the bearer token from localStorage
    const bearerToken = localStorage.getItem('bearerToken');
    try {
        const response = await fetch(`${BASE_URL}/${endPoint}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${bearerToken}`,
            },
            body: data
        });

        checkTimeOut(response)

        if (!response.ok) {
            const res = await response.json()
            console.error(`Problem: ${res.error}`)
            throw new Error('Newtwork Response Failed')
        }
        const result = await response.json()

        return result
    } catch (error) {
        console.error('Error:', error.message);
        throw error;
    }
};

export const putRequest = async (endPoint, data) => {
    const bearerToken = localStorage.getItem('bearerToken');

    // Get the bearer token from localStorage
    try {
        const response = await fetch(`${BASE_URL}/${endPoint}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearerToken}`,
            },
            body: JSON.stringify(data),
        });

        checkTimeOut(response)

        if (!response.ok) {
            const res = await response.json()
            console.error(`Problem: ${res.error}`)
            throw new Error(`HTTP error! Status: ${response.status}`);
        }


        const result = await response.json()
        return result

    } catch (error) {
        toast.error(error.message)
        throw error;
    }
};