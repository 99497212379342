import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Container, Grid } from '@mui/material';
import Comments from '../sections/@dashboard/drivers/singleDriver/Comments';
import IncidentCard from '../sections/@dashboard/drivers/singleDriver/IncidentCard';
import Status from '../sections/@dashboard/drivers/singleDriver/Status';
import DisplayUpdates from '../sections/@dashboard/drivers/singleDriver/DisplayUpdates';
import DisplayPersonal from '../sections/@dashboard/drivers/singleDriver/DisplayPersonal';
import DisplayExperience from '../sections/@dashboard/drivers/singleDriver/DisplayExperience';
import DisplayAccidentList from '../sections/@dashboard/drivers/singleDriver/DisplayAccidentList';
import DisplayViolationList from '../sections/@dashboard/drivers/singleDriver/DisplayViolationList';
import DisplayGeneral from '../sections/@dashboard/drivers/singleDriver/DisplayGeneral';
import DisplayFiles from '../sections/@dashboard/drivers/singleDriver/DisplayFiles';
import LoadingDisplay from './LoadingDisplay';
import ErrorPage from './ErrorPage';
import { getRequest } from '../shared/apiCalls';

const SingleDriverPage = () => {
    const { id } = useParams()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [driverDetails, setDriverDetails] = useState(null)

    useEffect(() => {
        const getDriverInfo = async () => {
            try {
                const response = await getRequest(`driver/${id}`)

                const result = await response.json()

                const accidentList = Object.keys(result.accidents).map(
                    key => ({
                        ...result.accidents[key],
                        'date': new Date(result.accidents[key].date)
                    })
                )

                const commentList = Object.keys(result.comments).map(
                    key => ({
                        ...result.comments[key],
                        'date': new Date(result.comments[key].date)
                    })
                )

                const experienceList = Object.keys(result.work_experience).map(
                    key => ({
                        ...result.work_experience[key],
                        'end_date': new Date(result.work_experience[key].end_date),
                        'start_date': new Date(result.work_experience[key].start_date)
                    })
                )
                const violationList = Object.keys(result.violations).map(
                    key => ({
                        ...result.violations[key],
                        'date': new Date(result.violations[key].date)
                    })
                )
                setDriverDetails({
                    ...result,
                    'abstract_retrieved': result.abstract_retrieved && new Date(result.abstract_retrieved.slice(0, -6)),
                    'accidents': accidentList,
                    'birthday': new Date(result.birthday),
                    'comments': commentList,
                    'cvor_retrieved': result.cvor_retrieved && new Date(result.cvor_retrieved.slice(0, -6)),
                    'drug_test_date': result.drug_test_date && new Date(result.drug_test_date.slice(0, -6)),
                    'last_update': new Date(result.last_update.slice(0, -6)),
                    'license': result.license ?
                        {
                            ...result.license,
                            'expiry': new Date(result.license.expiry)
                        } : null,
                    'offer_letter_date': result.offer_letter_date && new Date(result.offer_letter_date.slice(0, -6)),
                    'work_experience': experienceList,
                    'violations': violationList
                })

                setLoading(false)
                setError(false)
            } catch (error) {
                console.error(error)
                setError(true)
            }
        }
        getDriverInfo()
    }, [])

    if (error) {
        return <ErrorPage />
    }

    return (
        <>
            {loading ?
                <LoadingDisplay />
                :
                <Container maxWidth="lg" style={{ marginTop: '20px' }}>
                    <Grid container  >
                        {driverDetails.status === 'hired' ? <Grid item xs={8} s={8} md={8} marginBottom={2}>
                            <DisplayUpdates DriverDetails={driverDetails} />
                        </Grid>
                            : <></>
                        }
                        <Grid item xs={12} s={8} md={8} lg={8} xl={8} marginBottom={2}>
                            <Grid container spacing={3}>
                                {/* Steps Completed */}
                                <Grid item xs={12}>
                                    <Status DriverDetails={driverDetails} setDriverDetails={setDriverDetails} />
                                </Grid>
                                <DisplayPersonal driverDetails={driverDetails} setDriverDetails={setDriverDetails} />
                                <Grid item xs={12} md={12}>
                                    <DisplayFiles DriverDetails={driverDetails} setDriverDetails={setDriverDetails} />
                                </Grid>
                                <DisplayExperience workExperience={driverDetails.work_experience} />
                                <DisplayAccidentList accidents={driverDetails.accidents} />
                                <DisplayViolationList violations={driverDetails.violations} />
                                <DisplayGeneral DriverDetails={driverDetails} />

                            </Grid>

                        </Grid>
                        <Grid item xs={12} s={4} md={4} lg={4} xl={4} >
                            {(driverDetails.status !== 'new_applicant' && driverDetails.status !== 'in_progress') &&
                                <IncidentCard driverDetails={driverDetails} setDriverDetails={setDriverDetails} />}
                            {/* 'new_applicant',
    'in_progress' */}
                            <Comments driverDetails={driverDetails} setDriverDetails={setDriverDetails} />

                        </Grid>
                    </Grid>


                </Container>}
        </>
    );
};

export default SingleDriverPage;
