import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Dialog, DialogActions, DialogContent } from '@mui/material';

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { postRequestFile, getRequestDirectResponse } from '../../../../shared/apiCalls';
import AddFiles from '../application_components/AddFiles';

/* function to add new task to firestore */
const DisplayFiles = ({ DriverDetails, setDriverDetails }) => {
    const initState = {
        'licenseFile': '',
        'cvorFile': '',
        'abstractFile': '',
        'drugTestFile': '',
        'offerLetterFile': '',
        'license': {
            'country': 'Canada',
            'province_state': 'ON - Ontario',
            'num': '',
            'expiry': null,
        },
        'cvor_retrieved': null,
        'abstract_retrieved': null,
        'drug_test_date': null,
        'offer_letter_date': null
    }
    const [tempDriverFiles, setTempDriverFiles] = useState(initState)
    const [saveDisabled, setSaveDisabled] = useState(false)
    const [uploadFile, setUploadFile] = useState(false)

    const handleClose = () => {
        setTempDriverFiles(initState)
        setUploadFile(false)
    }

    const handleUploadFiles = async () => {
        // upload docs 
        const data = {}
        const dates = ['cvor_retrieved', 'abstract_retrieved', 'drug_test_date', 'offer_letter_date']
        const files = ['licenseFile', 'cvorFile', 'abstractFile', 'drugTestFile', 'offerLetterFile']
        dates.forEach(key => {
            if (tempDriverFiles[key] !== null) {
                data[key] = tempDriverFiles[key].toISOString()
            }
        })
        if (tempDriverFiles.license.expiry !== null) {
            data.license = {
                ...tempDriverFiles.license,
                'expiry': tempDriverFiles.license.expiry.toISOString()
            }
        }


        // tempDriverFiles
        try {
            const formData = new FormData();
            formData.append('driverFilesData', JSON.stringify(data));
            files.forEach(key => {
                if (tempDriverFiles[key] !== '') {
                    formData.append(key, tempDriverFiles[key])
                }
            })
            const response = await toast.promise(postRequestFile(
                `driverdocuments/${DriverDetails.id}`,
                formData
            ), {
                pending: 'Submitting Files...',
                success: 'Files Submitted',
                error: 'Error Submitting Files'
            })
            setDriverDetails(prevDetails => ({
                ...prevDetails,
                ...(tempDriverFiles.abstractFile !== '' && { 'abstract_retrieved': tempDriverFiles.abstract_retrieved }),
                ...(tempDriverFiles.cvorFile !== '' && { 'cvor_retrieved': tempDriverFiles.cvor_retrieved }),
                ...(tempDriverFiles.licenseFile !== '' && { 'license': tempDriverFiles.license }),
                ...(tempDriverFiles.drugTestFile !== '' && { 'drug_test_date': tempDriverFiles.drug_test_date })
            }));
            handleClose()
        } catch (err) {
            console.error('Error:', err)
        }
    }

    const licenseUploaded = !!DriverDetails.license
    const cvorUploaded = !!DriverDetails.cvor_retrieved
    const abstractUploaded = !!DriverDetails.abstract_retrieved
    const drugTestUploaded = !!DriverDetails.drug_test_date
    const offerLetterUploaded = !!DriverDetails.offer_letter_date

    // add arguements to get file
    const viewFile = async (fileType) => {
        try {
            const response = await getRequestDirectResponse(
                `driverdocuments/${DriverDetails.id}/${fileType}`
            )
            const blob = await response.blob();
            const fileUrl = URL.createObjectURL(blob);
            window.open(fileUrl, "_blank");
        } catch (error) {
            console.error("Error fetching or opening file:", error);
        }
    };
    useEffect(() => {
        // change so only upload one file if want
        if (tempDriverFiles.licenseFile === '' && tempDriverFiles.cvorFile === '' &&
            tempDriverFiles.abstractFile === '' && tempDriverFiles.drugTestFile === '' &&
            tempDriverFiles.offerLetterFile === '') {
            setSaveDisabled(true)
        } else if ((tempDriverFiles.licenseFile !== '' &&
            (tempDriverFiles.license.expiry === '' || tempDriverFiles.license.num === ''))
            || (tempDriverFiles.cvorFile !== '' && !tempDriverFiles.cvor_retrieved)
            || (tempDriverFiles.abstractFile !== '' && !tempDriverFiles.abstract_retrieved)
            || (tempDriverFiles.drugTestFile !== '' && !tempDriverFiles.drug_test_date)
            || (tempDriverFiles.offerLetterFile !== '' && !tempDriverFiles.offer_letter_date)) {
            setSaveDisabled(true)
        } else {
            setSaveDisabled(false)
        }

    }, [tempDriverFiles])
    return (
        <>
            <Paper elevation={3} style={{ padding: '20px' }}>
                <Grid container spacing={3} justifyContent="center" >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography display="inline" variant="h5" gutterBottom>
                            Files
                            <Button type='button' onClick={() => setUploadFile(true)} >
                                Upload
                            </Button>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ListItem id="license-file"
                            secondaryAction={
                                <Button
                                    disabled={!licenseUploaded}
                                    onClick={() => viewFile('license')}>
                                    View File
                                </Button>
                            }>
                            <ListItemText
                                disableTypography
                                primary={
                                    <>

                                        <Typography color={"common.black"} variant="h6">License</Typography>
                                        {DriverDetails.license ?
                                            <>
                                                <Typography variant='body2'><strong>License Num:</strong> {DriverDetails.license.num}</Typography>
                                                <Typography variant='body2'><strong>Country:</strong> {DriverDetails.license.country}</Typography>
                                                <Typography variant='body2'><strong>Prov/State:</strong> {DriverDetails.license.province_state}</Typography>
                                                <Typography variant='body2'><strong>Expiry:</strong> {DriverDetails.license.expiry.toString().slice(0, 16)}</Typography>
                                            </> :
                                            'Not Available'
                                        }
                                    </>
                                }
                            />
                        </ListItem>
                        <ListItem id="cvor-file"
                            secondaryAction={
                                <>
                                    <Button
                                        disabled={!cvorUploaded}
                                        onClick={() => viewFile('cvor')}>
                                        View File
                                    </Button>
                                    |
                                    <Button component="label" onClick={() => {
                                        toast.info(`Feature Coming Soon!`, {
                                            position: toast.POSITION.TOP_CENTER
                                        });
                                    }
                                    } >
                                        Order
                                    </Button>
                                </>
                            }>
                            <ListItemText
                                disableTypography
                                primary={
                                    <>
                                        <Typography color={"common.black"} variant="h6">CVOR</Typography>
                                        {DriverDetails.cvor_retrieved ? <Typography variant='body2'>
                                            <strong>Date Retrieved:</strong> {DriverDetails.cvor_retrieved.toString().slice(0, 16)}
                                        </Typography>
                                            : "Not Available"
                                        }

                                    </>
                                }
                            />
                        </ListItem>
                        <ListItem id="abstract-file"
                            secondaryAction={
                                <>
                                    <Button
                                        disabled={!abstractUploaded}
                                        onClick={() => viewFile('abstract')}>
                                        View File
                                    </Button>
                                    |
                                    <Button component="label" onClick={() => {
                                        toast.info(`Feature Coming Soon!`, {
                                            position: toast.POSITION.TOP_CENTER
                                        });
                                    }
                                    } >
                                        Order
                                    </Button>
                                </>
                            }>
                            <ListItemText
                                disableTypography
                                primary={
                                    <>
                                        <Typography color={"common.black"} variant="h6">Abstract</Typography>
                                        {DriverDetails.abstract_retrieved ?
                                            <Typography variant='body2'>
                                                <strong>Date Retrieved:</strong> {DriverDetails.abstract_retrieved.toString().slice(0, 16)}</Typography>
                                            : "Not Available"
                                        }
                                    </>
                                }
                            />
                        </ListItem>

                        <ListItem id="drug-test-file"
                            secondaryAction={
                                <Button
                                    disabled={!drugTestUploaded}
                                    onClick={() => viewFile('drug_test')}>
                                    View File
                                </Button>
                            }>
                            <ListItemText
                                disableTypography
                                primary={
                                    <>
                                        <Typography color={"common.black"} variant="h6">Drug Test</Typography>
                                        {DriverDetails.drug_test_date ?
                                            <Typography variant='body2'>
                                                <strong>Date Uploaded:</strong> {DriverDetails.drug_test_date.toString().slice(0, 16)}</Typography>
                                            : "Not Available"
                                        }

                                    </>

                                }
                            />
                        </ListItem>
                        <ListItem id="offer-letter-file"
                            secondaryAction={
                                <Button
                                    disabled={!offerLetterUploaded}
                                    onClick={() => viewFile('offer_letter')}>
                                    View File
                                </Button>
                            }>
                            <ListItemText
                                disableTypography
                                primary={
                                    <>
                                        <Typography color={"common.black"} variant="h6">Offer Letter</Typography>
                                        {DriverDetails.offer_letter_date ?
                                            <Typography variant='body2'>
                                                <strong>Date Uploaded:</strong> {DriverDetails.offer_letter_date.toString().slice(0, 16)}</Typography>
                                            : "Not Available"
                                        }


                                    </>
                                }
                            />
                        </ListItem>


                    </Grid>
                </Grid>


            </Paper>
            {uploadFile &&
                <Dialog
                    onClose={handleClose} open={uploadFile}
                    aria-labelledby="form-dialog"
                    maxWidth='md'
                    fullWidth
                >

                    <DialogContent>
                        <AddFiles DriverFiles={tempDriverFiles} setDriverFiles={setTempDriverFiles}
                            setAllowNext={() => { }} update />
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary"
                            disabled={saveDisabled}
                            variant="contained" onClick={handleUploadFiles}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    )


}

export default DisplayFiles;