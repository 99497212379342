import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Grid, Button, Container, Stack } from '@mui/material';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { capitalCase } from 'change-case';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import AddPosting from '../sections/@dashboard/posting/AddPosting';
import Iconify from '../components/iconify';
import { getRequest } from '../shared/apiCalls';
import LoadingDisplay from './LoadingDisplay';


export default function PostingsManager() {
  const [openAddModal, setOpenAddModal] = useState(false)
  const navigate = useNavigate();
  const [postings, setPostings] = useState([])
  const [loading, setLoading] = useState(true)

  const getAllPostings = async () => {
    try {
      const response = await getRequest(`jobpostings`)
      const result = await response.json()
      setPostings(result.data)
      setLoading(false)
    } catch (error) {
      console.error('Error:', error.status);
      alert('An Error Occured! Please try again!')
    }
  }

  const handleOnClose = () => {
    setLoading(true)
    getAllPostings()
    setOpenAddModal(false)

  }


  useEffect(() => {
    getAllPostings()
  }, [])


  const jobPage = (id) => {
    navigate(`/dashboard/jobpostings/${id}`);
  }

  return (

    <>
      <Helmet>
        <title> TFS | Postings  </title>
      </Helmet>

      {
        loading ?
          <LoadingDisplay /> :
          <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Job Postings
              </Typography>
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => setOpenAddModal(true)}>
                New Posting
              </Button>
            </Stack>
            <Grid container spacing={3} justifyContent="center">
              {postings.length === 0 ?
                <Stack direction="row" alignItems="center" mb={5}>
                  <Typography variant="h6" gutterBottom>
                    No Job Postings
                  </Typography>

                </Stack>
                :
                postings.map((value, index) => {
                  return (
                    <Grid key={index} item xs={10} sm={10} md={10} lg={10} xl={10}>

                      <Box sx={{ width: '100%', bgcolor: 'background.paper', pl: 2, pr: 2, py: 2 }} >
                        <ListItem
                          key={index}

                          secondaryAction={
                            <Stack >

                              <Typography component="div" sx={{ fontWeight: 'bold' }} color={"common.black"}>
                                {"Start Date: "} <Typography style={{ display: 'inline-block' }} color={'grey'} >
                                  {value && value.posting_end_date && value.posting_end_date.toString().slice(0, 10)}                              </Typography>
                              </Typography>
                              <Typography component="div" sx={{ fontWeight: 'bold' }} color={"common.black"}>
                                {"End Date: "} <Typography style={{ display: 'inline-block' }} color={'grey'} >
                                  {value && value.posting_end_date && value.posting_end_date.toString().slice(0, 10)}
                                </Typography>
                              </Typography>

                            </Stack>
                          }
                        >

                          <ListItemText
                            disableTypography
                            primary={
                              <>
                                <Typography color={"common.black"} variant="h6">{capitalCase(value.job_title)}<Button onClick={() => jobPage(value.id)}>Update </Button></Typography>
                                <Typography color={'grey'}>{capitalCase(value.job_location)}</Typography>

                              </>}
                          />
                        </ListItem>
                        <Divider />

                        <Stack >

                          <Typography component="div" sx={{ fontWeight: 'bold' }} color={"common.black"}>
                            {"ID: "} <Typography style={{ display: 'inline-block' }}>
                              {value.id}
                            </Typography>
                          </Typography>
                          <Typography component="div" sx={{ fontWeight: 'bold' }} color={"common.black"}>
                            {"URL: "} <Typography style={{ display: 'inline-block' }}><Link href={`${process.env.REACT_APP_URL}/jobs/${value.company_name}/${value.id}`} underline="hover">
                              {`${process.env.REACT_APP_URL}/jobs/${value.company_name}/${value.id}`}
                            </Link></Typography>
                          </Typography>
                          <Typography component="div" sx={{ fontWeight: 'bold' }} color={"common.black"}>
                            {"Last Updated: "} <Typography style={{ display: 'inline-block' }} color={'grey'} >{value.last_update.toString().slice(0, 16)}</Typography>
                          </Typography>

                        </Stack>


                        {/* </Paper> */}</Box>
                    </Grid>
                  )
                })}


            </Grid >
          </Container >}
      {
        openAddModal &&
        <AddPosting onClose={handleOnClose} open={openAddModal} />
      }
    </>
  );
}
