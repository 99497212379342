import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import {
    Stack,
    Grid,
    Button,
    Container,
    Typography,
} from '@mui/material';
import { capitalCase } from "change-case";
import AddForm from "../sections/@dashboard/drivers/AddForm"
import Iconify from '../components/iconify';
import Label from '../components/label';
import LoadingDisplay from "./LoadingDisplay";
import ErrorPage from "./ErrorPage"
import { getRequest } from "../shared/apiCalls";

function DriverManager() {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [allDrivers, setAllDrivers] = useState([])
    useEffect(() => {
        const getAllDrivers = async () => {
            try {
                const response = await getRequest('driver')
                const result = await response.json()
                setAllDrivers(result.data)
                setLoading(false)
                setError(false)
            } catch (error) {
                setError(true)
            }
        }
        getAllDrivers()
    }, [])
    const [openAddModal, setOpenAddModal] = useState(false)
    const navigate = useNavigate();

    const personalPage = (id) => {
        navigate(`/dashboard/drivers/${id}`)
    }


    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: {
                display: false,
                filter: false,

            }
        },
        {
            name: 'last_update',
            label: 'Last Update',
            options: {
                filter: false,
                display: false,
                customBodyRenderLite: (dataIndex) => {
                    const d = allDrivers[dataIndex].last_update
                    return (
                        <>
                            {d.toString().slice(0, 25)}
                        </>
                    )
                },
            }
        },
        {
            name: 'first_name',
            label: 'First name',
        },
        {
            name: 'last_name',
            label: 'Last name',
        },
        {
            name: 'email',
            label: 'Email',
        },
        {
            name: 'phone',
            label: 'Phone #',
        },
        {
            name: 'driver_type',
            label: 'Job Type',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const driver = allDrivers[dataIndex].driver_type
                    return (
                        <>
                            {capitalCase(driver)}
                        </>
                    )
                },
            }
        },
        {
            name: 'region',
            label: 'Region',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const region = allDrivers[dataIndex].region
                    return (
                        <>
                            {capitalCase(region)}
                        </>
                    )
                },
            }
        },
        {
            name: 'job_app_id',
            label: 'Job Posting Id',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const id = allDrivers[dataIndex].job_app_id
                    return (
                        <>
                            {id ?
                                id
                                : '-'
                            }
                        </>
                    )
                },

            }
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                customBodyRenderLite: (dataIndex) => {
                    const status = allDrivers[dataIndex].status
                    return (
                        <Label color={(status === 'fired' && 'error') || (status === 'inactive' && 'warning')
                            || (status === 'rejected' && 'error') || (status === 'update_required' && 'warning')
                            || (status === 'hired' && 'default') || 'primary'}>
                            {capitalCase(status)}
                        </Label>
                    )
                },

            }

        },
    ];

    const options = {
        filter: true,
        selectableRows: "multiple",
        filterType: "dropdown",
        responsive: "simple",
        rowsPerPage: 10,
        tableBodyMaxHeight: '600',
        sortOrder: {
            name: 'last_update',
            direction: 'desc'
        },
        onRowsDelete: () => {
            console.log('Delete')
            return false
        },
        onRowClick: (rowData) => { personalPage(rowData[0]) }
    }

    if (error) {
        return <ErrorPage />

    }

    return (

        <>

            <Helmet>
                <title> TFS | Drivers </title>
            </Helmet>
            {loading ?
                <LoadingDisplay />
                :

                <Container>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Driver list
                        </Typography>
                        <Button variant="contained" onClick={() => setOpenAddModal(true)}
                            startIcon={<Iconify icon="eva:plus-fill" />}>
                            Add Driver
                        </Button>
                    </Stack>

                    <Grid container spacing={3}>

                        <Grid item xs={12} lg={12} >
                            <MUIDataTable
                                columns={columns}
                                data={allDrivers}
                                options={options}
                            />

                        </Grid>
                        {openAddModal &&
                            <AddForm onClose={() => setOpenAddModal(false)} open={openAddModal} />}

                    </Grid>

                </Container>
            }
        </>
    )
}

export default DriverManager