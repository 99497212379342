import TextField from '@mui/material/TextField';
import { Button, ListItem, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';


function HireSteps({ values, setValues }) {


    const addItem = () => {
        // add limit of 5
        const updatedExperience = [...values.hiring_steps, '']
        setValues({
            ...values,
            'hiring_steps': updatedExperience
        })

    }

    const deleteItem = (index) => {
        const updatedExperience = values.hiring_steps.filter((_, i) => i !== index);
        setValues({
            ...values,
            'hiring_steps': updatedExperience
        })
    }

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedExperience = [...values.hiring_steps]
        updatedExperience[index] = value
        setValues({
            ...values,
            'hiring_steps': updatedExperience
        })

    };



    return (
        <>            {values.hiring_steps.map((hireStep, index) => {
            return (
                <ListItem
                    key={index}
                    secondaryAction={
                        index !== 0 ?
                            <IconButton color='error' onClick={() => deleteItem(index)}>
                                <DeleteIcon />
                            </IconButton>
                            :
                            <></>
                    }
                >
                    <TextField fullWidth
                        label={`Step ${index}`}
                        name={`step_${index}`}
                        value={hireStep}
                        required
                        variant="outlined"
                        onChange={(e) => handleChange(e, index)} />
                </ListItem>
            )
        })}
            {values.hiring_steps.length < 10 ?
                <Box
                    display="flex"
                    alignItems="left"
                    justifyContent="left"
                    marginTop={3}
                >
                    < Button variant="outlined" onClick={() => addItem()}>
                        Add Step
                    </Button >
                </Box>
                : <></>
            }
        </>

    )


}

export default HireSteps