import { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Radio, FormControlLabel, RadioGroup, FormControl, Typography, InputAdornment } from '@mui/material';

function CriminalRecord({ DriverRecord, setDriverRecord }) {
    const yesValue = true
    const noValue = false

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'charges' || name === 'misdemeanours' || name === 'collect_info') {
            if (value === 'true') {
                setDriverRecord((prevData) => ({
                    ...prevData, [name]: true
                }))
            } else {
                setDriverRecord((prevData) => ({
                    ...prevData, [name]: false
                }))
            }
        } else {
            setDriverRecord((prevData) => ({
                ...prevData, [name]: value
            }))
        }


    };

    return (
        <form>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop={5}
                marginBottom={3}
            >
                <Typography variant='h6'>Provide accurate information about your criminal record. </Typography>


            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} mt={2}>
                    <FormControl component="fieldset">
                        <Typography variant='subtitle'>*Have you ever been convicted or have any criminal charges pending?</Typography>
                        <RadioGroup
                            row
                            name="charges"
                            value={DriverRecord.charges}
                            onChange={(e) => handleChange(e)}>
                            <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                            <FormControlLabel value={noValue} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>


                {DriverRecord.charges === true ?
                    <Grid item xs={12} md={8} >
                        <Typography variant='subtitle'>*Please provide the details regarding your charges.</Typography>
                        <TextField
                            name="charges_detail"
                            inputProps={{ maxLength: 150 }}
                            required={DriverRecord.charges === true}
                            value={DriverRecord.charges_detail}
                            onChange={(e) => handleChange(e)}
                            fullWidth
                            multiline
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography color="textSecondary">{`${DriverRecord.charges_detail.length}/150`}</Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    :
                    <></>
                }
                <Grid item xs={12} md={9}>
                    <FormControl component="fieldset">
                        <Typography variant='subtitle'>*Have you ever been convicted or have any misdemeanours pending?</Typography>
                        <RadioGroup
                            row
                            name="misdemeanours"
                            value={DriverRecord.misdemeanours}
                            onChange={(e) => handleChange(e)}>
                            <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                            <FormControlLabel value={noValue} control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>


                {DriverRecord.misdemeanours === true ?
                    <Grid item xs={12} md={8} >
                        <Typography variant='subtitle'>*Please provide the details regarding your misdemeanours.</Typography>
                        <TextField

                            required={DriverRecord.misdemeanours === true}
                            name="misdemeanours_detail"
                            value={DriverRecord.misdemeanours_detail}
                            onChange={(e) => handleChange(e)}
                            fullWidth
                            multiline
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography color="textSecondary">{`${DriverRecord.misdemeanours_detail.length}/150`}</Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    :
                    <></>
                }
                <Grid item xs={12} md={8} >
                    <FormControl component="fieldset">
                        <Typography variant="subtitle">*Do you consent to the collection of data of your criminal record?</Typography>
                        <RadioGroup
                            row
                            name="collect_info"
                            value={DriverRecord.collect_info}
                            onChange={(e) => handleChange(e)}
                            required
                        >
                            <FormControlLabel value={yesValue} control={<Radio />} label="Yes" />
                            <FormControlLabel value={noValue} control={<Radio />} label="No" />
                        </RadioGroup>

                    </FormControl>

                </Grid>
            </Grid>

        </form>

    )


}

export default CriminalRecord