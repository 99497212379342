import { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, Divider, IconButton, ListItem, Typography } from '@mui/material';
import {
    countries, CanadaProvinces, USAStates, MexicoStates
} from '../../../../utils/dropOptions';
/* function to add new task to firestore */

function AddFiles({ DriverFiles, setDriverFiles, setAllowNext, update = false }) {

    useEffect(() => {
        if (DriverFiles.licenseFile === '' && DriverFiles.cvorFile === '' &&
            DriverFiles.abstractFile === '') {
            setAllowNext(false)
        } else {
            setAllowNext(true)
        }

    }, [DriverFiles])

    const handleDateChange = (type, e) => {
        if (e == null) {
            alert('Please Select Valid Date')
        } else {
            setDriverFiles({
                ...DriverFiles,
                [type]: e,
            });

        }
    }

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        const maxFileSize = 5 * 1024 * 1024 // 5 mb
        if (files[0].size > maxFileSize) {
            alert('File Exceed Max Size Allowed!')
            e.target.value = ''
        } else {
            setDriverFiles({
                ...DriverFiles,
                [name]: files[0],
            });
        }

    };


    const handleRemoveFile = (name) => {
        setDriverFiles({
            ...DriverFiles,
            [name]: '',
        });
        const fileInput = document.getElementById(name);
        fileInput.value = null;

    }

    return (
        <form >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop={5}
                marginBottom={3}
            >
                <Typography variant='h6'>Provide information for following files. </Typography>


            </Box>
            <Grid container spacing={3} paddingX={2}>

                <Grid item xs={12} md={8}>
                    <Typography variant='h6'>Driver's License:</Typography>
                    <InputLabel>Attach License *(.pdf, .jpg)</InputLabel>

                    <TextField
                        type="file"
                        id="licenseFile"
                        name="licenseFile"
                        onChange={(e) => handleFileChange(e)}
                        required
                        inputProps={{ accept: ".pdf, image/*" }}
                    />
                    {DriverFiles.licenseFile ?
                        <IconButton color='error' onClick={() => handleRemoveFile('licenseFile')}>
                            <DeleteIcon />
                        </IconButton>
                        : <></>
                    }

                </Grid>

                <Grid item md={12}>
                    <Divider sx={{ borderBottomWidth: '4px' }} />
                </Grid>

                <Grid item xs={12} md={8}>
                    <Typography variant='h6'>Latest CVOR:</Typography>

                    <InputLabel>Attach CVOR *(.pdf, .jpg)</InputLabel>

                    <TextField
                        type="file"
                        id="cvorFile"
                        name="cvorFile"
                        onChange={handleFileChange}
                        required
                        inputProps={{ accept: ".pdf, image/*" }}
                    />
                    {DriverFiles.cvorFile ?
                        <IconButton color='error' onClick={() => handleRemoveFile('cvorFile')}>
                            <DeleteIcon />
                        </IconButton>
                        : <></>
                    }
                    <Grid container spacing={3} paddingX={10} mt={1}>

                        <Grid item xs={12} md={8}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    required
                                    name='cvor_retrieved'
                                    label={'Date Retrieved'}
                                    value={DriverFiles.cvor_retrieved}
                                    onChange={(e) => handleDateChange('cvor_retrieved', e)}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item md={12}>
                    <Divider sx={{ borderBottomWidth: '4px' }} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Typography variant='h6'>Latest Abstract:</Typography>
                    <InputLabel>Attach Abstract *(.pdf, .jpg)</InputLabel>

                    <TextField
                        type="file"
                        id="abstractFile"
                        name="abstractFile"
                        onChange={handleFileChange}
                        required
                        inputProps={{ accept: ".pdf, image/*" }}
                    />
                    {DriverFiles.abstractFile ?
                        <IconButton color='error' onClick={() => handleRemoveFile('abstractFile')}>
                            <DeleteIcon />
                        </IconButton>
                        : <></>
                    }
                    <Grid container spacing={3} paddingX={10} mt={1}>

                        <Grid item xs={12} md={8}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    required
                                    name='abstract_retrieved'
                                    label={'Date Retrieved'}
                                    value={DriverFiles.abstract_retrieved}
                                    onChange={(e) => handleDateChange('abstract_retrieved', e)}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>

                </Grid>
                {update &&
                    <>
                        <Grid item md={12}>
                            <Divider sx={{ borderBottomWidth: '4px' }} />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography variant='h6'>Latest Drug Test:</Typography>
                            <InputLabel>Attach Drug Test (.pdf, .jpg)</InputLabel>

                            <TextField
                                type="file"
                                id="drugTestFile"
                                name="drugTestFile"
                                onChange={handleFileChange}
                                required
                                inputProps={{ accept: ".pdf, image/*" }}
                            />
                            {DriverFiles.drugTestFile ?
                                <IconButton color='error' onClick={() => handleRemoveFile('drugTestFile')}>
                                    <DeleteIcon />
                                </IconButton>
                                : <></>
                            }
                            <Grid container spacing={3} paddingX={10} mt={1}>

                                <Grid item xs={12} md={8}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            required
                                            name='drug_test_date'
                                            label={'Date of Test'}
                                            value={DriverFiles.drug_test_date}
                                            onChange={(e) => handleDateChange('drug_test_date', e)}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item md={12}>
                            <Divider sx={{ borderBottomWidth: '4px' }} />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography variant='h6'>Offer Letter:</Typography>
                            <InputLabel>Attach Offer Letter (.pdf, .jpg)</InputLabel>

                            <TextField
                                type="file"
                                id="offerLetterFile"
                                name="offerLetterFile"
                                onChange={handleFileChange}
                                required
                                inputProps={{ accept: ".pdf, image/*" }}
                            />
                            {DriverFiles.offerLetterFile ?
                                <IconButton color='error' onClick={() => handleRemoveFile('offerLetterFile')}>
                                    <DeleteIcon />
                                </IconButton>
                                : <></>
                            }
                            <Grid container spacing={3} paddingX={10} mt={1}>

                                <Grid item xs={12} md={8}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            required
                                            name='offer_letter_date'
                                            label={'Date Letter Signed'}
                                            value={DriverFiles.offer_letter_date}
                                            onChange={(e) => handleDateChange('offer_letter_date', e)}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>

                        </Grid>
                    </>
                }
            </Grid>
        </form>

    )


}

export default AddFiles