import { useState } from 'react';
import { Button, Container, Box, Grid, Typography, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PersonalInfo from './application_components/PersonalInfo';
import Education from './application_components/Education';
import WorkExperience from './application_components/WorkExperience';
import RecentAccident from './application_components/RecentAccident';
import TrafficViolation from './application_components/TrafficViolation';
import CriminalRecord from './application_components/CriminalRecord';
import GeneralInfo from './application_components/GeneralInfo';
import AddFiles from './application_components/AddFiles';
import ReviewForm from './application_components/ReviewForm';
import { isEmailValid, isPhoneNumberValid, isSinValid } from '../../../shared/dataValidation';
import { postRequest, postRequestFile } from '../../../shared/apiCalls';
import CriteriaToHire from './application_components/CriteriaToHire';
import { capitalCase } from 'change-case';
import Unemployment from './application_components/Unemployment';
import VerifyExperience from './application_components/VerifyExperience';
import FMCSRQuestions from './application_components/FMCSRQuestions';
import ConsentForms from './application_components/ConsentForms';
import SignConsentForms from './application_components/SignConsentForms';


function isBetweenZeroAndSix(value) {
  return value >= 0 && value <= 6;
}

function isBetweenOneAndSeven(value) {
  return value >= 1 && value <= 7;
}

function listToDictionary(list) {
  const dictionary = {};
  for (let i = 0; i < list.length; i += 1) {
    dictionary[i] = list[i];
  }
  return dictionary;
}

function DriverApplication({ onClose, companyName = '', jobPostId = -1 }) {
  const today = new Date();
  const eighteenYearsAgo = new Date(today);
  eighteenYearsAgo.setFullYear(today.getFullYear() - 24);
  const [id, setID] = useState(-1)
  const [allowNext, setAllowNext] = useState(true);
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  if (jobPostId === -1) {
    companyName = localStorage.getItem('name')
  }

  const validateObj = (keys, data) => {
    const displayMsg = []
    keys.forEach((key) => {
      if (key === 'address') {
        if (data[key].street.trim() === '') {
          displayMsg.push('Street Address')
        }
        if (data[key].city.trim() === '') {
          displayMsg.push('City')
        }
        if (data[key].province_state.trim() === '') {
          displayMsg.push('Province/State')
        }
        if (data[key].postal_zip.trim() === '') {
          displayMsg.push('Postal Code/Zip Code')
        }
      } else if (key === 'license') {
        if (data[key].expiry === null) {
          displayMsg.push('License Expiry')
        }
        if (data[key].num === '') {
          displayMsg.push('License Number')
        }
      } else if (key === 'can_contact' && data[key] === false) {
        displayMsg.push('Contact by SMS, phone and/or email')
      } else if (key === 'phone' && !isPhoneNumberValid(data[key])) {
        displayMsg.push(capitalCase(key))
      } else if (key === 'email' && !isEmailValid(data[key])) {
        displayMsg.push(capitalCase(key))
      } else if (key === 'sin' && !isSinValid(data[key])) {
        displayMsg.push(`${capitalCase(key)} Must be 9 digits`)
      } else if (data[key] === null || data[key] === '' || data[key] === false) {
        displayMsg.push(capitalCase(key))
      }
    })
    return displayMsg
  }


  const [DriverDetails, setDriverDetails] = useState({
    'first_name': '',
    'last_name': '',
    'birthday': eighteenYearsAgo,
    'address': {
      'country': 'Canada',
      'street': '',
      'city': '',
      'province_state': 'ON - Ontario',
      'postal_zip': ''
    },
    'status': 'new_applicant',
    'email': '',
    'phone': '',
    'sin': '',
    'last_update': new Date(),
    'can_contact': false,
    'privacy_policy': false,
    'terms_conditions': false,
    'valid_data': null,
    'signature_certify': {
      'data': '',
      'date': ''// backend
    },
    'consent_forms': {
      'disclosure': false,
      'psp': false,
      'clearing_house': false,
      'prev_employer': false,
      'medical': false,
      'license': false,
      'consent': false
    }
  })

  const [DriverEducation, setDriverEducation] = useState({
    'highest_grade_completed': 'grade_12', // dropdown
    'school_name': '',
    'school_address': '',
    'other_courses': ''
  })
  const [DriverExperience, setDriverExperience] = useState([]);
  const [DriverUnemployment, setDriverUnemployment] = useState([])
  const [DriverAccident, setDriverAccident] = useState({
    'involved_accident': false,
    'details': []
  });
  const [DriverViolation, setDriverViolation] = useState({
    'received_violation': false,
    'details': []
  });
  const [DriverRecord, setDriverRecord] = useState({
    'charges': null,
    'charges_detail': '',
    'misdemeanours': null,
    'misdemeanours_detail': '',
    'collect_info': null
  })

  const [DriverGeneral, setDriverGeneral] = useState({
    'fast': null,
    'fast_num': '',
    'fast_expiry': '',
    'bonded': null,
    'enter_usa': null,
    'citizenship': '',
    'driver_referral': '',
    'region': 'city',
    'driver_type': 'owner_operator',
  })
  const [DriverFMCSR, setDriverFMCSR] = useState({
    'question_1': null,
    'question_2': null,
    'question_3': null,
    'question_4': null,
    'question_5': null,

  })

  const [DriverFiles, setDriverFiles] = useState({
    'licenseFile': '',
    'cvorFile': '',
    'abstractFile': '',
    // 'license_uploaded': new Date(), add in backend
    'cvor_retrieved': null,
    'abstract_retrieved': null,
  })

  const [DriverPdfData, setDriverPdfData] = useState({
    'name': '',
    'sin': '',
    'signature_data': '',
    'date': '', // backend
    'address': {
      'country': 'Canada',
      'street': '',
      'city': '',
      'province_state': 'ON - Ontario',
      'postal_zip': ''
    },
    'license': {
      'country': 'Canada',
      'province_state': 'ON - Ontario',
      'num': '',
      'expiry': null,
    }
  })


  const handleCreateDriver = async () => {
    const keys_to_validate = ['name', 'signature_data', 'sin', 'license']
    const displayMsg = validateObj(keys_to_validate, DriverPdfData)
    if (displayMsg.length > 0) {
      const incompleteItems = displayMsg.join('\n')
      alert('Below fields were left incomplete or were not filled correct:\n' + incompleteItems)

    } else {
      const data = {
        // abstract_retrieved
        accidents: DriverAccident.details ? listToDictionary(DriverAccident.details) : {},
        address: DriverDetails.address,
        birthday: DriverDetails.birthday,
        can_contact: DriverDetails.can_contact,
        charges: DriverRecord.charges,
        charges_detail: DriverRecord.charges_detail.trim(),
        citizenship: DriverGeneral.citizenship.trim(),
        collect_info: DriverRecord.collect_info,
        // company_id
        comments: {},
        // cvor_retrieved,
        driver_referral: DriverGeneral.driver_referral.trim(),
        driver_type: DriverGeneral.driver_type,
        email: DriverDetails.email.trim(),
        enter_usa: DriverGeneral.enter_usa,
        fast: DriverGeneral.fast,
        fast_num: DriverGeneral.fast_num,
        first_name: DriverDetails.first_name.trim(),
        // id
        involved_accident: DriverAccident.involved_accident,
        job_app_id: jobPostId === -1 ? null : jobPostId,
        last_name: DriverDetails.last_name.trim(),
        // last_updated
        // license
        misdemeanours: DriverRecord.misdemeanours,
        misdemeanours_detail: DriverRecord.misdemeanours_detail,
        phone: DriverDetails.phone,
        privacy_policy: DriverDetails.privacy_policy,
        received_violation: DriverViolation.received_violation,
        region: DriverGeneral.region,
        status: 'new_applicant',
        sin: DriverDetails.sin.trim(),
        // steps_completed
        terms_conditions: DriverDetails.terms_conditions,
        violations: DriverViolation.details ? listToDictionary(DriverViolation.details) : {},
        work_experience: listToDictionary(DriverExperience)
      }
      try {
        let endPoint;
        if (jobPostId === -1) {
          endPoint = 'driver'
        } else {
          endPoint = `jobs/${companyName}/${jobPostId}`

        }
        setAllowNext(false)
        // const response = await toast.promise(postRequest(endPoint, data),
        //   {
        //     pending: 'Creating Profile...',
        //     success: 'Profile Created',
        //     error: 'Error Creating Profile'
        //   })
        setAllowNext(true)
        // setID(response.id)
        setID(-1)
        // add calls to files here
        setActiveStep((prevStep) => prevStep + 1)
      } catch (error) {
        console.error('Error:', error)
        setError(true)
      }
    }
  }

  const handleUploadFiles = async () => {

    // upload docs 
    let displayMsg = []
    if (DriverFiles.cvorFile !== '' && DriverFiles.cvor_retrieved === null) {
      displayMsg.push('Provide Date CVOR Retrieved')
    }
    if (DriverFiles.abstractFile !== '' && DriverFiles.abstract_retrieved === null) {
      displayMsg.push('Provide Date Abstract Retrieved')
    }
    if (displayMsg.length > 0) {
      const incompleteItems = displayMsg.join('\n')
      alert('Below fields were left incomplete or were not filled correct:\n' + incompleteItems)
    } else {


      const data = {
        cvor_retrieved: DriverFiles.cvor_retrieved && DriverFiles.cvor_retrieved.toISOString(),
        abstract_retrieved: DriverFiles.abstract_retrieved && DriverFiles.abstract_retrieved.toISOString(),
      }
      console.log(data)
      // DriverFiles
      // load multiple files and confirm 0 and single still works
      try {
        setAllowNext(false)
        const formData = new FormData();
        formData.append('driverFilesData', JSON.stringify(data));
        formData.append('licenseFile', DriverFiles.licenseFile);
        formData.append('cvorFile', DriverFiles.cvorFile);
        formData.append('abstractFile', DriverFiles.abstractFile);

        // const response = await toast.promise(postRequestFile(
        //   `driverdocuments/${id}`,
        //   formData
        // ), {
        //   pending: 'Submitting Files...',
        //   success: 'Files Submitted',
        //   error: 'Error Submitting Files'
        // })
        setActiveStep((prevStep) => prevStep + 1)
      } catch (err) {
        console.error('Error:', err)
        setError(true)

      }
    }
  }


  const [activeStep, setActiveStep] = useState(0);

  const handleNext = async () => {
    // keys to validate cannot be false,  '', or null
    let keys_to_validate;
    let displayMsg = []
    if (activeStep === 0) {
      keys_to_validate = ['privacy_policy', 'terms_conditions',]
      displayMsg = validateObj(keys_to_validate, DriverDetails)
    } else if (activeStep === 1) {
      keys_to_validate = ['first_name', 'last_name', 'address', 'email', 'phone',
        'sin', 'can_contact']
      displayMsg = validateObj(keys_to_validate, DriverDetails)
    } else if (activeStep === 2) {
      keys_to_validate = ['highest_grade_completed', 'school_name', 'school_address']
      displayMsg = validateObj(keys_to_validate, DriverEducation)
    } else if (activeStep === 3) {
      keys_to_validate = ['company_name', 'position', 'start_date', 'end_date', 'operate_commercial',
        'auth_to_contact', 'email', 'phone', 'address']

      DriverExperience.forEach((exp, index) => {
        keys_to_validate.forEach((key) => {
          if (key === 'phone' && !isPhoneNumberValid(exp[key])) {
            displayMsg.push(`Experience #${index + 1}: ${capitalCase(key)}`)
          } else if (key === 'email' && !isEmailValid(exp[key])) {
            displayMsg.push(`Experience #${index + 1}: ${capitalCase(key)}`)
          } else if (exp[key] === null || exp[key] === '') {
            displayMsg.push(`Experience #${index + 1}: ${capitalCase(key)}`)
          }

        })
        if (exp.end_date !== '') {
          if (exp.start_date > exp.end_date) {
            displayMsg.push(`Period #${index + 1}: End Date must be after Start Date`)
          }
        }
        if (exp.operate_commercial === true) {
          if (exp.commercial_exp.subject_regulations === null) {
            displayMsg.push(`Experience #${index + 1}: FMCSA Regulations`)
          }
          if (exp.commercial_exp.safety_sensitive === null) {
            displayMsg.push(`Experience #${index + 1}: DOT Regulations`)
          }
          console.log(typeof exp.commercial_exp.pay_per_mile)
          if (exp.commercial_exp.pay_per_mile === '') {
            displayMsg.push(`Experience #${index + 1}: Pay/Mile`)
          }
        }
      })
    } else if (activeStep === 4) {
      DriverUnemployment.forEach((exp, index) => {
        if (exp.start_date === '') {
          displayMsg.push(`Period #${index + 1}: Start Date`)
        }
        if (exp.end_date === '') {
          displayMsg.push(`Period #${index + 1}: End Date`)
        }
        if (exp.start_date > exp.end_date) {
          displayMsg.push(`Period #${index + 1}: End Date must be after Start Date`)
        }
      })

    } else if (activeStep === 5) {
      setActiveStep((prevStep) => prevStep + 1)
    } else if (activeStep === 6) {
      keys_to_validate = ['at_fault', 'commercial', 'detailed_info', 'receive_ticket']
      DriverAccident.details.forEach((exp, index) => {
        keys_to_validate.forEach((key) => {
          if (exp[key] === '' || exp[key] === null) {
            displayMsg.push(`Accident #${index + 1}: ${capitalCase(key)}`)
          }
        })
        if (exp.date === '') {
          displayMsg.push(`Accident #${index + 1}: Date Occured`)
        }
        if (exp.commercial === true && exp.dot_recordable === null) {
          displayMsg.push(`Accident #${index + 1}: DOT Recordable`)

        }
      })
    } else if (activeStep === 7) {
      keys_to_validate = ['commercial', 'penalty',]
      DriverViolation.details.forEach((exp, index) => {
        keys_to_validate.forEach((key) => {
          if (exp[key] === '' || exp[key] === null) {
            displayMsg.push(`Violation #${index + 1}: ${capitalCase(key)}`)
          }
        })
        if (exp.date === '') {
          displayMsg.push(`Violation #${index + 1}: Date Occured`)
        }
        if (exp.penalty === true && exp.penalty_info.trim() === '') {
          displayMsg.push(`Violation #${index + 1}: Penalty Info`)
        }
      })
    } else if (activeStep === 8) {
      keys_to_validate = ['misdemeanours', 'charges',]
      keys_to_validate.forEach((key) => {
        if (DriverRecord[key] === null) {
          displayMsg.push(`${capitalCase(key)}`)
        }
      })
      if (DriverRecord.collect_info === null) {
        displayMsg.push('Consent to collect information')
      }
      if (DriverRecord.charges === true && DriverRecord.charges_detail.trim() === '') {
        displayMsg.push('Charges Details')
      }
      if (DriverRecord.misdemeanours === true && DriverRecord.misdemeanours_detail.trim() === '') {
        displayMsg.push('Misdemeanour Details')
      }
    } else if (activeStep === 9) {
      keys_to_validate = ['fast', 'bonded', 'enter_usa']
      keys_to_validate.forEach((key) => {
        if (DriverGeneral[key] === null) {
          displayMsg.push(`${capitalCase(key)}`)
        }
      })
      if (DriverGeneral.fast === true) {
        if (DriverGeneral.fast_num.trim() === '') {
          displayMsg.push('FAST Number')
        }
        if (DriverGeneral.fast_expiry === '') {
          displayMsg.push('FAST Expiry Date')
        }
      }
      if (DriverGeneral.citizenship.trim() === '') {
        displayMsg.push('Citizenship')

      }

    } else if (activeStep === 10) {
      keys_to_validate = ['question_1', 'question_2', 'question_3', 'question_4', 'question_5']
      keys_to_validate.forEach((key) => {
        if (DriverFMCSR[key] === null) {
          displayMsg.push(capitalCase(key))
        }
      })
    } else if (activeStep === 11) {
      if (DriverDetails.valid_data === null || DriverDetails.valid_data === false) {
        displayMsg.push('Confirmation of data accuracy')
      }

      if (DriverDetails.signature_certify.data === '') {
        displayMsg.push('Signature')
      }
    } else if (activeStep === 12) {
      keys_to_validate = ['disclosure', 'psp', 'clearing_house', 'prev_employer', 'medical', 'license', 'consent']
      keys_to_validate.forEach((key) => {
        if (DriverDetails.consent_forms[key] === false) {
          displayMsg.push(`${capitalCase(key)} signature`)
        }
      })
    }
    if (displayMsg.length > 0) {
      const incompleteItems = displayMsg.join('\n')
      alert('Below fields were left incomplete or were not filled correct:\n' + incompleteItems)
    } else {
      setActiveStep((prevStep) => prevStep + 1)
    }
  };


  const handleBack = () => {
    setAllowNext(true)
    if (activeStep === 1) {
      setDriverDetails({
        ...DriverDetails,
        'privacy_policy': false,
        'terms_conditions': false
      })
    } else if (activeStep === 12) {
      setDriverDetails({
        ...DriverDetails,
        'signature_certify': {
          'data': '',
          'date': ''// backend
        },
      })
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // For example, you can validate the form data and then decide whether to navigate to the next step or not
  };

  const steps = ['Personal Info', 'Experience', 'Accidents',
    'Traffic Violations', 'Criminal Record',
    'General Info', 'Create Profile', 'Submit Files',];


  if (error) {
    return (

      <Typography align="center" color={"textSecondary"} variant="h5" >
        An error occured, please try submitting your application later.
      </Typography>

    )
  }
  return (

    <>
      {isLoading ?
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress color='primary' />
        </Box>
        :
        <>
          <Container style={{
            minWidth: '500px', minHeight: '300px', marginTop: '20px'
          }} >

            {activeStep === 0 &&
              <CriteriaToHire DriverDetails={DriverDetails} setDriverDetails={setDriverDetails} />
            }

            {activeStep === 1 &&
              <PersonalInfo DriverDetails={DriverDetails} companyName={companyName}
                setDriverDetails={setDriverDetails} />}

            {activeStep === 2 &&
              <Education DriverEducation={DriverEducation} setDriverEducation={setDriverEducation} />
            }
            {activeStep === 3 &&
              <WorkExperience DriverExperience={DriverExperience} setDriverExperience={setDriverExperience} />
            }
            {activeStep === 4 &&
              <Unemployment DriverUnemployment={DriverUnemployment} setDriverUnemployment={setDriverUnemployment} />
            }
            {activeStep === 5 &&
              <VerifyExperience DriverExperience={DriverExperience} DriverUnemployment={DriverUnemployment} setAllowNext={setAllowNext} />
            }
            {activeStep === 6 &&
              <RecentAccident DriverAccident={DriverAccident} setDriverAccident={setDriverAccident} />
            }
            {activeStep === 7 &&
              <TrafficViolation DriverViolation={DriverViolation} setDriverViolation={setDriverViolation} />
            }
            {activeStep === 8 &&
              <CriminalRecord DriverRecord={DriverRecord} setDriverRecord={setDriverRecord} />
            }
            {activeStep === 9 &&
              <GeneralInfo DriverGeneral={DriverGeneral} setDriverGeneral={setDriverGeneral} />
            }
            {activeStep === 10 &&
              <FMCSRQuestions DriverFMCSR={DriverFMCSR} setDriverFMCSR={setDriverFMCSR} />
            }
            {activeStep === 11 &&
              <ReviewForm DriverDetails={DriverDetails} setDriverDetails={setDriverDetails} setAllowNext={setAllowNext} />
            }
            {activeStep === 12 &&
              <ConsentForms DriverDetails={DriverDetails} setDriverDetails={setDriverDetails} setAllowNext={setAllowNext} />
            }

            {activeStep === 13 &&
              <SignConsentForms DriverPdfData={DriverPdfData} setDriverPdfData={setDriverPdfData}
                DriverExperience={DriverExperience} setDriverExperience={setDriverExperience} />
            }

            {activeStep === 14 &&
              <AddFiles DriverFiles={DriverFiles} setDriverFiles={setDriverFiles} setAllowNext={setAllowNext} />}

            {activeStep === 15 &&
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                marginTop={3}
                height="50vh"
                minWidth="800px"
              >
                <Typography variant="h6"> Thank you for applying, your application has been received.</Typography>
                <p>
                  {jobPostId === -1 &&
                    <Button fullWidth variant="contained"
                      style={{
                        maxWidth: '200px', maxHeight: '300px'
                      }} color="primary" onClick={onClose} >Exit</Button>}

                </p>
              </Box>

            }


          </Container >
          <Grid container justify="center" mt={5}>
            <Grid item xs={10} lg={10} md={10}>
              {(activeStep > 0 && activeStep <= 13) &&
                <Button onClick={handleBack} style={{ marginRight: '20px' }}>
                  Back
                </Button>
              }
            </Grid>

            <Grid item xs={2} md={2} lg={2}>
              {(activeStep >= 0 && activeStep < 13) &&
                <Button variant="contained"
                  fullWidth
                  disabled={!allowNext}
                  style={{
                    maxWidth: '200px', maxHeight: '300px'
                  }} color="primary" onClick={handleNext}>
                  Next
                </Button>
              }
              {activeStep === 13 &&
                <Button variant="contained"
                  fullWidth
                  onClick={handleCreateDriver}
                  style={{
                    maxWidth: '300px', maxHeight: '300px'
                  }} color="primary">
                  Create Profile
                </Button>
              }
              {activeStep === 14 &&
                <Button variant="contained"
                  fullWidth
                  onClick={handleUploadFiles}
                  disabled={!allowNext}
                  style={{
                    maxWidth: '300px', maxHeight: '300px'
                  }} color="primary">
                  Submit Files
                </Button>
              }
            </Grid>
          </Grid>
        </>
      }
    </>


  );
}

export default DriverApplication;