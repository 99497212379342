import { useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { toast } from 'react-toastify';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import 'react-toastify/dist/ReactToastify.css';
import { capitalCase } from 'change-case';
import { putRequest } from '../../../../../shared/apiCalls';



// Set Status to hired, fired, inactive
export default function UpdateStatus({ onClose, open, DriverDetails, setDriverDetails }) {
    const statuses = ['hired', 'inactive', 'fired']
    const [status, setStatus] = useState(DriverDetails.status)
    const changeStatus = (e) => {
        setStatus(e.target.value)
    }
    const handleSubmit = async () => {
        try {
            const response = await toast.promise(putRequest(`driver/${DriverDetails.id}`, {
                'status': status

            }),
                {
                    pending: 'Updating Status...',
                    success: 'Status Updated',
                    error: 'Error Updating Status'
                })
            setDriverDetails(driver => {
                return { ...driver, 'status': status }
            });
            onClose();
        } catch (error) {
            console.error(error)
            alert(error)
        }
    }

    return (
        <Dialog
            onClose={onClose} open={open}
            aria-labelledby="form-dialog"
            maxWidth='sm'
            fullWidth

        >
            <DialogContent>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={10}>
                        <Typography color={"primary"} variant="h5">
                            Update Status
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <FormControl fullWidth >
                            <InputLabel id="status-select-label">New Status: </InputLabel>
                            <Select
                                labelId="status-select-label"
                                id="status-select"
                                name='status'
                                onChange={(e) => changeStatus(e)}
                                value={status}
                                input={<OutlinedInput label="New Status" />}
                            >
                                {statuses.map((t) => (

                                    <MenuItem key={t} value={t}>
                                        <ListItemText primary={capitalCase(t)} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>
                <Box mt={6}>
                    <Button variant="contained" fullWidth
                        pt={10} type='submit' onClick={handleSubmit}>Update</Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
