import React from 'react';
import { List, ListItem, ListItemText, Paper, Typography, Grid } from '@mui/material';
import DisplayAccidentDetails from './AccidentDetails';

const DisplayAccidentList = ({ accidents }) => {

    return (
        <Grid item xs={6} md={6}>
            <Paper elevation={3} style={{ padding: '20px' }}>
                <Typography variant='h5'> Previous Accidents</Typography>
                <List>
                    {accidents.map((accident, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={<DisplayAccidentDetails accident={accident} />} // Use the DisplayAccidentDetails component
                            />
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </Grid>
    );
};

export default DisplayAccidentList;